import { useAuth0 } from '@auth0/auth0-react';
import ModalComponent from 'components/common/Modal/Modal';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { useContext, useEffect, useReducer, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router';
import Select from 'react-select';
import Switch from "react-switch";
import UserContext from '../../Context/UserProvider';
import './SurveyPage.css';





function reducer(state: any, action: any) {
    switch (action.input) {
        case "vehicleRegistration":
            return { ...state, vehicleRegistration: action.value }
        case "firstName":
            return { ...state, firstName: action.value }
        case "lastName":
            return { ...state, lastName: action.value }
        case "address":
            return { ...state, address: action.value }
        case "ParkingType":
            return { ...state, parkingType: action.value }
        case "ExistingChargingFacilities":
            return { ...state, existingChargingFacilities: action.value }
        case "ParkingSize":
            return { ...state, parkingSize: action.value }
        case "PropertyStatus":
            return { ...state, propertyStatus: action.value }
        case "PropertyType":
            return { ...state, propertyType: action.value }
        case "evOvernightChargingRate":
            return { ...state, evOvernightChargingRate: action.value }
        default:
            return state


    }

}



function AuthenticatedSurveyPage(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext)
    const { isAuthenticated, loginWithRedirect } = useAuth0()
    const [toggle, setToggle] = useState(true)

    const [missingInput, setMissingInput] = useState("")
    let navigate = useNavigate()
    const goHome = () => {
        let path = isAuthenticated ? '/plan' : '/';
        navigate(path);
    }
    const location = useLocation()
    var splitURL = location.pathname.toString().split("/");
    const initialState = {

        vehicleRegistration: "",
        firstName: "",
        lastName: "",
        address: "",
        parkingType: "",
        existingChargingFacilities: true,
        parkingSize: "",
        propertyStatus: "",
        propertyType: "",
        evOvernightChargingRate: toggle

    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reference, setReference] = useState({}) as any
    const [modalIsOpen, setIsOpen] = useState(false)

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }


    // async function checkSurveyValidity() {
    //     let bodyData: { [name: string]: string } = {}
    //     const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin

    //     bodyData['fleetUuid'] = splitURL[2]
    //     bodyData['surveyUuid'] = splitURL[3]
    //     let fetch_link = `${rootUrl}/survey/referencedata`
    //     let fetch_option = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(bodyData)
    //     }
    //     let response = (await fetch(fetch_link, fetch_option))
    //     if (response.status !== 200) {
    //         setIsError(true)
    //         return false
    //     }
    //     let res_json = await response.json()
    //     return true

    // }
    const fetchReference = async () => {

        // await checkSurveyValidity()

        let bodyData: { [name: string]: string } = {}
        bodyData['fleetUuid'] = userInfo.fleetUuid
        bodyData['uuid'] = userInfo.uuid
        // bodyData['surveyUuid'] = splitURL[3]
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/survey/referencedata/authenticated`
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        }
        let response = (await fetch(fetch_link, fetch_option))
        if (response.status !== 200) {
            setIsError(true)
            return
        }
        let res_json = await response.json()
        let referenceData = res_json.referenceData
        referenceData = {
            ...referenceData,
            ParkingSize: referenceData.ParkingSize.map((e: any) => {
                dispatch({ input: 'ParkingSize', value: referenceData.ParkingSize[0].value });
                return { ...e, label: e.displayName, name: 'ParkingSize' }
            }),

            PropertyType: referenceData.PropertyType.map((e: any) => {
                dispatch({ input: 'PropertyType', value: referenceData.PropertyType[0].value });
                return { ...e, label: e.displayName, name: 'PropertyType' }
            }),

            PropertyStatus: referenceData.PropertyStatus.map((e: any) => {
                dispatch({ input: 'PropertyStatus', value: referenceData.PropertyStatus[0].value });

                return { ...e, label: e.displayName, name: 'PropertyStatus' }
            }),

            ParkingType: referenceData.ParkingType.map((e: any) => {
                dispatch({ input: 'ParkingType', value: referenceData.ParkingType[0].value });

                return { ...e, label: e.displayName, name: 'ParkingType' }
            }),

            ExistingChargingFacility: [{ label: 'Yes', value: true, name: 'existingChargingFacility' }, { label: 'No', value: false, name: 'existingChargingFacility' }]



        }
        dispatch({ input: 'ExistingChargingFacility', value: true });

        setReference(referenceData)
        setLoading(false)
    }

    function validateForm() {
        let firstName = document.getElementById('firstName') as HTMLInputElement
        let lastName = document.getElementById('lastName') as HTMLInputElement
        let address = document.getElementById('address') as HTMLInputElement
        let vehicleRegistration = document.getElementById('vehicleRegistration') as HTMLInputElement
        if (state.vehicleRegistration === '') {
            vehicleRegistration.style.borderColor = "red"
            setMissingInput("Vehicle registration is required ")
            return false
        }
        else if (state.firstName === '') {
            firstName.style.borderColor = 'red'
            setMissingInput("First name is required ")

            return false
        }
        else if (state.lastName === '') {
            lastName.style.borderColor = "red"
            setMissingInput("Last name is required ")

            return false
        }
        else if (state.address === '') {
            address.style.borderColor = "red"
            setMissingInput("Address is required ")

            return false
        }


        return true


    }
    function toggleButton() {
        setToggle(prev => !prev)
        dispatch({ input: "evOvernightChargingRate", value: !toggle })


    }



    const submitSurvey = async () => {

        const validationStatus = validateForm()
        if (!validationStatus) {
            return
        }

        let bodyData: { [name: string]: string } = {}
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin

        bodyData['fleetUuid'] = userInfo.fleetUuid
        bodyData['uuid'] = userInfo.uuid
        bodyData['survey'] = state
        let fetch_link = `${rootUrl}/survey/submit/authenticated`
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        }
        let response = (await fetch(fetch_link, fetch_option))
        if (response.status !== 200) {
            setIsError(true)
            return
        }
        let res_json = await response.json()


        openModal()
    }
    function onChange(e: any) {
        let action: any
        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            }
        } else {
            action = {
                input: e.name,
                value: e.value,
            }
        }
        dispatch(action)
    }
    useEffect(() => {

        fetchReference()
    }, [])
    return (
        <>
            {
                isError ? <ErrorPage statusCode='505' />
                    :
                    <>
                        {
                            loading ? <>loading.......</> :
                                <div style={{ display: 'flex', justifyContent: 'center' }}>

                                    <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                                        <>
                                            <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <div className="close-btn" />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>
                                                <h1>Thank you</h1>
                                                <p style={{ maxWidth: 400, textAlign: 'center' }}>
                                                Your survey response has been submitted successfully.
                                                </p>
                                                <button onClick={() => goHome()} className='primary-btn' >Continue</button>
                                            </div>
                                        </>

                                    </ModalComponent>
                                    {/* <JourneyNav fixed="fixed-top" homePage={true} /> */}
                                    <div className="user-survey-container" style={{ marginTop: 10 }}>
                                        <span className="user-survey-title">
                                            User Survey
                                        </span>
                                        <form action="" style={{ display: 'flex', flexDirection: 'column', gap: 30 }} >
                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Vehicle Registration Number : </span>
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 200 }}>
                                                    <input type="text" className="textbox" name="vehicleRegistration" onChange={onChange} id='vehicleRegistration' required />
                                                    {/* <span style={{ marginLeft: 10 }} >%</span> */}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>First Name : </span>
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 200 }}>
                                                    <input type="text" className="textbox" name='firstName' onChange={onChange} id='firstName' required />
                                                    {/* <span style={{ marginLeft: 10 }} >%</span> */}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Last Name : </span>
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 200 }}>
                                                    <input type="text" className="textbox" name='lastName' onChange={onChange} id='lastName' required />
                                                    {/* <span style={{ marginLeft: 10 }} >%</span> */}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Address : </span>
                                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 200 }}>
                                                    <input type="text" className="textbox" name="address" onChange={onChange} id='address' required />
                                                    {/* <span style={{ marginLeft: 10 }} >%</span> */}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Please choose what best describes your property: </span>
                                                <Select
                                                    defaultValue={reference.PropertyType[0]}
                                                    name="propertyType"
                                                    // label="Single select"
                                                    onChange={onChange}
                                                    options={reference.PropertyType}
                                                // styles={colourStyles}
                                                />
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Do you have existing charging facilities available at your property? </span>
                                                <Select
                                                    defaultValue={reference.ExistingChargingFacility[0]}
                                                    // label="Single select"
                                                    onChange={onChange}
                                                    name="chargingFacility"
                                                    options={reference.ExistingChargingFacility}
                                                // styles={colourStyles}
                                                />
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Please choose what best describes your parking space: </span>
                                                <Select
                                                    defaultValue={reference.ParkingType[0]}
                                                    name="propertyType"
                                                    // label="Single select"
                                                    onChange={onChange}
                                                    options={reference.ParkingType}
                                                // styles={colourStyles}
                                                />
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                            <span>How far in metres is the parking location from your house/flat? </span>
                                                <Select
                                                    defaultValue={reference.ParkingSize[0]}
                                                    // label="Single select"
                                                    onChange={onChange}
                                                    options={reference.ParkingSize}

                                                // styles={colourStyles}
                                                />
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                                                <span>Is your property owned or rented? </span>
                                                <Select
                                                    defaultValue={reference.PropertyStatus}
                                                    name="isPropertyOwn"
                                                    onChange={onChange}
                                                    // label="Single select"
                                                    options={reference.PropertyStatus}
                                                // styles={colourStyles}
                                                />
                                            </div>
                                            <label style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>Would you look to take advantage of any overnight EV tariff special rate?</span>
                                                <Switch onChange={toggleButton} checked={toggle} name={'markActive'} />

                                            </label>

                                        </form>

                                        <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, gap: 20 }}>

                                            <button className='primary-btn' onClick={() => submitSurvey()}>Submit</button>
                                            <button className='warning-btn'>Reset</button>
                                        </div>

                                    </div>
                                </div>
                        }
                    </>
            }

        </>

    );
}

export default AuthenticatedSurveyPage;