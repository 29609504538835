import React from "react";
import { useNavigate } from "react-router";
import {
  PieChart,
  Pie,
  LabelList,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
// import './PieGraph.scss'
import "./ScenarioSummaryPieChart.css";

const COLORS = ["#BEF213", "#00D9FF", "#006B6A", "#FF8C81"];

function ScenarioSummaryPieChart(props: any) {
  const navigate = useNavigate();
  const getIntroOfPage = (label: any) => {
    if (label === "Single-Charge") {
      return "Single charge tells the amount of journey that required only one single charge";
    }
    if (label === "Muliple-charge") {
      return "Multiple charge tells the amount of journey that required only more than one charge";
    }
    if (label === "Non-Charge") {
      return "Non charge tells the the amount of journey that required no charge";
    }
    return "";
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            fontSize: 5,
            color: "black",
            backgroundColor: "	rgba(255,255,255,0.8)",
            borderRadius: 10,
            display: "flex-box",
            padding: 20,
          }}
        >
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="intro">{getIntroOfPage(payload[0].name)}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="pie-chart-summary col-auto">
      {/* <div className="left-title">
                <b style={{ fontSize: 15 }} > </b > of journeys <b style={{ fontSize: 15 }}>did not require </b> an en-route charge
            </div> */}
      <h2 className="pie-title">{props.title}</h2>

      <PieChart
        width={props.width}
        height={props.height}
        style={{ cursor: "pointer" }}
      >
        <text
          x={props.width / 2}
          y={props.width / 2 + 5}
          fill={"grey"}
          fontSize={12}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {props.unit}
        </text>
        <Pie
          data={props.data}
          textAnchor="middle"
          dominantBaseline="middle"
          innerRadius={60}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="percentage"
        >
          <Label
            style={{ fontSize: 30, fontWeight: "bold" }}
            value={props.total}
            position="center"
          />

          <LabelList
            dataKey="percentage"
            position="insideTopLeft"
            fill="white"
            formatter={(value: any) => {
              if (value !== 0) {
                return value.toString() + "%";
              }
            }}
          />
          {props.data.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          align={"center"}
          iconType={"circle"}
          layout={"horizontal"}
          height={40}
          iconSize={9}
        />
      </PieChart>
      {/* <button style={{ marginTop: 20 }} className="primary-btn" onClick={() => (navigate("/plan/insight/fleet/scenario/categories", {
                state: {
                    fleetScenarioNumber: props.fleetScenarioNumber,
                    fleetScenarioUuid: props.fleetScenarioUuid
                }
            }))}>View</button> */}
    </div>
  );
}
export default ScenarioSummaryPieChart;
