import { Backdrop, CircularProgress } from '@mui/material';
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import HomeLocationsTable from 'components/ppa-quote/HomeLocationsTable';
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function isNull(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function BuildQuotePageLocations(props: any) {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [homeLocationsData, setHomeLocationsData] = useState([]) as any;
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const navigate = useNavigate();

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    const handleNext = () => {

        navigate("/agree/ppa/quote/build/summary")

    }

    async function fetchLocationData() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/infrastructure/dwelling/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        res_json.homeLocations = checking_null_undefined(res_json.homeLocations);

        setHomeLocationsData(res_json.homeLocations.map((e: any) => ({ ...e, id: e.uuid, nearestPublicChargingDistance: e.nearestPublicCharging != null ? e.nearestPublicChargingDistance < 0.1 ? "< 0.1 " + userInfo.userProfile.distanceUnitShortDisplayName : parseInt(e.nearestPublicChargingDistance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName : "" })));

        setLoading(false);
    }

    useEffect(() => {
        fetchLocationData();
    }, []);

    return (
        <div>
            {/* Breadcrumbs always displayed */}
            <Breadcrumbs currentStep={"Locations"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (

                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">
                        <div className="drag-drop-container">
                            <HomeLocationsTable data={homeLocationsData} />
                            <br />
                            <br />
                            <br />
                            <div className="col-xs-12 col-md-12 col-xl-12">
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                        <button className="primary-btn" disabled={isDisabled} onClick={handleNext}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default BuildQuotePageLocations;