import AdvancedBookingPanel from "components/charging/ChargingPartnerComponent/AdvancedBookingPanel";
import 'react-datepicker/dist/react-datepicker.css';



function AdvancedBookingPage(props: any) {


    return (
        <div className="d-flex flex-column gap-5">
            <AdvancedBookingPanel />
        </div >
    );
}

export default AdvancedBookingPage;
