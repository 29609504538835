import React from "react";
import { Outlet } from "react-router";

function SurveyRouter(props: any) {
  return (
    <div className="d-flex flex-column gap-5">
      <Outlet />
    </div>
  );
}

export default SurveyRouter;
