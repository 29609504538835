import { Stack } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { checking_null_undefined } from "utils"

function DwellingChargePointListTable(props: any) {
    const navigate = useNavigate();

    const data = checking_null_undefined(props.dwelling.chargePoints);

    const columns: GridColDef[] = [
        // {
        //     field: "uuid",
        //     renderHeader: (params) => {
        //         // Your custom render logic here
        //         return (
        //             <div style={{ fontWeight: 'bold' }}>
        //                 {`Location : ${props.dwelling.addressLine}`}
        //             </div>
        //         );
        //     }, sortable: false,
        //     width: 280,
        //     align: "left",
        //     headerAlign: "left",
        //     renderCell: (cell) => {
        //         return (
        //             <span>
        //                 {cell.row.connectorTypeAsString}
        //             </span>
        //         );
        //     },
        // },
        {
            field: "label",
            renderHeader: (params) => {
                // Your custom render logic here
                return (
                    <div style={{ fontWeight: 'bold' }}>
                        Charge Points
                    </div>
                );
            },            sortable: false,
            width: 200,
            align: "left",
            headerAlign: "left",
           renderCell: (cell) => {
                return (
                    <span>
                        {cell.row.properties.label}
                    </span>
                );
            },

        },
        {
            field: "connectors",
            headerName: "Connector(s)",
            sortable: false,
            width: 150,
            align: "left",
            headerAlign: "left",
            renderCell: (cell) => {
                return (
                    <span>
                        {cell.row.properties.connectorListAsString}
                    </span>
                );
            },
       },

        {
            field: "maxRateKW",
            headerName: "Max Rate (kW)",
            sortable: false,
            width: 150,
            align: "right",
            headerAlign: "right",
            renderCell: (cell) => {
                return (
                    <span>
                        {cell.row.properties.maxRateKW}
                    </span>
                );
            },
       },
        //    {
        //   field: "speed",
        //   headerName: "Speed",
        //   sortable: false,
        //   width: 150,
        //   align: "left",
        //   headerAlign: "center",
        // },

        // {
        //   field: "status",
        //   headerName: "Status",
        //   sortable: false,
        //   width: 150,
        //   align: "center",
        //   headerAlign: "center",
        // },
        // {
        //   field: "availability",
        //   headerName: "See Availability",
        //   sortable: false,
        //   width: 150,
        //   align: "right",
        //   headerAlign: "center",
        // },


    ];

    return (
        <div className="table-box">
            <DataGrid
                width={"100%"}
                rows={data}
                autoHeight
                {...data}
                columns={columns}
                getRowId={(row) => row.properties.uuid}
                pageSize={15}
                disableColumnMenu
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center">
                            <span>No Connectors have been configured.</span>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center">
                            Selected filter returned no results
                        </Stack>
                    ),
                }}
                fontFamily={"Arvo"}></DataGrid>
        </div>
    );
}

export default DwellingChargePointListTable;
