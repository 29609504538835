import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useLocation } from "react-router-dom";

import { ChargingPartner } from "../BusinessChargingNetworkParameters/chargingPartner";
import { CloudStorageObject } from "interfaces/CloudStorageObject";

interface PartnershipContractualDocumentsPanelProps {
    chargingPartnerOffer: ChargingPartner;
    panelTitle: string
    type: string
}

const PartnershipContractualDocumentsPanel: React.FC<PartnershipContractualDocumentsPanelProps> = ({ chargingPartnerOffer, panelTitle, type}) => {

    const location = useLocation();

    const documentColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            renderCell: (params: any) => (
                <a href={params.row.signedUrl} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </a>
            )
        }
    ];

    const files =  chargingPartnerOffer?.ramsDocuments?.filter(doc => doc.type === type);

    const documentRows: GridRowsProp = files?.map((doc: any, index: number) => ({
        id: index,
        name: doc.name,
        signedUrl: doc.signedUrl
    }));

    return (

        <div className="dashboard-card" >
            <div className="dashboard-card-title">
                <span className="icon material-symbols-outlined">view_timeline</span>
                {panelTitle}
            </div>
            <div
                className="dashboard-card-content"
                style={{ gap: 20 }}>
                {!chargingPartnerOffer.siteAccessRestriction ? (
                    <div className="box-info">
                        <span
                            className="item"
                            style={{ width: 300 }}>
                            No documents
                        </span>
                    </div>

                ) : (

                    <div>
                        <div className="box-info">
                            <DataGrid rows={documentRows} columns={documentColumns} />
                        </div>

                    </div>

                )}

            </div>
        </div>

    )
}
export default PartnershipContractualDocumentsPanel