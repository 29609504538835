import Question from '../../components/homepage/FrequenceQuestionComponent/Question';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import "./FrequenceQuestionPage.scss";
function FrequenceQuestionPage(props: any) {

    return (
        <div>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className='frequence_container'>
                <div className="frequence_contain">
                    <div className="question_container">
                        <div className="question_header">
                            <h3>FAQs</h3>
                            <br />
                            <h1>Frequently Ask Questions</h1>
                            <br />
                            <p>Have a questions? We are here to help</p>
                        </div>
                        <br />
                        <br />
                        <br />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                        <Question question={<>Is there any free trail available?</>} answer={<>Yes, You can try our service for free account. This account will have limit access to our data and recommendation insight.</>} />
                        <hr />
                    </div>
                    <div className="question-footer">
                        <div style={{ fontWeight: 'bold', fontSize: 20 }}>Still have a question?</div>
                        <div style={{}}> Can't find the answers you're looking for? Please <a href="/contact" style={{ background: 'white', padding: 10, borderRadius: 10, textDecoration: 'underline' }}>contact us</a> for answer</div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default FrequenceQuestionPage;


