import UserContext from "Context/UserProvider";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UnauthenticatedSurveyPage from "pages/SurveyPage/UnauthenticatedSurveyPage";
import SurveyPage from "pages/SurveyPage/UnauthenticatedSurveyPage";
import React, { useContext, useEffect, useState } from "react";
import { FaGalacticSenate } from "react-icons/fa";
import BackButton from "../../components/common/SiteTemplate/BackButton/BackButton";
import ChargingTransactionTable from "../../components/charging/ChargingTransactionComponent/ChargingTransactionTable";
import { currencyFormat, checking_null_undefined } from "utils";

function ChargingTransactionListPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [chargingTransactionsHome, setChargingTransactionsHome] = useState() as any;
    const [chargingTransactionsAway, setChargingTransactionsAway] = useState() as any;
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("Home");

    const fetchChargingTransactions = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/charging-transaction/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        console.log(res_json);

        setChargingTransactionsHome(checking_null_undefined(res_json.chargingTransactionsInbound).map((e: any) => ({ ...e, id: e.uuid, partnerName: e.partnerName, partnerUuid: e.partnerUuid, transactionDate: new Date(Date.parse(e.transactionDate)).toLocaleDateString(), partnerCost: "£" + e.partnerCost.toFixed(2), income: "£" + e.income.toFixed(2), chargeAmountKWh: e.chargeAmountKWh.toFixed(2), chargeTimeInMins: e.chargeTimeInMins, addressLine: e.addressLine, registrationNumber: e.registrationNumber })));
        setChargingTransactionsAway(checking_null_undefined(res_json.chargingTransactionsOutbound).map((e: any) => ({ ...e, id: e.uuid, partnerName: e.partnerName, partnerUuid: e.partnerUuid, transactionDate: new Date(Date.parse(e.transactionDate)).toLocaleDateString(), partnerCost: "£" + e.partnerCost.toFixed(2), income: "£" + e.income.toFixed(2), chargeAmountKWh: e.chargeAmountKWh.toFixed(2), chargeTimeInMins: e.chargeTimeInMins, addressLine: e.addressLine, registrationNumber: e.registrationNumber })));

        console.log(res_json.chargingTransactionsHome);
        console.log(res_json.chargingTransactionsAway);

        setLoading(false);

    };




    useEffect(() => {
        fetchChargingTransactions();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>

                    {showCategory[0] && (
                        <>
                            {/* <h1>Charging Transactions</h1> */}
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "Home" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([true, false]), setActiveMenu("Home"))}>
                                    Your Locations
                                </button>
                                <div className="vl"></div>

                                <button
                                    className={`tab-btn ${activeMenu === "Away" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([false, true]), setActiveMenu("Away"))}>
                                    Partner Locations
                                </button>
                                <div className="vl">
                                </div>
                                <br />
                            </div>
                            {showSecondaryCategory[0] && (
                                <>
                                    <ChargingTransactionTable chargingTransactions={chargingTransactionsHome} transactionDirection="INBOUND"/>
                                </>
                            )}
                            {showSecondaryCategory[1] && (
                                <>
                                    <ChargingTransactionTable chargingTransactions={chargingTransactionsAway} transactionDirection="OUTBOUND"/>
                                </>
                            )}


                        </>
                    )}
                    {/* {showCategory[1] &&
                <>
                    <br />
                    <h1>Email Address</h1>
                    <br />
                    <SurveyEmailTable />
                </>
            } */}
                </div>
            )}
        </>
    );
}

export default ChargingTransactionListPage;
