import React, { InputHTMLAttributes, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "Context/UserProvider";
import Select from "react-select";
import WeeklyChargingReservation from "../../components/charging/BusinessChargingNetworkParameters/WeeklyChargerReservation";
import { WeekDay, weekDays as initialWeekDays } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";
import { initialChargingPartnerRateCardValues } from "../../components/charging/BusinessChargingNetworkParameters/initialChargingPartnerRateCardValues";
import { toHourValues } from "../../components/charging/BusinessChargingNetworkParameters/toHourValues";
import { reservationOptions } from "components/charging/BusinessChargingNetworkParameters/reservationOptions";
import { isChargePointOperator } from "utils/categoryUtils";
import toast from 'react-hot-toast';
import PowerPurchaseAgreementQuotesTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementQuotesTable";
import { useNavigate } from "react-router-dom";
import LogoGrid from "components/charging/PowerPurchaseAgreementComponent/LogoGrid";
import Map from "../../components/common/MapComponent/Map";
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import ReCAPTCHA from 'react-google-recaptcha';


interface OptionType {
    label: string;
    value: string;
    name: string;
}
function PowerPurchaseAgreementPublicPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [parameter, setParameter] = useState({}) as any;
    const location = useLocation();
    const params = location.state as any;
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [submitShow, setSubmitShow] = useState(false)

    const [numberOfVehicles, setNumberOfVehicles] = useState<number | null>(null);
    const [chargesPerWeek, setChargesPerWeek] = useState<number | null>(null);
    const [averageChargeSize, setAverageChargeSize] = useState<number | null>(null);
    const [monthlyRequirementKWh, setMonthlyRequirementKWh] = useState<number | null>(null);
    const [fullName, setFullName] = useState<number | null>(null);
    const [emailAddress, setEmailAddress] = useState<number | null>(null);
    const [businessName, setBusinessName] = useState<number | null>(null);

    const [isUploading, setIsUploading] = useState(false);



    const [selectedLogos, setSelectedLogos] = useState<string[]>([]);

    const handleLogoClick = (name: string) => {
        setSelectedLogos(prevSelectedLogos => {
            if (prevSelectedLogos.includes(name)) {
                return prevSelectedLogos.filter(prevName => prevName !== name);
            } else {
                return [...prevSelectedLogos, name];
            }
        });
    };

    type SelectedState = {
        uk: boolean;
        england: boolean;
        scotland: boolean;
        wales: boolean;
    };

    const [selectedRegion, setSelectedRegion] = useState<SelectedState>({
        uk: false,
        england: false,
        scotland: false,
        wales: false,
    });

    const handleUKChange = () => {
        setSelectedRegion({
            uk: !selectedRegion.uk,
            england: false,
            scotland: false,
            wales: false,
        });
    };

    const handleCountryChange = (country: keyof SelectedState) => {
        setSelectedRegion((prevState) => ({
            ...prevState,
            uk: false,
            [country]: !prevState[country],
        }));
    };


    useEffect(() => {
        // fetchChargingPartnerRateCard();
        setLoading(false);
    }, []);

    let captcha: any
    const checkCaptcha = () => {
        setSubmitShow(true)
    }
    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref
        }
    }
    const resetCaptcha = () => {
        captcha.reset()
    }

    const calculateMonthlyRequirement = (vehicles: number | null, charges: number | null, chargeSize: number | null) => {
        if (vehicles !== null && charges !== null && chargeSize !== null) {
            const result = (vehicles * charges * chargeSize * 52) / 12;
            setMonthlyRequirementKWh(result);
        } else {
            setMonthlyRequirementKWh(0);
        }
    };

    const onChange = (e: any) => {
        const { name, value } = e.target;
        const numericValue = value === '' ? null : parseFloat(value);

        if (name === 'numberOfVehicles') {
            setNumberOfVehicles(numericValue);
            calculateMonthlyRequirement(numericValue, chargesPerWeek, averageChargeSize);
        } else if (name === 'chargesPerWeek') {
            setChargesPerWeek(numericValue);
            calculateMonthlyRequirement(numberOfVehicles, numericValue, averageChargeSize);
        } else if (name === 'averageChargeSize') {
            setAverageChargeSize(numericValue);
            calculateMonthlyRequirement(numberOfVehicles, chargesPerWeek, numericValue);
        } else if (name === 'fullName') {
            setFullName(value);
        } else if (name === 'emailAddress') {
            setEmailAddress(value);
        } else if (name === 'businessName') {
            setBusinessName(value);
        }

    };

    async function submitQuote() {
        setIsUploading(true); // Indicate the start of the upload process

        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        bodyData["numberOfVehicles"] = numberOfVehicles;
        bodyData["chargesPerWeek"] = chargesPerWeek;
        bodyData["averageChargeSize"] = averageChargeSize;
        bodyData["businessName"] = businessName;
        bodyData["emailAddress"] = emailAddress;
        bodyData["fullName"] = fullName;
        bodyData["chargePointOperators"] = selectedLogos;
        bodyData["selectedRegion"] = selectedRegion;

        console.log(JSON.stringify(selectedRegion));

        let fetch_link = `${rootUrl}/cpo/ppa/register`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        setIsUploading(false); // Indicate the start of the upload process

        if (response.status !== 200) {
            toast.error("Unfortunately it has not been possible to register your quote at this time", {
                position: 'bottom-center',
                duration: 5000,
            });
            return;
        }

        // resetCaptcha();
        setSubmitShow(false);
        navigate("/ppa/success")

    }





    return (
        <>

            {isUploading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
                <JourneyNav fixed="fixed-top" homePage={true} />
                <div className="contact-container" style={{ marginTop: "200px" }}>
                    <div>
                        <div className="container-fluid g-0">
                            <div className="row gy-2 justify-content-center">
                                <div className="col-xs-12 col-md-8 col-xl-6">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Build your Power Purchase Agreement Quote
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Number of Vehicles :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={numberOfVehicles !== null ? numberOfVehicles : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="numberOfVehicles"
                                                        id="numberOfVehicles"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Charges per week :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={chargesPerWeek !== null ? chargesPerWeek : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="chargesPerWeek"
                                                        id="chargesPerWeek"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Average Charge size (kW/h) :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={averageChargeSize !== null ? averageChargeSize : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="averageChargeSize"
                                                        id="averageChargeSize"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Monthly Requirement: {monthlyRequirementKWh?.toFixed(2)} kW/h
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Full Name :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={fullName !== null ? fullName : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="fullName"
                                                        id="fullName"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Email Address :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={emailAddress !== null ? emailAddress : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="emailAddress"
                                                        id="emailAddress"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Business Name :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                    <input
                                                        ref={currentInput}
                                                        defaultValue={businessName !== null ? businessName : ""}
                                                        type="text"
                                                        style={{ textAlign: "left" }}
                                                        className="textbox"
                                                        name="businessName"
                                                        id="businessName"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Charge Points Coverage :
                                                </span>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <label style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRegion.uk}
                                                            onChange={handleUKChange}
                                                        />
                                                        United Kingdom
                                                    </label>
                                                    <label style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRegion.england}
                                                            onChange={() => handleCountryChange('england')}
                                                        />
                                                        England
                                                    </label>
                                                    <label style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRegion.scotland}
                                                            onChange={() => handleCountryChange('scotland')}
                                                        />
                                                        Scotland
                                                    </label>
                                                    <label style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedRegion.wales}
                                                            onChange={() => handleCountryChange('wales')}
                                                        />
                                                        Wales
                                                    </label>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Select any preferred Operators
                                                </span>
                                            </div>
                                            <LogoGrid selectedLogos={selectedLogos} onLogoClick={handleLogoClick} />
                                            <Map
                                                selectedLocationDwelling={[]}
                                                vehicleMapData={[]}
                                                chargePoints={[]}
                                                polygons={[]}
                                                height={800}
                                                showButtons={false}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                            <ReCAPTCHA
                                                ref={(r) => setCaptchaRef(r)}
                                                sitekey={(process.env.REACT_APP_RECAPTCHA_KEY as string)!.toString() || ""}
                                                onChange={checkCaptcha}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", gap: 50, margin: "32px" }}>

                                            <button
                                                className="primary-btn"
                                                disabled={!submitShow}
                                                onClick={submitQuote}
                                            >
                                                Fetch Quotes
                                            </button>
                                        </div>
                                    </div>


                                </div>
                            </div >
                        </div>
                        <br />

                        {/* <PowerPurchaseAgreementQuotesTable businessChargingCategory={userInfo.businessCategory} quotes={quotes} acceptQuote={acceptQuote} /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PowerPurchaseAgreementPublicPage;