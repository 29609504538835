import React, { useState } from 'react';
import { Grid, Button, MenuItem, Select, Typography, Box, TextField, SelectChangeEvent } from '@mui/material';

type Cell = {
  day: string;
  hour: number;
};

type Selection = {
  name: string;
  cells: Cell[];
};

const PowerPurchaseAgreementCPOPage: React.FC = () => {
  const [selectedCells, setSelectedCells] = useState<Cell[]>([]);
  const [selections, setSelections] = useState<Selection[]>([]);
  const [currentSelection, setCurrentSelection] = useState<string | undefined>(undefined);
  const [selectionName, setSelectionName] = useState<string>('');

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);

  const toggleCell = (day: string, hour: number) => {
    const cell: Cell = { day, hour };
    const isSelected = selectedCells.some(c => c.day === day && c.hour === hour);

    if (isSelected) {
      setSelectedCells(selectedCells.filter(c => !(c.day === day && c.hour === hour)));
    } else {
      setSelectedCells([...selectedCells, cell]);
    }
  };

  const addSelection = () => {
    if (selectionName) {
      const newSelection: Selection = { name: selectionName, cells: selectedCells };
      setSelections([...selections, newSelection]);
      setSelectionName('');
      setSelectedCells([]);
    }
  };

  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    const selectedName = event.target.value as string;
    setCurrentSelection(selectedName);
    const selected = selections.find(s => s.name === selectedName);
    setSelectedCells(selected?.cells || []);
  };

  return (
    <Box>
      <Typography variant="h6">Week Hours Grid</Typography>
      <Select
        value={currentSelection || ''}
        onChange={handleSelectionChange}
        displayEmpty
        fullWidth
        style={{ marginBottom: '16px' }}
      >
        <MenuItem value="" disabled>Select a saved selection</MenuItem>
        {selections.map(selection => (
          <MenuItem key={selection.name} value={selection.name}>
            {selection.name}
          </MenuItem>
        ))}
      </Select>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(24, 1fr)',
          gap: '2px', // Replaces gridGap
        }}
      >
        {daysOfWeek.map(day =>
          hoursOfDay.map(hour => {
            const isSelected = selectedCells.some(c => c.day === day && c.hour === hour);
            return (
              <Button
                key={`${day}-${hour}`}
                onClick={() => toggleCell(day, hour)}
                style={{
                  backgroundColor: isSelected ? 'yellow' : 'grey',
                  height: '40px',
                }}
              >
                {`${day[0]}${hour}`}
              </Button>
            );
          })
        )}
      </Box>

      <Box mt={2}>
        <TextField
          label="Selection Name"
          value={selectionName}
          onChange={e => setSelectionName(e.target.value)}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={addSelection} fullWidth>
          Add Selection
        </Button>
      </Box>
    </Box>
  );
};


export default PowerPurchaseAgreementCPOPage;
