import React from 'react';
import { AiFillLinkedin, AiFillInstagram } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import './Footer.scss'

function Footer(prop: any) {
    const navigate = useNavigate()
    return (
        <div className="about_page_footer">

            <span onClick={() => navigate('/')} style={{ fontFamily: 'Qualy', flex: 1, fontSize: 80, color: 'white', cursor: 'pointer' }}><b>Evata</b></span>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 10, justifyContent: 'center' }}>
                <a href={'/'} >Home</a>
                <a href={'/about'} >About Us</a>
                {/* <a href={'/term_condition'} >Term Condition</a> */}
                <a href={'/privacy_policy'} >Privacy Policy</a>
                {/* <a href={'/frequent_question'}>Frequent Question </a> */}
                <a href={'/contact'}>Contact Us</a>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 10 }} >
                <p style={{ color: 'white' }}>To book a demo or to learn more about Evata, please <a href={'/contact'}><u>contact us</u></a>  </p>
                <p style={{ color: 'white' }}>Connect with us on:   </p>
                <div style={{ display: 'flex' }} onClick={() => { window.location.href = 'https://www.linkedin.com/company/evata-uk/' }}>
                    <AiFillLinkedin size={50} color={'white'} />
                </div>
            </div>
            <p style={{ color: 'white', fontSize: 15 }}>© Copyright 2022 Evata UK Limited</p>
        </div>
    );
}

export default Footer;