import ModalComponent from "components/common/Modal/Modal";
import React, { lazy, useContext, useState } from "react";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import { IoMdClose } from "react-icons/io";
import { TbFileText } from "react-icons/tb";
import { VscCloudUpload } from "react-icons/vsc";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import UserContext from "../../../../Context/UserProvider";
import { NotificationContext } from "../../../common/Notifications/NotificationProvider";

function difference(a1: any, a2: any) {
  var result = [];
  for (var i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}
function checking_null_undefined(array: any) {
  if (array === null || array === undefined) {
    return [];
  }
  return array;
}
const checkForUndefined = (type: any) => (type === "undefined" ? false : true);
function UploadForm(props: any) {
  const notificationContext = useContext(NotificationContext);
  const {userInfo, setUserInfo} = useContext(UserContext);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // const notificationContext = useContext(NotificationContext)
  //State
  const [fileContent, setFileContent] = useState([{ fileName: "", fileSize: 0, file: File, id: 0 }]);
  const [hoverZone, setHoverZone] = useState(false);
  // const [statusPolling, setStatusPolling] = props.statusPolling
  // const [fileUploadExceed, setFileUploadExceed] = props.fileUploadExceed;
  const [fileUploaded, setFileUploaded] = useState(false);
  // const [previousFile, setPreviousFile] = props.previousFile

  //Variable
  let filesDetails: any[] = [];

  const onDragEnter = (e: any): void => {
    e.preventDefault();
    setHoverZone(true);
  };
  const onDragLeave = (e: any): void => {
    e.preventDefault();
    setHoverZone(false);
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function addNewFile(currentFiles: [any], newFile: any) {
    let result = [] as any;
    for (let i = 0; i < newFile?.length; i++) {
      if (
        currentFiles
          ?.map((e: any) => {
            return e.fileName;
          })
          .indexOf(newFile[i].fileName) < 0
      ) {
        result.push(newFile[i]);
      }
    }
    return result;
  }

  const converFileBase64 = async (file: any) => {
    let files_list_arr = [];
    for (let i = 0; i < file.length; i++) {
      let current_file: any = await toBase64(file[i].file);
      files_list_arr.push({
        originalFilename: file[i].fileName,
        encodedFile: current_file.slice(21),
      });
    }
    return files_list_arr;
  };

  const submitForm = async (fileContent: any) => {
    //File preparation
    let fileToUpload = addNewFile([] as any, fileContent);
    if (fileToUpload.length <= 0) {
      notificationContext.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          id: v4(),
          type: "ERROR",
          message: "File already been upload",
        },
      });
      removeFile();
      return;
    }
    removeFile();
    setFileUploaded(true);

    let bodyData: { [name: string]: string } = {};
    bodyData["access_token"] = userInfo.access_token;
    bodyData["userId"] = userInfo.uuid;
    bodyData["businessUuid"] = userInfo.businessUuid;
    //Convert file for upload
    let files_list_arr = await converFileBase64(fileToUpload);
    bodyData["fileList"] = JSON.stringify(files_list_arr);
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

    let fetch_link = `${rootUrl}/infrastructure/dwelling/list/import`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };

    try {
      let response = await fetch(fetch_link, requestOptions);
      let res_obj = await response.json();
      if (response.status !== 200) {
        notificationContext.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            id: v4(),
            type: "ERROR",
            message: res_obj.notifications[0].code,
          },
        });
      }

      // 	return
      // }

      //Set status polling to poll the status update of current file comparision
      notificationContext.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          id: v4(),
          type: "SUCCESS",
          message: "File finished uploading",
        },
      });
      openModal();

      // setPreviousFile((previousFile: any) => [...previousFile, ...fileToUpload])
      setFileUploaded(true);
    } catch {
      notificationContext.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          id: v4(),
          type: "ERROR",
          message: "There is an error during upload",
        },
      });
      setFileUploaded(false);
    }
  };

  const removeFile = (): void => {
    setFileContent([{ fileName: "", fileSize: 0, file: File, id: 0 }]);
    setFileUploaded(false);
  };

  const changeHandler = (e: any) => {
    setHoverZone(false);
    let i = 0;
    let files_arr: any[] = [];
    Array.from(e.target.files)
      .filter((file: any) => file.type === "text/csv" || file.type === "application/zip"|| file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      .forEach((file: any) => {
        files_arr.push({
          fileName: file.name,
          fileSize: file.size,
          file: file,
          id: i,
        });
        i++;
      });
    setFileContent(files_arr as any);
    submitForm(files_arr);
    setFileUploaded(false);
  };
  return (
    <div className={`drop-area-background `}>
      <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
        <>
          <div
            onClick={() => {
              closeModal();
            }}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <IoMdClose size={30}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              margin: 10,
              padding: 50,
              justifyContent: "center",
              alignItems: "center",
              gap: 30,
            }}>
            <h1>Locations uploaded successfully</h1>
            <p style={{ maxWidth: 400, textAlign: "center" }}>
              Click{" "}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#00c1d5",
                }}
                onClick={() =>
                  navigate("/plan/business/locations", {
                    state: { showCategory: [false, true] },
                  })
                }>
                here
              </span>{" "}
              to see the list of locations
            </p>
          </div>
        </>
      </ModalComponent>

      <div style={{ marginBottom: 10 }}>
        <VscCloudUpload size={48} />
      </div>
      <h4>Upload files</h4>
      <p>Files must be in csv format.</p>

      <div className={`drop-area-container`}>
        <div
          className={`drop-area ${hoverZone ? "file-active" : ""} ${fileContent[0].fileName.length > 0 && "hide-border"}`}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            let i = 0;
            let files_list: any[] = [];
            setHoverZone(false);
            e.preventDefault();
            Array.from(e.dataTransfer.files)
              .filter((file: File) => file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
              .forEach(async (file) => {
                files_list.push({
                  fileName: file.name as string,
                  fileSize: file.size as number,
                  file: file as any,
                  id: i,
                });
                setFileContent(files_list as any);
                i++;
              });
          }}>
          <>
            <span> Drag & Drop your file(s) here or</span>
            <label>
              <input
                type="file"
                className="upload-btn"
                name="file"
                onChange={(e) => {
                  changeHandler(e);
                }}
                defaultValue=""
                accept=".xlsx,.csv,.zip"
                multiple
              />
              <a style={{ textDecoration: "underline", cursor: "pointer", color: "rgb(92,142,250)", fontWeight: "700" }}>browse</a>
            </label>
          </>
        </div>
      </div>
      <div>{fileContent[0].fileName.length > 0 ? filesDetails : <></>}</div>

      <br />
    </div>
  );
}

export default UploadForm;
