import UserContext from "Context/UserProvider";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import BalanceGraph from "components/charging/TakeOrPay/BalanceGraph";

interface OptionType {
    label: string;
    value: string;
    name: string;
}
function PowerPurchaseAgreementPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [parameter, setParameter] = useState({}) as any;
    const location = useLocation();
    const params = location.state as any;
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isError, setIsError] = useState(false);

    const [numberOfVehicles, setNumberOfVehicles] = useState<number | null>(null);
    const [chargesPerWeek, setChargesPerWeek] = useState<number | null>(null);
    const [averageChargeSize, setAverageChargeSize] = useState<number | null>(null);
    const [monthlyRequirementKWh, setMonthlyRequirementKWh] = useState<number | null>(null);

    const [agreement, setAgreement] = useState([]) as any

    const data = [
        { month: 'Aug', usage: 27000 },
        { month: 'Sep', usage: 28000 },
        { month: 'Oct', usage: 19000 },
        { month: 'Nov', usage: 24000 },
        { month: 'Dec', usage: 23000 },
        { month: 'Jan', usage: 30000 },
        { month: 'Feb', usage: 27000 },
        { month: 'Mar', usage: 26000 },
        { month: 'Apr', usage: 24000 },
        { month: 'May', usage: 19000 },
        { month: 'Jun', usage: 21000 },
        { month: 'Jul', usage: 22000 },    ];



    useEffect(() => {
        fetchPPA();

    }, []);

    async function fetchPPA() {
        setLoading(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        let fetch_link = `${rootUrl}/cpo/ppa/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        setAgreement(res_json.agreement);
        setLoading(false);

    }

    async function saveChargingPartnerRateCard() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        let chargingPartnerRateCard: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["chargingPartnerRateCard"] = chargingPartnerRateCard;
        bodyData["chargingPartnerRateCardUuid"] = parameter.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        chargingPartnerRateCard["energyCostAcSinglePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostAcThreePhaseGbpPerKWhOffPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostDcGbpPerKWhOffPeak"] = Number(parameter.energyCostDcGbpPerKWhOffPeak);
        chargingPartnerRateCard["energyCostAcSinglePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcSinglePhaseGbpPerKWhPeak);
        chargingPartnerRateCard["energyCostAcThreePhaseGbpPerKWhPeak"] = Number(parameter.energyCostAcThreePhaseGbpPerKWhPeak);
        chargingPartnerRateCard["energyCostDcGbpPerKWhPeak"] = Number(parameter.energyCostDcGbpPerKWhPeak);
        chargingPartnerRateCard["name"] = parameter.name;
        chargingPartnerRateCard["allowanceKWh"] = parameter.allowanceKWh;

        let fetch_link = `${rootUrl}/charging-partner/rate-card/save`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();

        if (response.status === 200) {
            toast.success('Rate Card Save Successful', {
                position: 'bottom-center',
                duration: 5000,
            });
            setIsDisabled(false);
        } else if (response.status === 400) {
            setIsDisabled(false);

            let errorMessage = "";

            switch (res_json.code) {
                // case "CHARGING_PARTNER_RATE_CARD_UUID_IS_MANDATORY":
                //     errorMessage = ("Please select a Rate Card from the list");
                //     break;
                default:
                    errorMessage = ("An error (" + res_json.code + ") occurred whilst saving this rate card.");
                    break;
            }

            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });

        }

    }


    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                agreement == null ? (
                    <div><span>No Power Purchase Agreement has been set up - please click <Link to="/agree/power-purchase-agreement/create">Create PPA</Link> to start</span></div>
                ) : (

                    <div className="container-fluid g-0">
                        <div className="row gy-2">

                            <div className="col-xs-12 col-md-6 col-xl-6">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Power Requirements                              </div>
                                    <div
                                        className="dashboard-card-content"
                                        style={{ gap: 20 }}>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Number of Vehicles :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.numberOfVehicles}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Charges Per Week :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.chargesPerWeek}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Average Size of Charge:
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.averageChargeSizeInKWh} kW/h
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6 col-xl-6">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Power Purchase Agreement                              </div>
                                    <div
                                        className="dashboard-card-content"
                                        style={{ gap: 20 }}>

                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Start Date :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.startDate}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Monthly kW/h commitment :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.monthlyCommitmentInKWh.toFixed(2)} kW/h
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Monthly Purchase commitment :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    £{agreement.monthlyCommitmentInGbp.toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6 col-xl-6">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Provider
                                    </div>
                                    <div
                                        className="dashboard-card-content"
                                        style={{ gap: 20 }}>

                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Charge Point Operator :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.chargePointOperator.name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Product
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    {agreement.rateCardName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Price per kW/h
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    £{agreement.ppaPricePerKWh.toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6 col-xl-6">
                                <div className="dashboard-card">
                                <div className="box-info">

                                    <BalanceGraph data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12 col-xl-12">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                <button
                                    className="primary-btn"
                                    disabled={isDisabled}
                                >
                                    Trade
                                </button>
                            </div>
                        </div>
                    </div>

                    </div>

                )
            )
            }
        </>
    );
}

export default PowerPurchaseAgreementPage;