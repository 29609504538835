import ModalComponent from "components/common/Modal/Modal";
import { NotificationContext } from "components/common/Notifications/NotificationProvider";
import TableSkelton from "components/common/SkeltonComponent/TableSkelton";
import ScenarioConfigContext from "Context/ScenarioConfigContext";
import UserContext from "Context/UserProvider";
import UseInterval from "custom_hook/useInterval";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { v4 } from "uuid";
import IceVehicleTable from "./components/IceVehicleTable";
import UploadProgressTable from "./components/UploadProgressTable";
import UploadTabScreen from "./components/UploadTabScreen";

function UploadIceVehiclePage(props: any) {
  return (
    <div className="drag-drop-container">
      <UploadTabScreen />
    </div>
  );
}

export default UploadIceVehiclePage;
