import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ErrorIcon } from '../../components/common/Icons';
import './ErrorPage.scss';

interface ErrorPageProps {

	statusCode?: string,
}

export default function ErrorPage(props: ErrorPageProps) {
	let navigate = useNavigate();
	const { isAuthenticated } = useAuth0()
	const goHome = () => {
		let path = isAuthenticated ? '/plan' : '/';
		navigate(path);
	}
	let errorMsg = ""
	if (props.statusCode === '503') {
		errorMsg = "This Page is under Construction"
	}
	else if (props.statusCode === '500') {
		errorMsg = "There is problem with request, Try again later!"
	}
	else if (props.statusCode === "505") {
		errorMsg = "You have already submit survey with this email!"
	}

	return (

		<div className="error-message">
			<div className="error-icon">
				<ErrorIcon />
			</div>
			<h1>{props.statusCode || "404"}</h1>
			<p>{errorMsg || "This page does not exist"}</p>
			<br />
			<button onClick={() => goHome()} className="btn">Home</button>
		</div>
	)
}