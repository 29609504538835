import React from 'react';
import {
  ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

interface DataPoint {
  month: string;
  usage: number;
}

interface BalanceGraphProps {
  data: DataPoint[];
}

const BalanceGraph: React.FC<BalanceGraphProps> = ({ data }) => {
  // Add a constant area value of 24960 for each data point
  const updatedData = data.map((item) => ({
    ...item,
    commitment: 24960, // Constant value for the area chart
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={updatedData}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" label={{ value: 'Month', position: 'insideBottomRight', offset: -5 }} />
        <YAxis label={{ value: 'kW/h', angle: -90, position: 'insideLeft',      dx: -10, 
            dy: -20,  offset: 30 }} />
        <Tooltip />
        <Legend />
        {/* Area component with a constant value for Monthly Commitment */}
        <Area type="monotone" dataKey="commitment" name="Monthly Commitment" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.2} />
        {/* Line component for Monthly Usage */}
        <Line type="monotone" dataKey="usage" name="Monthly Usage" stroke="#8884d8" strokeWidth={5} dot={true} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BalanceGraph;
