import { useContext } from "react";

import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function News(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">feed</span> News (Coming Soon)
                </div>
                <div className="dashboard-card-content">
                    <div className="news">
                        <span className="date">05/12/2022</span>
                        <p>
                            <a href="#">News headline text.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default News;
