import RequestEarlyBtn from "./RequestEarlyButton";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface navFixed {
    fixed: string;
    homePage: boolean;
}

const JourneyNav = (props: navFixed) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    let navigate = useNavigate();
    const goHome = () => {
        let path = isAuthenticated ? "/plan" : "/";
        navigate(path);
    };

    const goDashboard = () => {
        navigate("/login");
    };

    const goRegister = () => {
        navigate("/register");
    }

    let classes = `${props.fixed} nav-journey`;

    return (
        <nav className={classes}>
            <h1
                style={{ fontFamily: "Qualy", fontWeight: 800, color: "black", cursor: "pointer" }}
                onClick={() => goHome()}>
                {" "}
                <b>Evata</b>
            </h1>
            <span>
                <span
                    className="btn-primary2-nav"
                    onClick={goRegister}>
                    Register
                </span>
                <span
                    className="btn-primary2-nav"
                    onClick={goDashboard}>
                    Login
                </span>
            </span>
        </nav>
    );
};

export default JourneyNav;
