import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { CgLogOut } from "react-icons/cg";
import { FiChevronDown } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../../../custom_hook/useWindowSize";
import { links } from "../../../../static/dummy";
import Button from "@mui/material/Button";
import "./Sidebar.scss";
import { GiPorcupine } from "react-icons/gi";
import { isAccountRole } from "utils/roleUtils";

const Sidebar = (props: any) => {
    const [userInfo, setUserInfo] = props.userInfo;
    const [sidebar, setSidebar] = props.sidebar;
    let navigate = useNavigate();
    const location = useLocation();
    const [hoverMenu, setHoverMenu] = useState() as any;
    const [activeMenu, setActiveMenu] = useState("") as any;
    const routeChange = (url: string) => {
        navigate(`/${url}`);
        props.controlSideBar();
    };
    const auth0 = useAuth0();
    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes("plan/insight")) {
            setActiveMenu("Insight");
        } else if (pathname.includes("plan/scenario/configuration")) {
            setActiveMenu("Fleet");
        } else if (pathname.includes("plan/business")) {
            setActiveMenu("Business");
        } else if (pathname.includes("plan/dashboard")) {
            setActiveMenu("Account");
        } else if (pathname.includes("plan/private-charging-network")) {
            setActiveMenu("Account");
        } else {
            setActiveMenu("Account");
        }
        if (sidebar === true) {
            setHoverMenu("");
        }
    }, [sidebar]);
    return (
        <div
            className={`sidebar ${sidebar ? "close" : ""}`}
            id={"sidebar"}>
            <nav
                className="nav"
                id={"nav"}>
                <ul>
                    {links.map((item: any, index: any) => (
                        <SideMenu
                            key={index}
                            item={item}
                            privateChargingNetworkSubscription={userInfo.privateChargingNetworkSubscription}
                            hoverMenu={[hoverMenu, setHoverMenu]}
                            activeMenu={[activeMenu, setActiveMenu]}
                            routeChange={routeChange}
                            sidebar={[sidebar, setSidebar]}
                            controlSideBar={props.controlSideBar}
                            userInfo={[userInfo, setUserInfo]}
                        />
                    ))}
                </ul>
            </nav>
        </div>
    );
};
export default Sidebar;

//Top component of the sidebar

function SideMenu(props: any) {
    const sideMenu = useRef() as any;
    const [hoverMenu, setHoverMenu] = props.hoverMenu;
    const [activeMenu, setActiveMenu] = props.activeMenu;
    const [sidebar, setSidebar] = props.sidebar;
    const [userInfo, setUserInfo] = props.userInfo;

    const isAccountOnly = (isAccountRole(userInfo.userType) && props.item.acc) || !props.item.acc;

    if (isAccountOnly && ((props.privateChargingNetworkSubscription && props.item.pcn) || !props.item.pcn)) {

        return (

            <li
                className={`item ${hoverMenu === props.item.title ? "hover" : ""} ${activeMenu === props.item.title ? "active" : ""}`}
                id="item"
                onMouseEnter={() => setHoverMenu(props.item.title)}
                ref={sideMenu}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                    onClick={() => (props.routeChange(props.item.url), setActiveMenu(props.item.title))}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="material-symbols-outlined icon">{props.item.icon}</span>
                        <span>{props.item.title}</span>
                    </div>

                    {props.item.links.length > 0 ? (
                        <FiChevronDown size={20}
                        />
                    ) : (
                        <></>
                    )}
                </div>

                {/* <div className='sidebar-title'>{props.item.title}</div> */}
                <div
                    className="main-child-container"
                    id="child"
                    style={{ display: hoverMenu === props.item.title ? "block" : "none" }}>
                    {props.item.links.length > 0 ? (
                        <ChildItem
                            item={props.item}
                            privateChargingNetworkSubscription={props.privateChargingNetworkSubscription}
                            sidebar={sidebar}
                            routeChange={props.routeChange}
                            activeMenu={props.activeMenu}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </li>
        );
    } else {
        return (<div />);
    }
}

//Second component of the sidebar
function ChildItem(props: any) {

    return (
        <div className={`child-container`}>
            {props.item.links.map((link: any, index: any) => {
                return (
                    <LinkItem
                        key={index}
                        privateChargingNetworkSubscription={props.privateChargingNetworkSubscription}
                        mainTitle={props.item.title}
                        activeMenu={props.activeMenu}
                        link={link}
                        index={index}
                        length={props.item.links.length}
                        routeChange={props.routeChange}
                    />
                );
            })}
        </div>

    );

}

//Last component of the sidebar
function LinkItem(props: any) {
    const [activeMenu, setActiveMenu] = props.activeMenu;
    if ((props.privateChargingNetworkSubscription && props.link.pcn) || !props.link.pcn) {
        return (

            <div
                key={props.link.name}
                onClick={() => (props.routeChange(props.link.url), setActiveMenu(props.mainTitle))}
                className="link_container px-3 py-3"
                style={{ position: "relative" }}>
                <span className="link">{props.link.name}</span>
            </div>
        );
    } else {
        return (<div />);
    }
}
