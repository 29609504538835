import { Cell, Label, Pie, PieChart } from "recharts";
import './PieChartSkelton.scss';


function PieChartSkelton(props: any) {
    const data = [
        {
            name: "singe",
            value: 3000
        },
        {
            name: "mutlo",
            value: 4000
        },

    ]

    return (

        <div className="pie-chart col-auto" >
            <div className="left-title">
                <b style={{ fontSize: 15 }} className="text-skelton" >...</b > of journeys did  <b style={{ fontSize: 15 }}>not require </b> an en-route charge
            </div>
            <h2 className='pie-title-skelton skelton' ></h2>
            <PieChart
                width={props.width} height={props.height}
            >
                <text
                    x={props.width / 2}
                    y={props.width / 2 - 20}
                    fill={"grey"}
                    fontSize={10}
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    Total Journeys
                </text>
                <Pie
                    data={data}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    innerRadius={60}
                    outerRadius={130}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"

                >
                    <Label
                        style={{ fontSize: 50, fontWeight: 'bold', backgroundColor: 'red' }}
                        value={'. . .'}
                        className={"pie-lable-skelton pie-skelton"}
                        position='center'
                    />

                    {data.map((entry: any, index: any) => (
                        <Cell
                            key={`cell-${index}`}
                            className={"pie-skelton"}
                        />
                    ))}
                </Pie>
                {/* <Legend align={'center'} iconType={"circle"} layout={"horizontal"} height={40} iconSize={9} /> */}
            </PieChart>
        </div >
    );
}
export default PieChartSkelton;