import { DataGrid, GridAlignment, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

interface DataRow {
    id: string; // or string, depending on your data
    registrationNumber: string;
    make: string;
    model: string;
    combinedRange: string;
    uuid: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    electricVehicles: DataRow[];
    displayVin: boolean;
    displayRegLink: boolean;
    makeModel: boolean;
}

const ElectricVehicleTable: React.FC<DataGridChildProps> = ({ electricVehicles, displayVin, displayRegLink, makeModel }) => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const columns: GridColDef[] = [

        {
            field: "registrationNumber",
            headerName: "Registration Number",
            sortable: false,
            width: 180,
            align: "left",
            headerAlign: "left",
            renderCell: (cellValue: any) => {
                if (displayRegLink) {

                    return (
                        <span
                            className="hyper-link"
                            onClick={() =>
                                navigate("/agree/ppa/quote/build/journey-insight", {
                                    state: {
                                        id: cellValue.id,
                                        comparisonUuid: cellValue.row.uuid,
                                        //   categoryType: props.categoryType,
                                        //   fleetScenarioNumber: props.fleetScenarioNumber,
                                        //   fleetScenarioUuid: props.fleetScenarioUuid,
                                        //   description: cellValue.row.description,
                                        //   registrationNumber: props.registrationNumber,
                                    },
                                })
                            }>
                            {cellValue.row.registrationNumber}
                        </span>
                    );
                }
            },
        },
        ...(displayVin
            ? [
                {
                    field: "vin",
                    headerName: "VIN",
                    sortable: false,
                    width: 180,
                    align: "left" as GridAlignment, // Explicitly set as GridAlignment
                    headerAlign: "left" as GridAlignment, // Explicitly set as GridAlignment

                },
            ]
            : []),
        ...(makeModel ? [
            {
                field: "make",
                headerName: "Make",
                sortable: false,
                width: 180,
                align: "left" as GridAlignment, // Explicitly set as GridAlignment
                headerAlign: "left" as GridAlignment, // Explicitly set as GridAlignment
            },
            {
                field: "model",
                headerName: "Model",
                sortable: false,
                width: 180,
                align: "left" as GridAlignment, // Explicitly set as GridAlignment
                headerAlign: "left" as GridAlignment, // Explicitly set as GridAlignment
            },
        ]
            : [
                {
                    field: "make",
                    headerName: "Make/Model",
                    sortable: false,
                    width: 320,
                    align: "left" as GridAlignment, // Explicitly set as GridAlignment
                    headerAlign: "left" as GridAlignment, // Explicitly set as GridAlignment
                },

            ]),
        {
            field: "hasHomeChargingCapability",
            headerName: "Home Charging?",
            sortable: false,
            width: 140,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                params.value ?
                    <FontAwesomeIcon icon={faCheck} style={{ color: "green", fontSize: "20px", marginTop: "16px"}} /> :
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red", fontSize: "20px", marginTop: "16px" }} />
            ),
        },

        {
            field: "countCharges",
            headerName: "Charge Count",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
        },
        {
            field: "countDaysOfData",
            headerName: "Days of Data",
            sortable: false,
            width: 140,
            align: "right",
            headerAlign: "right",
        },
    ];

    return (
        <>
            <div className="table-box">
                {/* <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose className="close-btn" fontSize={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>

                            <UpdateSurveyPage currentSelected={currentSelected} fetchEmails={fetchEmails} />

                        </div>
                    </>

                </ModalComponent> */}

                <DataGrid
                    rows={electricVehicles}
                    autoHeight
                    {...electricVehicles}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}></DataGrid>
            </div>
        </>
    );
}

export default ElectricVehicleTable;
