import BusinessContext from "Context/BusinessContext";
import UserContext from "Context/UserProvider";
import AdvancedBookingMap from "components/charging/AdvancedBookingMap/AdvancedBookingMap";
import { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from "react-router-dom";
import Select from "react-select";

interface ChargingPartner {
    label: string;
    value: string;
    name?: string; // Include other properties as optional if they might not always be present
}

interface ElectricVehicleDriver {
    label: string;
    value: string;
    name?: string; // Include other properties as optional if they might not always be present
}

interface Cell {
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED';
    originalStatus: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED'; // New property to track original status
    uuid: string | null;
    pill: string;
    connectorUuid: string;
    reserved: boolean;
    available: boolean;
    identifier?: string; // Optional, if you're adding this property dynamically
}

interface DetailsPanelProps {
    // onChange: (e: any) => void;
    // reservationDate: string;
    // setReservationDate: (e: string) => void;
    // electricVehicleDrivers: ElectricVehicleDriver[];
    // electricVehicleDriverValue: {};
    // handleSearchClick: () => void;
    // handleCellClick: (cell: Cell) => void;
    // isCellSelected: (cell: Cell) => boolean;
    // handleSaveClick: () => void;
    // daysLabels: [];
    // chargePoints: [];
    // isDisabled: boolean;
    // loading: boolean;

}



const AdvancedBookingPanel: React.FC<DetailsPanelProps> = ({ }) => {
    const { userInfo, setUserInfo } = useContext(UserContext);

    const [hourReservationData, setHourReservationData] = useState([]) as any;
    const [selectWeekData, setSelectWeekData] = useContext(BusinessContext) as any;
    const [loading, setLoading] = useState(true);
    const [selectedWeek, setSelectedWeek] = useState([]) as any;
    const [daysLabels, setDayLabels] = useState([]) as any;
    const location = useLocation();
    const params: any = location.state;
    const [activeMenu, setActiveMenu] = useState(selectWeekData ? selectWeekData.weekNumber : 0);
    const [isError, setIsError] = useState(false);
    const [chargingPartnerOptions, setChargingPartnerOptions] = useState({ label: "None", value: "NONE", name: "chargingPartner" }) as any;
    const [chargingPartnerValue, setChargingPartnerValue] = useState<ChargingPartner | {}>({});
    const [isDisabled, setIsDisabled] = useState(false);
    const dwellingInfo = location.state as any; // This is the state passed from the Partner component
    //  const [dwellingInfo,setDwellingInfo] = useState([location.state]) as any;
    const [chargePoints, setChargePoints] = useState([]) as any;

    const [selectedCells, setSelectedCells] = useState<Cell[]>([]);
    const [reservationDate, setReservationDate] = useState('');
    const [electricVehicleDrivers, setElectricVehicleDrivers] = useState([]) as any;
    const [electricVehicleDriverValue, setElectricVehicleDriverValue] = useState<ElectricVehicleDriver | {}>({});
    const [chargePointConnectorUuid, setChargePointConnectorUuid] = useState('');





    const handleCellClick = (cell: Cell, chargePointConnectorUuid: string) => {

        if (cell.status === "UNAVAILABLE") {
            return;
        }

        // Assuming you're adding an 'identifier' property to each cell for easy identification
        const cellIdentifier = cell.identifier || `${cell.connectorUuid}-${cell.pill}`;
        const isSelected = selectedCells.some(selectedCell => selectedCell.identifier === cellIdentifier);
        setChargePointConnectorUuid(cell.connectorUuid);

        if (isSelected) {
            setSelectedCells(selectedCells.filter(selectedCell => selectedCell.identifier !== cellIdentifier));
            cell.status = cell.originalStatus;
        } else {

            switch (cell.status) {
                case "RESERVED":
                    cell.status = "AVAILABLE";
                    cell.reserved = false;
                    cell.available = true;
                    break;
                case "AVAILABLE":
                    cell.status = "RESERVED";
                    cell.reserved = true;
                    cell.available = false;
                    break;

            }
            setSelectedCells([...selectedCells, { ...cell, identifier: cellIdentifier }]);
        }
    };

    const handleSaveClick = () => {
        // Trigger API call or any other action to process selectedCells

        saveBooking();
    };

    async function saveBooking() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        let chargingPartner: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["selectedCells"] = selectedCells;
        bodyData["reservationDate"] = reservationDate;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["chargePointConnectorUuid"] = chargePointConnectorUuid;
        bodyData["electricVehicleDriverValue"] = electricVehicleDriverValue;
        bodyData["slots"] = selectedCells;

        let fetch_link = `${rootUrl}/cpo/charge-points/advanced-booking/create`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {
            setIsDisabled(false);
        }


    }


    const isCellSelected = (cell: Cell) => {
        const cellIdentifier = `${cell.connectorUuid}-${cell.pill}`;
        return selectedCells.some(selectedCell => selectedCell.identifier === cellIdentifier);
    };


    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    function isNull(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    useEffect(() => {
        const initializeData = async () => {
            // Set the current date as the reservation date if it's not already set
            const today = new Date();
            const formattedDate = today.toISOString().substring(0, 10); // Formats the date to yyyy-MM-dd
            if (!reservationDate) {
                setReservationDate(formattedDate);
            }

            // Fetch electric vehicle drivers
            let bodyData = { "uuid": userInfo.uuid, "businessUuid": userInfo.businessUuid };
            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/business/electric-vehicle/driver/list`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            const drivers = res_json.drivers.map((e: any) => ({ ...e, label: e.registrationNumber + " / " + e.driver, value: e.uuid, name: "electricVehicleDriver" }));
            setElectricVehicleDrivers(drivers);

            if (drivers.length > 0) {
                setElectricVehicleDriverValue(drivers[0]); // Set the first driver as selected
                // Ensure the reservation date is used in the search
                search(formattedDate, drivers[0].value); // Initiate search with the first driver's uuid and current date
            }

            setLoading(false);
        };

        initializeData();
    }, []); // Dependency array is empty, indicating this effect runs only once on mount

    const handleSearchClick = () => {
        // Check if electricVehicleDriverValue is an ElectricVehicleDriver object and has a 'value' property
        if ('value' in electricVehicleDriverValue && electricVehicleDriverValue.value) {
            search(reservationDate, electricVehicleDriverValue.value); // Use both reservationDate and electricVehicleDriver's uuid
        } else {
            // Handle the case where no electric vehicle driver is selected or the object does not match the expected type
        }
    };


    async function search(date: string, electricVehicleDriverUuid: string) {
        setLoading(true);

        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;

        bodyData["electricVehicleDriverUuid"] = electricVehicleDriverUuid;
        bodyData["businessUuid"] = dwellingInfo.businessUuid;
        bodyData["dwellingUuid"] = dwellingInfo.uuid;
        bodyData["reservationDate"] = date;


        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/cpo/charge-points/availability/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.chargePoints = checking_null_undefined(res_json.chargePoints);

        setChargePoints(res_json.chargePoints.map((e: any) => ({ ...e, id: e.uuid })));

        console.log("CHARGE POINTS " + JSON.stringify(chargePoints));


        setLoading(false);
    }


    function onChange(e: any) {

        if (e.name === "chargingPartner") {


            const selectedChargingPartner = chargingPartnerOptions.find((i: any) => i.uuid === e.uuid);


            if (selectedChargingPartner != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedChargingPartner.name, // assuming it has a 'name' field for the label
                    value: selectedChargingPartner.uuid, // the unique identifier, used as value
                    ...selectedChargingPartner, // spread the rest of the object properties if there are any others
                };

                setChargingPartnerValue(selectedValue);

            }
        } else if (e.name === "electricVehicleDriver") {

            const selectedElectricVehicleDriver = electricVehicleDrivers.find((i: any) => i.uuid === e.uuid);

            if (selectedElectricVehicleDriver != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedElectricVehicleDriver.registrationNumber + " / " + selectedElectricVehicleDriver.driver, // assuming it has a 'name' field for the label
                    value: selectedElectricVehicleDriver.uuid, // the unique identifier, used as value
                    ...selectedElectricVehicleDriver, // spread the rest of the object properties if there are any others
                };

                setElectricVehicleDriverValue(selectedValue);

            }


        } else if (e.target.name === "reservationDate") {
            setReservationDate(e.target.value);
        }
    }




    // useEffect(() => {
    //     fetchChargingPartners();
    // }, []);

    useEffect(() => {
        // fetchDwellings();
    }, [chargingPartnerValue]);

    useEffect(() => {
    }, [selectedCells]);




    return (
        <div className="container-fluid g-0">
            <div className="row gy-2"></div>
            <div className="col-xs-12 col-md-6 col-xl-4">
                <div className="dashboard-card" >
                    <div className="dashboard-card-title">
                        <span className="icon material-symbols-outlined">view_timeline</span>
                        Selection Criteria
                    </div>
                    <div
                        className="dashboard-card-content"
                        style={{ gap: 20 }}>
                        <div className="box-info">
                            <span
                                className="item"
                                style={{ width: 300 }}>
                                Vehicle / Driver :
                            </span>
                            <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "246px" }}>
                                <Select
                                    styles={{ container: (base) => ({ ...base, width: "246px" }) }}
                                    value={electricVehicleDriverValue}
                                    name="electricVehicleDriver"
                                    onChange={onChange}
                                    options={electricVehicleDrivers}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="dashboard-card-content"
                        style={{ gap: 20 }}>
                        <div className="box-info">
                            <span
                                className="item"
                                style={{ width: 300 }}>
                                Reservation Date :
                            </span>
                            <ReactDatePicker
                                selected={reservationDate ? new Date(reservationDate) : null}
                                onChange={(date) => setReservationDate(date ? date.toISOString().substring(0, 10) : '')}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                name="reservationDate"
                                id="reservationDate"

                            />


                        </div>
                    </div>

                </div>

            </div>



            <div className="col-xs-12 col-md-12 col-xl-12">
                <div className="">

                    <div
                        className=""
                        style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                        <button
                            className="primary-btn"
                            disabled={isDisabled}
                            onClick={handleSearchClick}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>

            {loading ? (
                <>Loading ...</>
            ) : (
                <>
                    {
                        chargePoints.length > 0 && chargePoints.map((chargePoint: any, index: number) => (
                            <AdvancedBookingMap
                                key={index} // Assuming chargePoint has no unique identifier; if it does, use that instead
                                data={chargePoint}
                                labels={daysLabels}
                                onCellClick={handleCellClick}
                                isCellSelected={isCellSelected}
                            />
                        ))
                    }
                    <div className="col-xs-12 col-md-12 col-xl-12">
                        <div className="">

                            <div
                                className=""
                                style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                <button
                                    className="primary-btn"
                                    onClick={handleSaveClick}>Save Selected Cells</button>
                            </div>
                        </div>
                    </div>



                </>
            )
            }
        </div >
    );
}

export default AdvancedBookingPanel;
