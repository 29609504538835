import React, { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import './Question.scss'

function Question(props: any) {
    const [active, setActive] = useState(false)
    return (
        <div className="question-box">
            <div className="question-title" onClick={() => setActive(pre => (!pre))}>
                <div> {props.question}</div>
                <AiOutlinePlusCircle />
            </div>
            {active &&
                <div className="answer">
                    <br />
                    {props.answer}
                </div>}
        </div>
    );
}

export default Question;