import ModalComponent from 'components/common/Modal/Modal';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { ChangeEvent, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router';
import UserContext from '../../Context/UserProvider';
import QuestionTable from "../../components/charging/SiteAccessPolicyComponent/QuestionTable";
import DocumentsTable from 'components/common/DocumentsTable';
import { SiteAccessPolicySaveRequest } from 'interfaces/api/SiteAccessPolicySaveRequest';
import './SiteAccessPolicy.css';
import { CloudStorageObject } from 'interfaces/CloudStorageObject';



// interface FileInfo {
//     name: string;
//     type: string;
// }


function reducer(state: any, action: any) {
    switch (action.input) {
        case "surveyName":
            return { ...state, surveyName: action.value }

        default:
            return state


    }

}

type SignedUrlResponse = {
    ramsDocuments: CloudStorageObject[];
};

function SiteAccessPolicyEditPage(props: any) {

    const { userInfo, setUserInfo } = useContext(UserContext)
    const [questions, setQuestions] = useState([]) as any
    const [ramsDocuments, setRamsDocuments] = useState<CloudStorageObject[]>([]);
    const [missingInput, setMissingInput] = useState("")
    let navigate = useNavigate()

    const location = useLocation() as any;
    const [siteAccessPolicyUuid, setSiteAccessPolicyUuid] = useState(location.state?.siteAccessPolicyUuid);

    useEffect(() => {
        if (location.state?.siteAccessPolicyUuid) {
            setSiteAccessPolicyUuid(location.state.siteAccessPolicyUuid);
        }
    }, [location.state?.siteAccessPolicyUuid]);
    const initialState = {
        surveyName: "",
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reference, setReference] = useState({}) as any
    const [modalIsOpen, setIsOpen] = useState(false)
    const [isUploading, setIsUploading] = useState(false);
    const [files, setFiles] = useState<FileList | null>(null);

    const [documentFiles, setDocumentFiles] = useState<CloudStorageObject[]>([]);
    const [videoFiles, setVideoFiles] = useState<CloudStorageObject[]>([]);

    useEffect(() => {
        // Initialize document and video files separately from ramsDocuments
        setDocumentFiles(ramsDocuments.filter(doc => doc.type === 'DOCUMENT'));
        setVideoFiles(ramsDocuments.filter(doc => doc.type === 'VIDEO'));
    }, [ramsDocuments]);

    const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles: CloudStorageObject[] = Array.from(event.target.files).map((file, index) => ({
                id: `temp-${Date.now()}-${index}`, // Temporary unique ID
                name: file.name,
                type: "VIDEO",
                status: 'Not Saved' as 'Not Saved', // Custom status to indicate the file is not yet saved
                file: file, // Store the file object for uploading
                putSignedUrl: "",
                deleteSignedUrl: "",
                fullName: ""
            }));

            // Add new files to the start of the array
            setRamsDocuments(prev => [...newFiles, ...prev]);

        }
    };

    // Use the FileList type for the files state.
    const handleDocumentChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles: CloudStorageObject[] = Array.from(event.target.files).map((file, index) => ({
                id: `temp-${Date.now()}-${index}`, // Temporary unique ID
                name: file.name,
                type: "DOCUMENT",
                status: 'Not Saved' as 'Not Saved', // Custom status to indicate the file is not yet saved
                file: file, // Store the file object for uploading
                putSignedUrl: "",
                deleteSignedUrl: "",
                fullName: ""
            }));

            // Add new files to the start of the array
            setRamsDocuments(prev => [...newFiles, ...prev]);
        }
    };



    const removeDocument = (id: string, type: string) => {
        if (type === 'DOCUMENT') {
            setDocumentFiles(prev => prev.filter(doc => doc.id !== id));
        } else if (type === 'VIDEO') {
            setVideoFiles(prev => prev.filter(doc => doc.id !== id));
        }


    };

    const save = async () => {
        setIsUploading(true); // Indicate the start of the upload process
    
        // Merge documentFiles and videoFiles to create a new snapshot of ramsDocuments
        const updatedRamsDocuments = [...documentFiles, ...videoFiles];
        console.log("ALL RAMS " + JSON.stringify(updatedRamsDocuments));
    
        try {
            // Handle deletion of documents marked as 'Deleted'
            const toDelete = updatedRamsDocuments.filter(doc => doc.status === 'Deleted');
            const deletePromises = toDelete.map(doc =>
                fetch(doc.deleteSignedUrl, {
                    method: 'DELETE',
                })
            );
            await Promise.all(deletePromises);
    
            // Remove deleted documents from the updatedRamsDocuments before upload operations
            let postDeleteDocuments = updatedRamsDocuments.filter(doc => doc.status !== 'Deleted');
    
            // Filter out 'Not Saved' documents that have a file to upload
            const toUpload = postDeleteDocuments.filter(doc => doc.status === 'Not Saved' && doc.file);
    
            if (toUpload.length > 0) {
                const fileInfos = toUpload.map(doc => ({ name: doc.name, type: doc.type }));
    
                const urls = await fetchSignedUrls(fileInfos, await submitSurvey());
    
                const uploadPromises = urls.map((url, index) => {
                    const file = toUpload[index].file!; // Non-null assertion is safe due to the filter
                    return fetch(url.putSignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                        },
                    });
                });
    
                await Promise.all(uploadPromises);
    
                // Update the status of uploaded documents to 'Saved'
                postDeleteDocuments = postDeleteDocuments.map(doc => ({
                    ...doc,
                    status: doc.status === 'Not Saved' && doc.file ? 'Saved' : doc.status
                }));
            }
    
            // Set the final state of ramsDocuments
            setRamsDocuments(postDeleteDocuments);
    
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('Error uploading files');
        } finally {
            setIsUploading(false); // Indicate the end of the upload process
            openModal();
        }
    };
    
    // Update the type for files parameter to match the expected structure
    const fetchSignedUrls = async (fileInfos: { name: string; type: string }[], sasUuid: string): Promise<CloudStorageObject[]> => {


        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/rams/signedurls/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ files: fileInfos, businessUuid: userInfo.businessUuid, siteAccessPolicyUuid: sasUuid }),
        };

        let response = await fetch(fetch_link, fetch_option);

        if (response.status !== 200) {
            setIsError(true);
        }

        const data: SignedUrlResponse = await response.json();

        return data.ramsDocuments; // Your backend should respond with an array of URLs
    };

    const updateDocuments = (updatedDocs: CloudStorageObject[], type: string) => {
        if (type === 'DOCUMENT') {
            setDocumentFiles(updatedDocs);
        } else if (type === 'VIDEO') {
            setVideoFiles(updatedDocs);
        }
    };

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const addNewQuestion = () => {
        setQuestions((prevQuestions: string | any[]) => [
            ...prevQuestions,
            { id: `${prevQuestions.length + 1}`, text: '', type: 'STATEMENT' }
        ]);

    };

    const fetchSurvey = async () => {
        let bodyData: { [name: string]: string } = {};

        if (siteAccessPolicyUuid != null) {
            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["siteAccessPolicyUuid"] = siteAccessPolicyUuid;
            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/site-access-policy/get`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            setQuestions(res_json.survey.questions.map((e: any) => ({ ...e, id: e.uuid, text: e.text, type: e.type })));
            setRamsDocuments(res_json.ramsDocuments.map((e: any) => ({ ...e, id: e.fullName, name: e.name, type: e.type, fullName: e.fullName, signedUrl: e.signedUrl })));
            dispatch({ input: "surveyName", value: res_json.survey.name });

            console.log("RES RES " + JSON.stringify(res_json.ramsDocuments));

        }


        console.log(JSON.stringify(ramsDocuments));

        setLoading(false);

    };

    useEffect(() => {
        fetchSurvey();
    }, []);

    function validateForm() {
        let surveyName = document.getElementById('surveyName') as HTMLInputElement
        if (state.surveyName === '') {
            surveyName.style.borderColor = "red"
            setMissingInput("Survey Name is required ")
            return false
        }


        return true


    }



    const handleQuestionUpdate = useCallback((updatedQuestions: any) => {
        setQuestions(updatedQuestions);
    }, []);


    function valueToString(value: null) {
        return value == null ? '' : String(value);
    }

    const submitSurvey = async () => {
        const validationStatus = validateForm()
        if (!validationStatus) {
            return null; // Return null if validation fails
        }

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        const bodyData = {
            businessUuid: userInfo.businessUuid,
            uuid: userInfo.uuid,
            surveyName: state.surveyName,
            questions: questions,
            siteAccessPolicyUuid: siteAccessPolicyUuid,
            ramsDocuments: ramsDocuments,
        };

        let fetch_link = `${rootUrl}/site-access-policy/save`;
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return null; // Return null in case of HTTP errors
        }
        let res_json = await response.json();
        console.log("RESULT RESULT " + JSON.stringify(res_json));

        return res_json.uuid; // Assuming 'uuid' is the name of the key where the UUID is stored
    };



    function onChange(e: any) {
        let action: any
        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            }
        } else {
            action = {
                input: e.name,
                value: e.value,
            }
        }
        dispatch(action)
    }

    return (
        <>
            {
                isError ? <ErrorPage statusCode='505' />
                    :
                    <>
                        {
                            loading ? <>loading.......</> :
                                <div className="container-fluid g-0">
                                    <form action="" style={{ display: 'flex', flexDirection: 'column', gap: 30, marginBottom: 24 }} >
                                        <div style={{ display: 'flex', gap: 30, }}>
                                            <span style={{ paddingTop: 6 }}>Site Access Policy Name : </span>
                                            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 200 }}>
                                                <input type="text" defaultValue={valueToString(state.surveyName)} className="textbox" name="surveyName" onChange={onChange} id='surveyName' required />
                                                {/* <span style={{ marginLeft: 10 }} >%</span> */}
                                            </div>
                                        </div>


                                    </form>
                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Partnership / Contractual Documents
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <label htmlFor="document-upload" className="action-button">
                                                    Upload Files
                                                    <input type="file" id="document-upload" multiple onChange={handleDocumentChange} style={{ display: 'none' }} />
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className="user-survey-container" style={{ marginTop: 10 }}>
                                            <DocumentsTable data={documentFiles} onRemove={(id) => removeDocument(id, 'DOCUMENT')} onUpdate={(docs) => updateDocuments(docs, 'DOCUMENT')} />
                                                <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Instructional Videos
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <label htmlFor="video-upload" className="action-button">
                                                    Upload Videos
                                                    <input type="file" id="video-upload" multiple onChange={handleVideoChange} style={{ display: 'none' }} accept="video/*" />
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className="user-survey-container" style={{ marginTop: 10 }}>
                                            <DocumentsTable data={videoFiles} onRemove={(id) => removeDocument(id, 'VIDEO')} onUpdate={(docs) => updateDocuments(docs, 'VIDEO')} />
                                                <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Driver Instructions
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                                            <div className="user-survey-container" style={{ marginTop: 10 }}>


                                                <QuestionTable data={questions} onUpdate={handleQuestionUpdate}
                                                />
                                                <button style={{ width: 140, marginTop: 16 }} className="" onClick={addNewQuestion}>
                                                    Add a question
                                                </button>

                                                <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>


                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, gap: 20 }}>

                                        <button className='primary-btn' onClick={() => save()}>Save</button>
                                    </div>

                                </div>

                        }
                        <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                            <div
                                onClick={() => {
                                    closeModal();
                                }}
                                style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IoMdClose size={20}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    marginTop: 10,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 30,
                                }}>
                                <p style={{ maxWidth: 400 }}>Site Access Policy saved</p>
                                <div style={{ display: "flex", flexDirection: "row", gap: 90 }}>
                                    <button
                                        className="primary-btn"
                                        onClick={() => {
                                            closeModal();
                                        }}>
                                        OK
                                    </button>

                                </div>
                            </div>
                        </ModalComponent>
                        {isUploading && (
                            <div className="spinner-overlay">
                                <div className="spinner"></div>
                            </div>
                        )}
                    </>
            }

        </>

    );
}

export default SiteAccessPolicyEditPage;