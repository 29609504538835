import { DirectionsRenderer } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GiConsoleController } from "react-icons/gi";
import Map from "../../common/MapComponent/Map";
import MapStyle from "../../common/MapComponent/MapStyle";
import originImage from "../../../static/icons/map_icon/png/002-location-pin.png";
import chargingImage from "../../../static/icons/map_icon/png/001-charging-station.png";
import destinationImage from "../../../static/icons/map_icon/png/003-placeholder.png";
import "./MapWithRoute.scss";
import { MdOutlineBackHand } from "react-icons/md";
import UserContext from "../../../Context/UserProvider";

let markers: google.maps.Marker[] = [];
function MapWithRoute(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  let originIcon = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/icons/map_icon/png/00222-location-pin.png" : originImage;
  let destinationIcon = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/icons/map_icon/png/003-placeholder.png" : destinationImage;
  let chargingIcon = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/icons/map_icon/png/001-charging-station.png" : chargingImage;
  const [error, setError] = useState() as any;
  const directionsDisplay = useRef(null);
  const [roadResult, setRoadResult] = useState() as any;
  const [map, setMap] = useState() as any;
  const [maps, setMaps] = useState() as any;
  const distance = props.selectedDistance;
  const places = props.data[props.selectedJourney].map((e: any) => ({ ...e, lat: +e.latLong.split(",")[0], lng: +e.latLong.split(",")[1] }));
  const defaultProps = {
    center: {
      lat: 53.40979,
      lng: -2.15761,
    },
    zoom: 7,
  };
  let waypts: google.maps.DirectionsWaypoint[] = [];
  const waypoints: any = places.map((p: any) => ({
    location: { lat: p.lat, lng: p.lng, chargePoint: p.chargePoint },
    isWayPoints: !(p.type === "RE_ROUTE"),
    stopover: p.type === "CHARGE_POINT",
  }));

  for (let i = 1; i < waypoints.length - 1; i++) {
    waypoints[i].isWayPoints && waypts.push({ location: waypoints[i].location, stopover: waypoints[i].stopover });
  }
  const origin = waypoints[0].location;
  const destination = waypoints[waypoints.length - 1].location;

  let infoMarker = (markerInfo: any) => {
    return new google.maps.InfoWindow({
      content: `
      <div style ="display:flex; flex-direction:column; gap: 20px">
      <span >
      Location : ${markerInfo.properties.label}
      </span>
      <span>
      Connector Type : ${markerInfo.properties.connectorUsed.connectorType}
      </span>
      <span>
      Connector Rate : ${markerInfo.properties.connectorUsed.rateKW}
      </span>
      <span>
      Power Type : ${markerInfo.properties.connectorUsed.powerType}
      </span>
      </div>
      `,
    });
  };

  function handleApiLoaded(map: any, maps: any) {
    var icons = {
      start: new maps.MarkerImage(
        // URL
        originIcon,
        // (width,height)
        new google.maps.Size(35, 35),
        // The origin point (x,y)
        new google.maps.Point(0, 0),
        // The anchor point (x,y)
        new google.maps.Point(22, 32)
      ),
      end: new maps.MarkerImage(
        // URL
        destinationIcon,
        // (width,height)
        new google.maps.Size(35, 35),
        // The origin point (x,y)
        new google.maps.Point(0, 0),
        // The anchor point (x,y)
        new google.maps.Point(22, 32)
      ),
      charge: new maps.MarkerImage(
        // URL
        chargingIcon,
        // (width,height)
        new google.maps.Size(35, 35),
        // The origin point (x,y)
        new google.maps.Point(0, 0),
        // The anchor point (x,y)
        new google.maps.Point(22, 32)
      ),
      waypoints: new maps.MarkerImage(
        "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=1|FF0000|000000",
        // (width,height)
        new google.maps.Size(35, 35),
        // The origin point (x,y)
        new google.maps.Point(0, 0),
        // The anchor point (x,y)
        new google.maps.Point(22, 32)
      ),
    };

    function addMarker(position: any, icon: any, title: any, chargingStation: boolean, map: any) {
      const marker = new maps.Marker({
        position: position,
        map: map,
        icon: icon,
        // title: title,
      });
      if (chargingStation) {
        const markerInfo = infoMarker(position.chargePoint);
        google.maps.event.addListener(marker, "mouseover", function () {
          markerInfo.open(map, marker);
        });
        // google.maps.event.addListener(map, "click", function () {
        //   markerInfo.close();
        //   google.maps.event.removeListener(marker);
        //   google.maps.event.removeListener(map);
        // });
      }
      markers.push(marker);
    }
    function setMapOnAll(map: google.maps.Map | null) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    }
    // Removes the markers from the map, but keeps them in the array.
    function hideMarkers(): void {
      setMapOnAll(null);
    }
    function deleteMarkers(): void {
      hideMarkers();
      markers = [];
    }
    deleteMarkers();
    addMarker(origin, icons.start, "start", false, map);
    addMarker(destination, icons.end, "start", false, map);
    let wayIndex = 1;
    waypts.map((location: any, index: any) => {
      if (!location.stopover) {
        addMarker(
          location.location,
          new maps.MarkerImage(
            `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${wayIndex}|FF0000|000000`,
            new google.maps.Size(35, 35),
            // The origin point (x,y)
            new google.maps.Point(0, 0),
            // The anchor point (x,y)
            new google.maps.Point(22, 32)
          ),
          "enroute location",
          false,
          map
        );
        wayIndex++;
      }
    });

    waypts.map((location: any) => {
      location.stopover && addMarker(location.location, icons.charge, "charging station", true, map);
    });

    var lineSymbol = {
      path: "M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0",
      fillColor: "black",
      strokeColor: "black",
      strokeWeight: 2,
      strokeOpacity: 1,
    };

    var polylineDotted = {
      strokeColor: "#3FB5F9",
      strokeOpacity: 1,
      fillOpacity: 0,
      strokeWeight: 10,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "200px",
        },
      ],
    };

    var rendererOptions = {
      map: map,
      suppressMarkers: true,
      polylineOptions: polylineDotted,
    };
    const directionsService = new maps.DirectionsService();
    if (directionsDisplay.current === null) {
      directionsDisplay.current = new maps.DirectionsRenderer(rendererOptions);
    }
    function secondsToTime(secs: any) {
      var hours = Math.floor(secs / (60 * 60));

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);

      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);

      var obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      var result = obj.h.toString() + " hour " + obj.m.toString() + " mins";
      return result;
    }
    // const directionsDisplay = new maps.DirectionsRenderer(rendererOptions);
    const directionDisplay = directionsDisplay.current as any;
    directionDisplay.setMap(map);
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        // polylineQuality: "HIGH_QUALITY",
        drivingOptions: {
          departureTime: new Date(Date.now()), // for the time N milliseconds from now.
          trafficModel: "optimistic",
        },
        provideRouteAlternatives: false,
        waypoints: waypts,
        region: "GB",
      },
      (result: any, status: any) => {
        if (status === "OK") {
          directionDisplay.setDirections(result);
          // var leg = result.routes[0].legs[0];
          let distance = 0;
          let travelTime = 0;
          result.routes[0].legs.map((e: any) => {
            distance = distance + e.distance.value;
            travelTime = travelTime + e.duration.value;
          });
          setRoadResult({ distance: Math.round(distance / 1000).toString() + " km", duration: secondsToTime(travelTime) });
          // leg.via_waypoints.map((e: any) => makeMarker(e, icons.charge, "charge", map))
        } else {
          directionDisplay.setMap(null);
          setError(result);
        }
      }
    );
  }
  useEffect(() => {
    map && maps && handleApiLoaded(map, maps);
    // handleApiLoaded(map, maps)
  }, [props.selectedJourney, map, maps]);

  return (
    <div className="route-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ styles: MapStyle.light }}
        // onClick={(obj: any) => { if (props.enableChangeLocation) { handleMapClick(obj) } }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => (setMap(map), setMaps(maps))}></GoogleMapReact>
      {roadResult && (
        <div className="d-flex flex-row justify-content-around ">
          <p>Distance Travelled: {parseInt(distance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName}</p>
          <p>Travel Time: {roadResult?.duration}</p>
        </div>
      )}
    </div>
  );
}

export default MapWithRoute;
