import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { IoMdClose } from 'react-icons/io';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import ModalComponent from '../../components/common/Modal/Modal';
import LoaderModal from 'components/common/Modal/LoaderModal';
import './RegisterPage.scss';


function RegisterPage(props: any) {

    const [submitShow, setSubmitShow] = useState(false)
    const [dataSent, setDataSent] = useState('')
    const [showDataSent, setShowDataSent] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false);


    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setDataSent("");
        setIsOpen(false);
    }
    let captcha: any
    const checkCaptcha = () => {
        setSubmitShow(true)
    }
    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref
        }
    }
    const resetCaptcha = () => {
        captcha.reset()
    }

    const submitForm = async () => {
        let firstName = document.getElementById("firstName") as HTMLInputElement
        let lastName = document.getElementById("lastName") as HTMLInputElement
        let emailAddress = document.getElementById("emailAddress") as HTMLInputElement
        let password = document.getElementById("password") as HTMLInputElement
        let contactNumber = document.getElementById("contactNumber") as HTMLInputElement
        let countFleetVehicles = document.getElementById("countFleetVehicles") as HTMLInputElement
        let countDwellings = document.getElementById("countDwellings") as HTMLInputElement
        let countChargePoints = document.getElementById("countChargePoints") as HTMLInputElement
        let bodyData: { [name: string]: any } = {}
        bodyData['firstName'] = firstName!.value
        bodyData['lastName'] = lastName!.value
        bodyData['emailAddress'] = emailAddress!.value
        bodyData['password'] = password!.value
        bodyData['contactNumber'] = contactNumber!.value
        bodyData['countFleetVehicles'] = countFleetVehicles!.value
        bodyData['countDwellings'] = countDwellings!.value
        bodyData['countChargePoints'] = countChargePoints!.value
        // firstName!.value = ''
        // lastName!.value = ''
        // emailAddress!.value = ''
        // password!.value = ''
        // contactNumber!.value = ''
        setLoading(true);
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/registration/register`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };

        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            let res_json = await response.json();

            console.log(response);
            console.log(res_json);

            if (res_json.code === "REASON_USER_ALREADY_EXISTS") {
                setDataSent("It has not been possible to create your account at this time.");
                // openModal();
            } else if (res_json.code === "REASON_PASSWORD_TOO_WEAK") {
                setDataSent("Sorry password too weak.");
            } else if (res_json.code === "MANDATORY_DATA_MISSING") {
                setDataSent("Please enter all mandatory fields.");
            } else if (res_json.code === "CONTACT_NUMBER_INVALID") {
                setDataSent("Please enter a valid contact number");
            } else if (res_json.code === "EMAIL_NOT_BUSINESS_DOMAIN") {
                setDataSent("Please enter a business email address");
            }
            //   openModal();
            return;
        } else {
            firstName!.value = ''
            lastName!.value = ''
            emailAddress!.value = ''
            password!.value = ''
            contactNumber!.value = ''
            countFleetVehicles!.value = ''
            countChargePoints!.value = ''
            countDwellings!.value = ''

            setDataSent("Thank you for registering - one of the team will be in contact shortly");
            // openModal();
        }

        resetCaptcha()
        setSubmitShow(false)
        setLoading(false);

    }

    useEffect(() => {
        console.log('in modal' + dataSent);
        if (dataSent != null && dataSent != "") {
            setLoading(false);
            openModal();
        } else {
            setLoading(false);
        }
    }, [dataSent]);

    useEffect(() => {
        if (!modalIsOpen)
            setDataSent("");

    }, [modalIsOpen]);

    useEffect(() => {

        console.log("in loading " + loading);

        if (loading) {
            <LoaderModal />
        }
    }, [loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className="contact-container">

                {loading && <LoaderModal />}

                <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]} style={{ zIndex: 1 }}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose size={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>
                            <h1>Register</h1>
                            <p style={{ maxWidth: 400, textAlign: 'center' }}>
                                {dataSent}
                            </p>
                        </div>
                    </>

                </ModalComponent>
                <div className="contact-body-container">
                    <div
                        className="col-xs-12 col-md-6 col-xl-4" style={{ marginBottom: "48px" }}
                    >
                        <br />
                        <div >
                            <h1>Register for an account</h1>
                        </div>
                        <br />
                        <div>
                            <p>Please enter all fields marked with *</p>
                        </div>
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="firstName"
                                id="firstName"
                            />
                            <label>First name*</label>
                        </div>

                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="lastName"
                                id="lastName"
                            />
                            <label>Last name*</label>
                        </div>
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="emailAddress"
                                id="emailAddress"
                            />
                            <label>Email Address (used as username)*</label>
                        </div>
                        <div className="inputGroup">
                            <input
                                type="password"
                                required={true}
                                autoComplete="off"
                                name="password"
                                id="password"
                            />
                            <label>Password*</label>
                        </div>
                        <div style={{marginLeft: "1em"}}>At least 8 characters long.</div>
                        <div style={{marginLeft: "1em"}}>Contains at least one uppercase letter.</div>
                        <div style={{marginLeft: "1em"}}>Contains at least one lowercase letter.</div>
                        <div style={{marginLeft: "1em"}}>Contains at least one number.</div>
                        <div style={{marginLeft: "1em"}}>Contains at least one special character.</div>
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="contactNumber"
                                id="contactNumber"
                            />
                            <label>Contact number*</label>
                        </div>
                        <br />
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="countFleetVehicles"
                                id="countFleetVehicles"
                            />
                            <label>Vehicles in your Fleet</label>
                        </div>
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="countDwellings"
                                id="countDwellings"
                            />
                            <label>Number of locations</label>
                        </div>
                        <div className="inputGroup">
                            <input
                                type="text"
                                required={true}
                                autoComplete="off"
                                name="countChargePoints"
                                id="countChargePoints"
                            />
                            <label>Number of charge points</label>
                        </div>

                        <br />

                        <ReCAPTCHA style={{ marginLeft: "1em" }}
                            ref={(r) => setCaptchaRef(r)}
                            sitekey={(process.env.REACT_APP_RECAPTCHA_KEY as string)!.toString() || ""}
                            onChange={checkCaptcha}
                        />
                        <br />

                        <button style={{ marginLeft: "1em" }}
                            className="primary-btn"
                            disabled={!submitShow}
                            onClick={() => submitForm()}
                        >
                            Submit
                        </button>
                    </div>

                </div>
            </div >

        </div >
    );
}

export default RegisterPage;