import React from "react";
import { useNavigate } from "react-router";
import {
  PieChart,
  Pie,
  LabelList,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import "./PieGraph.scss";

const COLORS = ["#BEF213", "#A6CBE3", "#B2BEB5", "#A6CBE3"];

function PieGraph(props: any) {
  const navigate = useNavigate();

  const getIntroOfPage = (label: any) => {
    if (label === "Single-Charge") {
      return "Single charge tells the amount of journey that required only one single charge";
    }
    if (label === "Muliple-charge") {
      return "Multiple charge tells the amount of journey that required only more than one charge";
    }
    if (label === "Non-Charge") {
      return "Non charge tells the the amount of journey that required no charge";
    }
    return "";
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            fontSize: 5,
            color: "black",
            backgroundColor: "	rgba(255,255,255,0.8)",
            borderRadius: 10,
            display: "flex-box",
            padding: 20,
          }}
        >
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className="intro">{getIntroOfPage(payload[0].name)}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div
      className="pie-chart col-auto"
      onClick={() =>
        navigate(
          "/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight",
          {
            state: {
              id: props.data.id,
              categoryType: props.categoryType,
              description: props.data.description,
              fleetScenarioNumber: props.fleetScenarioNumber,
              fleetScenarioUuid: props.fleetScenarioUuid,
              registrationNumber: props.registrationNumber,
            },
          }
        )
      }
    >
      <div className="left-title">
        <b style={{ fontSize: 15 }}>{props.data.percentage} </b> of journeys{" "}
        <b style={{ fontSize: 15 }}>did not require </b> an en-route charge
      </div>
      <h2 className="pie-title">{props.data.description}</h2>

      <PieChart
        width={props.width}
        height={props.height}
        style={{ cursor: "pointer" }}
      >
        <text
          x={props.width / 2}
          y={props.width / 2 + 8}
          fill={"grey"}
          fontSize={12}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          Journeys
        </text>
        <Pie
          data={props.data.data}
          textAnchor="middle"
          dominantBaseline="middle"
          innerRadius={60}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          <Label
            style={{ fontSize: 35, fontWeight: "bold" }}
            value={props.data.nob}
            position="center"
          />

          <LabelList dataKey="value" position="insideTop" fill="white" />
          {props.data.data.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          align={"center"}
          iconType={"circle"}
          layout={"horizontal"}
          height={40}
          iconSize={9}
        />
      </PieChart>
    </div>
  );
}
export default PieGraph;
