import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, CircularProgress } from '@mui/material';
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router-dom";
import PowerPurchaseAgreementQuotesTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementQuotesTable";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
interface Vehicle {
    vrn: string;
    make: string;
    model: string;
    journeys: number;
    totalDistance: string;
    avgCharge: string;
    avgDwellTime: string;
}

function PowerPurchaseAgreementAdvancedCreatePage(props: any) {
    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useContext(UserContext);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [quotes, setQuotes] = useState<any[]>([]);
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);
    const [connecting, setConnecting] = useState<boolean>(false);
    const [quoting, setQuoting] = useState<boolean>(false);

    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [showVehiclesTable, setShowVehiclesTable] = useState<boolean>(false); // New state to control the table visibility
    const [selectedVehicles, setSelectedVehicles] = useState<Set<string>>(new Set()); // Set to track selected rows
    const [selectedProvider, setSelectedProvider] = useState<number | null>(null); // State to track selected provider
    const [showQuotesTable, setShowQuotesTable] = useState<boolean>(false); // State to control new table visibility

    const [step, setStep] = useState<number>(0); // State to track the current step
    const [quoteStep, setQuoteStep] = useState<number>(0); // State to track the current step

    const steps = ["Authenticating...", "Downloading...", "Analysing..."];
    const stepDurations = [1500, 500, 2000]; // Array to set the duration (in ms) for each step

    const quoteSteps = ["Processing...", "Optimising...", ""];
    const quoteStepDurations = [1000, 1000, 1000 ]; // Array to set the duration (in ms) for each step

    const data1 = {
        datasets: [{
            data: [48, 52],
            backgroundColor: ['#FF6384', '#CCCCCC'],
        }],
    };

    const data2 = {
        datasets: [{
            data: [12, 88],
            backgroundColor: ['#36A2EB', '#CCCCCC'],
        }],
    };

    const handleClickOpen = () => {
        setShowVehiclesTable(false); // Hide the vehicles table when "Telematics Connect" is pressed
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProviderSelect = (index: number) => {
        setSelectedProvider(index);
    };

    const handleContinue = () => {
        setOpen(false);
        setConnecting(true);
        setStep(0);

        let totalDuration = 0;

        steps.forEach((_, index) => {
            totalDuration += stepDurations[index];
            setTimeout(() => {
                setStep(index);
            }, totalDuration);
        });

        setTimeout(() => {
            setConnecting(false);
            fetchVehicleData();
            setShowVehiclesTable(true); // Show the vehicles table after all steps are completed
        }, totalDuration);
    };

    const handleVehicleSelect = (vrn: string) => {
        setSelectedVehicles((prevSelectedVehicles) => {
            const newSelectedVehicles = new Set(prevSelectedVehicles);
            if (newSelectedVehicles.has(vrn)) {
                newSelectedVehicles.delete(vrn);
            } else {
                newSelectedVehicles.add(vrn);
            }
            return newSelectedVehicles;
        });
    };

    const handleFetchQuotes = () => {
        setOpen(false);
        setQuoting(true);
        setQuoteStep(0);

        let totalDuration = 0;

        quoteSteps.forEach((_, index) => {
            totalDuration += quoteStepDurations[index];
            setTimeout(() => {
                setQuoteStep(index);
            }, totalDuration);
        });

        setTimeout(() => {
            setQuoting(false);
            fetchVehicleData();
            setShowQuotesTable(true); // Show the new table
        }, totalDuration);
    };

    const fetchVehicleData = () => {
        const vehicleData: Vehicle[] = [
            { vrn: 'HN73JUH', make: 'Maxus', model: 'e Deliver 9', journeys: 212, totalDistance: '9,122 km', avgCharge: '71 kW/h', avgDwellTime: '1h 30m' },
            { vrn: 'HN24KOL', make: 'Citroen', model: 'e-Dispatch', journeys: 409, totalDistance: '22,542 km', avgCharge: '42 kW/h', avgDwellTime: '55m' },
            { vrn: 'GU71PPW', make: 'Citroen', model: 'e-Dispatch', journeys: 344, totalDistance: '18,201 km', avgCharge: '54 kW/h', avgDwellTime: '42m' },
            { vrn: 'FR22UYW', make: 'Renault', model: 'Master E-Tech', journeys: 839, totalDistance: '37,212 km', avgCharge: '49 kW/h', avgDwellTime: '48m' }
        ];
        setVehicles(vehicleData);
    };

    const providers = [
        { name: 'Verizon Connect', logo: 'https://www.verizonconnect.com/images/logo-vzc-5c1dedfa.svg' },
        { name: 'Quartix', logo: 'https://www.quartix.com/content/uploads/2021/01/quartix-logo-eur.svg' },
        { name: 'Teletrac Navman', logo: 'https://www.teletracnavman.co.uk/media/16878/tn-logo-lockup-rgb.svg' },
        { name: 'Movolytics', logo: 'https://movolytics.com/wp-content/uploads/2022/09/movolytics_logo.svg' },
        { name: 'Masternaut', logo: 'https://connectedfleet.michelin.com/hs-fs/hubfs/MCF_2024/MICHELIN%20Connected%20Fleet_logo.png?width=578&height=122&name=MICHELIN%20Connected%20Fleet_logo.png' },
    ];

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                <div>
                    <div className="container-fluid g-0">
                        <div className="row gy-2">

                            <div className="col-xs-12 col-md-6 col-xl-6">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Telematics
                                    </div>

                                    <div className="dashboard-card-content" style={{ gap: 20 }}>
                                        <div className="box-info">
                                            <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
                                                <button className="primary-btn" disabled={isDisabled} >
                                                    Upload Files
                                                </button>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                                <button className="primary-btn" disabled={isDisabled} onClick={handleClickOpen}>
                                                    Telematics Connect
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    <br />


                    {/* Vehicle Data Table */}
                    {showVehiclesTable && vehicles.length > 0 && (
                        <div className="container-fluid g-0" style={{ marginTop: '20px' }}>
                            <div className="row gy-2">
                                <div className="col-xs-12">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">directions_car</span>
                                            Connected Vehicles
                                            {selectedProvider !== null && (
                                                <img
                                                    src={providers[selectedProvider].logo}
                                                    alt={providers[selectedProvider].name}
                                                    style={{ height: '24px', marginLeft: '20px' }}
                                                />
                                            )}
                                        </div>
                                        <div className="dashboard-card-content">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th></th> {/* Empty header for the checkbox column */}
                                                        <th>VRN</th>
                                                        <th>Make</th>
                                                        <th>Model</th>
                                                        <th>Number of Journeys</th>
                                                        <th>Total Distance</th>
                                                        <th>Average Charge</th>
                                                        <th>Average Dwell Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vehicles.map((vehicle) => (
                                                        <tr
                                                            key={vehicle.vrn}
                                                            style={{
                                                                backgroundColor: selectedVehicles.has(vehicle.vrn) ? '#add8e6' : 'transparent',
                                                            }}
                                                        >
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedVehicles.has(vehicle.vrn)}
                                                                    onChange={() => handleVehicleSelect(vehicle.vrn)}
                                                                />
                                                            </td>
                                                            <td>{vehicle.vrn}</td>
                                                            <td>{vehicle.make}</td>
                                                            <td>{vehicle.model}</td>
                                                            <td>{vehicle.journeys}</td>
                                                            <td>{vehicle.totalDistance}</td>
                                                            <td>{vehicle.avgCharge}</td>
                                                            <td>{vehicle.avgDwellTime}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-2 col-md-12 col-xl-12">
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                        <button
                                            className="primary-btn"
                                            disabled={isDisabled || selectedVehicles.size === 0} // Disable if no vehicles are selected
                                            onClick={handleFetchQuotes}
                                        >
                                            Fetch Quotes
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <br />

                        </div>

                    )}

                    {showQuotesTable && (
                        <div className="container-fluid g-0" style={{ marginTop: '20px' }}>
                            <div className="row gy-2">
                                <div className="col-xl-6">
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                        <img src="https://ospreyaad.cdn.prismic.io/ospreyaad/dfe5be46-4bb0-4e02-9a62-6d6bdc806f29_osprey-logo.svg" alt="CPO 2" style={{ height: '48px' }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                        <p>48% of journeys would have been within 0.1 miles of a charger</p>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                        <img src="https://www.zap-map.com/sites/default/files/media/image/2023-12/IONITY%20%C3%94%C3%87%C3%B4%20Master%20Logo_Networkguide_RGB.jpg" alt="CPO 2" style={{ height: '48px' }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                        <p>12% of journeys would have been within 0.3 miles of a charger</p>

                                    </div>

                                </div>

                                <div className="col-xl-6">
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                        <div style={{ width: '200px', height: '200px', position: 'relative' }}>
                                            <Doughnut
                                                data={data1}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    responsive: true
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                        <div style={{ width: '200px', height: '200px', position: 'relative' }}>
                                            <Doughnut
                                                data={data2}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    responsive: true
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )}


                    {/* Dialog Component for Telematics Connect */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Select Telematics Provider</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={0}>
                                {providers.map((provider, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        key={index}
                                        onClick={() => handleProviderSelect(index)}
                                        style={{
                                            textAlign: 'center',
                                            padding: '24px',
                                            border: selectedProvider === index ? '2px solid blue' : '2px solid transparent',
                                            borderRadius: '12px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img src={provider.logo} alt={provider.name} style={{ width: '100px', height: 'auto' }} />
                                    </Grid>
                                ))}
                            </Grid>
                            <TextField
                                margin="dense"
                                label="Username"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="outlined"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleContinue} color="primary">
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Interstitial Popup for Connecting */}
                    <Dialog open={connecting} fullWidth maxWidth="xs">
                        <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
                            <CircularProgress />
                            <div>{steps[step]}</div>
                        </DialogContent>
                    </Dialog>
                    {/* Interstitial Popup for Quoting */}
                    <Dialog open={quoting} fullWidth maxWidth="xs">
                        <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
                            <CircularProgress />
                            <div>{quoteSteps[quoteStep]}</div>
                        </DialogContent>
                    </Dialog>
                </div >
            )
            }
        </>
    );
}

export default PowerPurchaseAgreementAdvancedCreatePage;
