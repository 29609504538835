export type WeekDay = {
    dayIndex: number;
    day: string;
    toHour: string;
    fromHour: string;
};

export const weekDays = [
    { dayIndex: 0, day: "Monday", toHour: "00", fromHour: "00" },
    { dayIndex: 1, day: "Tuesday", toHour: "00", fromHour: "00" },
    { dayIndex: 2, day: "Wednesday", toHour: "00", fromHour: "00" },
    { dayIndex: 3, day: "Thursday", toHour: "00", fromHour: "00" },
    { dayIndex: 4, day: "Friday", toHour: "00", fromHour: "00" },
    { dayIndex: 5, day: "Saturday", toHour: "00", fromHour: "00" },
    { dayIndex: 6, day: "Sunday", toHour: "00", fromHour: "00" },
];
