import React from "react";
import { useNavigate } from "react-router";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import "./BarGraph.scss";

const CustomizedLabel = ({ x, y, fill, value }: any) => {
  return (
    <text
      x={x + 40}
      y={y + 30}
      dy={-4}
      fontSize="20"
      fontWeight={"bold"}
      fill={"white"}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};
const COLORS = ["#BEF213", "#B2BEB5", "#C1D1D6", "#A6CBE3"];

function BarGraph(props: any) {
  const data: any = props.data.data;
  const navigate = useNavigate();
  return (
    <div
      className="bar-chart col-auto"
      onClick={() =>
        navigate(
          "/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight",
          {
            state: {
              id: props.data.id,
              categoryType: props.categoryType,
              description: props.data.description,
              fleetScenarioNumber: props.fleetScenarioNumber,
              fleetScenarioUuid: props.fleetScenarioUuid,
              registrationNumber: props.registrationNumber,
            },
          }
        )
      }
    >
      <div className="top-title">{props.data.description}</div>
      <div className="side-text">Price of energy comsumption</div>
      <BarChart
        width={400}
        height={400}
        data={data}
        barGap={40}
        barSize={40}
        margin={{ top: 20, right: 0, left: 0, bottom: 25 }}
      >
        <XAxis dataKey="name" dy="25" />
        <YAxis hide />
        <Bar
          dataKey="price"
          barSize={80}
          fontFamily="sans-serif"
          label={<CustomizedLabel />}
        >
          {data.map((entry: any, index: any) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
      <div className="below-title">Energy Price Comparison (£)</div>
    </div>
  );
}

export default BarGraph;
