import './Footer.scss';

function Footer(props: any) {
    return (
        <footer className="footer">
            <span className="copyright">Copyright {(new Date().getFullYear())} Evata Ltd</span>
            <div className="logo">Evata</div>
        </footer>
    );
}

export default Footer;