import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router";
import { currencyFormat } from "utils";


const CustomizedLabel = ({ x, y, fill, value }: any) => {
  return (
    <text
      x={x + 60}
      y={y + 30}
      dy={-4}
      fontSize="20"
      fontWeight={"bold"}
      fill={"white"}
      textAnchor="middle"
    >
      {currencyFormat(value)}
    </text>
  );
};
const COLORS = ["#BEF213", "#4B6105"];

function ChargingSummaryBarChart(props: any) {
  const data: any = props.data;
  const dashboardInfo: any = props.dashboardInfo;
  const navigate = useNavigate();
  return (
    <div className="col-auto">
      <div className="side-text">Cost</div>
      <BarChart
        width={400}
        height={400}
        data={data}
        barGap={40}
        barSize={40}
        margin={{ top: 20, right: 0, left: 10, bottom: 25 }}
      >
        <XAxis dataKey="name" dy="25" />
        <YAxis hide />
        <Bar
          dataKey="price"
          barSize={120}
          fontFamily="sans-serif"
          
          label={<CustomizedLabel />}
        >
          {data.map((entry: any, index: any) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
      <div className="below-title">You've saved approx. £{Math.round(dashboardInfo.currentMonth?.costSaving)} this month</div>
      {/* <button style={{ marginTop: 20 }} className="primary-btn" onClick={() => (navigate("/plan/insight/fleet/scenario/categories", {
                state: {
                    scenarioUuid: props.fleetScenarioUuid
                }
            }))}>View</button> */}
    </div>
  );
}

export default ChargingSummaryBarChart;
