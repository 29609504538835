import React, { useContext } from "react";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import TableSkelton from "../../common/SkeltonComponent/TableSkelton";
import UserContext from "Context/UserProvider";
import { CgOpenCollective } from "react-icons/cg";
import { useNavigate } from "react-router";

function TableVehicleInsight(props: any) {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    // {
    //     field: 'registrationNumber',
    //     headerName: 'Electric Vehicle Model',
    //     sortable: false,
    //     type: 'number',
    //     width: 350,
    //     align: 'left',
    //     headerAlign: 'left'

    // },
    {
      field: "description",
      headerName: "Electric Vehicle Model",
      type: "number",
      width: 350,
      align: "left",
      headerAlign: "left",
      renderCell: (cellValue: any) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight", {
                state: {
                  id: cellValue.id,
                  categoryType: props.categoryType,
                  fleetScenarioNumber: props.fleetScenarioNumber,
                  fleetScenarioUuid: props.fleetScenarioUuid,
                  description: cellValue.row.description,
                  registrationNumber: props.registrationNumber,
                },
              })
            }>
            {cellValue.row.description}
          </span>
        );
      },
    },
    {
      field: "distanceDrivenInKms",
      headerName: "Total Distance",
      sortable: false,
      type: "number",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numberOfJourneys",
      headerName: "Journeys",
      sortable: false,
      type: "number",
      width: 104,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countEnRouteCharges",
      headerName: "En Route Charges",
      sortable: false,
      type: "number",
      width: 168,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "extraTimeOnRoute",
      headerName: "En-route Extra Time",
      sortable: false,
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dcKWh",
      headerName: "DC",
      sortable: false,
      type: "number",
      width: 104,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "acSinglePhaseKWh",
      headerName: "AC(Single Phase)",
      sortable: false,
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ac3PhaseKWh",
      headerName: "AC(3 Phase)",
      sortable: false,
      type: "number",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "operationalCostOfEnRouteCharging",
      headerName: "Operational Enroute Charging Cost",
      sortable: false,
      type: "number",
      width: 152,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "energyCost",
      headerName: "Total Energy Cost",
      sortable: false,
      type: "number",
      width: 152,
      align: "center",
      headerAlign: "center",
    },
  ];
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: 30,
        borderRadius: "10px",
        boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)",
        alignItems: "center",
        justifyContent: "center",
      }}>
      {props.loading ? (
        <TableSkelton />
      ) : (
        <DataGrid
          rows={props.rows}
          autoHeight
          {...props.rows}
          columns={columns}
          pageSize={9}
          disableColumnMenu
          rowsPerPageOptions={[9]}
          disableSelectionOnClick
        />
      )}

      {/* <Button onClick={()=>compareVehicle(seletedRow)}>Compare {seletedRow.length} file</Button> */}
    </div>
  );
}

export default TableVehicleInsight;
