type DashboardCardItemProps = {
    itemTitle: string;
    itemValue: string;
};

function DashboardCardItem({ itemTitle, itemValue }: DashboardCardItemProps) {

    return (
        <div className="box-info">
            <span className="item">{itemTitle}</span>
            <span className="data">{itemValue}</span>
        </div>
    )
}
export default DashboardCardItem;