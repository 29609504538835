import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { currencyFormat } from "utils";
import UserContext from "../../Context/UserProvider";
import Map from "../../components/common/MapComponent/Map";
import BarChartSkelton from "../../components/common/SkeltonComponent/BarChartSkelton";
import PieChartSkelton from "../../components/common/SkeltonComponent/PieChartSkelton";
import HorizontalStackedChartCharge from "../../components/fleet/JourneyInsightComponent/HorizontalStackedChartCharge";
import HorizontalStackedChartPower from "../../components/fleet/JourneyInsightComponent/HorizontalStackedChartPower";
import BarGraph from "../../components/fleet/VehicleInsightComponent/Chart/BarGraph";
import PieGraph from "../../components/fleet/VehicleInsightComponent/Chart/PieGraph";
import TableVehicleInsight from "../../components/fleet/VehicleInsightComponent/TableVehicleInsight";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import "./InsightPage.scss";


function isNull(array: any) {
  if (array === null || array === undefined) {
    return [];
  }
  return array;
}
function convertMinutesToHoursAndMinutes(minutes: any) {
  var hours = Math.floor(minutes / 60);

  var leftoverMinutes = minutes % 60;

  return hours + " hours and " + leftoverMinutes + " mins";
}

export default function InsightPage(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [showCategory, setShowCategory] = useState([false, true, false, false, false]) as any;
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("Yearly Summary");
  const params: any = location.state;
  const returnMenu = ["Scenarios ", `Scenario ${params.fleetScenarioNumber} `, `Category ${params.categoryType + 1} `, `${params.iceVehicle.registrationNumber} `];

  const iceVehicle = params.iceVehicle;
  const [insightData, setInsightData] = useState({
    rechargeData: [],
    partnerLocations: [],
    vehicleMapData: [],
    feulData: [],
    insightTableData: [],
    pieChartText: [] as any,
    mapText: [] as any,
  });
  const [loading, setLoading] = useState(true);
  const [enableButton, setEnableButton] = useState(false);

  function clasifyInsight(obj: any) {
    // let recommneded_vehicle_data = obj.recommendationSummary.recommended
    let recharge_data = obj.comparisons.map(({ countEnRouteChargesSingle: single, countEnRouteChargesMulti: multi, journeysNotRequiringEnRouteChargePercent: percentage, countEnRouteCharges: total, uuid: id, numberOfJourneys: nob, recommendedEV: evRecommend }: any) => {
      return {
        nob,
        total,
        id,
        description: evRecommend,
        percentageInNumber: percentage,
        percentage: Math.round(percentage * 100).toString() + " %",
        data: [
          { name: "Zero Charges", value: nob - (single + multi) },
          { name: "Single Charges", value: single === 0 ? "" : single },
          { name: "Multiple Charges", value: multi === 0 ? "" : multi },
        ],
      };
    });
    recharge_data = recharge_data.sort((a: any, b: any) => b.percentageInNumber - a.percentageInNumber);
    let company_map_data = isNull(obj.companyDwellings);
    let vehicle_map_data = isNull(obj.vehicleDwellings);
    let fuel_consumption_data = obj.comparisons.map(({ distanceDriven, energyCost, recommendedEV: evRecommend, uuid: id }: any) => {
      return {
        description: evRecommend,
        id,
        distance_travel: distanceDriven,
        price_save: parseInt(obj.iceVehicleCalculations.totalFuelCostForTerm),
        data: [
          {
            price: parseInt(energyCost),
            name: "Electricity",
          },
          {
            price: parseInt(obj.iceVehicleCalculations.totalFuelCostForTerm),
            name: "Fuel",
          },
        ],
      };
    });
    fuel_consumption_data = fuel_consumption_data.sort((a: any, b: any) => b.price_save - a.price_save);

    let insight_vehicles_data = obj.comparisons.map(({ energyCost, distanceDriven, numberOfJourneys, totalEnRouteChargingTime, countEnRouteCharges, dcKWh, acSinglePhaseKWh, ac3PhaseKWh, operationalCostOfEnRouteCharging, uuid: id, recommendedEV: evRecommend }: any) => {
      return {
        // registrationNumber: evRecommend,
        description: evRecommend,
        id,
        energyCost: currencyFormat(energyCost),
        distanceDrivenInKms: parseInt(distanceDriven).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName,
        numberOfJourneys: numberOfJourneys,
        extraTimeOnRoute: convertMinutesToHoursAndMinutes(totalEnRouteChargingTime.toFixed(0)),
        countEnRouteCharges: countEnRouteCharges,
        dcKWh: parseInt(dcKWh == null ? 0 : dcKWh).toLocaleString() + " kWh",
        acSinglePhaseKWh: parseInt(acSinglePhaseKWh == null ? 0 : acSinglePhaseKWh).toLocaleString() + " kWh",
        ac3PhaseKWh: parseInt(ac3PhaseKWh == null ? 0 : ac3PhaseKWh).toLocaleString() + " kWh",
        operationalCostOfEnRouteCharging: currencyFormat(operationalCostOfEnRouteCharging),
      };
    });
    setInsightData({
      rechargeData: recharge_data,
      partnerLocations: company_map_data,
      vehicleMapData: vehicle_map_data,
      feulData: fuel_consumption_data,
      insightTableData: insight_vehicles_data,
      pieChartText: [],
      mapText: [],
    });
  }

  async function fetchInsight() {
    let bodyData: { [name: string]: string } = {};
    //Variable for fetch request
    let userID = userInfo.uuid;
    let access_token = userInfo.access_token;
    let fleetSnapshotIceVehicleUuid = params.iceVehicle.fleetSnapshotIceVehicleUuid;
    let fleetUuid = userInfo.fleetUuid;
    bodyData["uuid"] = userID;
    bodyData["access_token"] = access_token;
    bodyData["fleetUuid"] = fleetUuid;
    bodyData["fleetSnapshotIceVehicleUuid"] = fleetSnapshotIceVehicleUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/iceVehicle/insight`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    clasifyInsight(res_json);
    setInsightData((prev) => ({
      ...prev,
      pieChartText: res_json.pieChartCopyText,
    }));
    setInsightData((prev) => ({
      ...prev,
      mapText: res_json.mapCopyText ? res_json.mapCopyText : [],
    }));
    setLoading(false);
    return res_json;
  }

  useEffect(() => {
    let cancel = false;
    fetchInsight().then(() => {
      if (cancel) return;
    });

    return () => {
      cancel = true;
    };
  }, []);

  const header = (
    <h1>
      <b>Vehicle Insight for </b> <b style={{ color: "#049cf2" }}>{iceVehicle.registrationNumber}</b>
    </h1>
  );
  const paragraph =
    userInfo.accountType === "FREE" ? (
      <p>
        <br />
        This page provides insight into how each EV compared having re-driven each journey based on the uploaded Telematics Data for {iceVehicle.registrationNumber}.
        <br />
        <br />
        As a FREE TIER user, the comparsions are a selection of 3 EVs from our set. If you wish to upgrade to a Early Adopter and see the comparsions against our full EV dataset, then please <a href="/contact">contact us</a> .
      </p>
    ) : (
      <p>
        <br />
        This page provides insight into how each EV compared having re-driven each journey based on the uploaded Telematics Data for {iceVehicle.registrationNumber}.
        <br />
        <br />
        As an EARLY ADOPTER, the comparisons shown are for our whole set of EVs. If there is further insight that you think we should be displaying on this page, we would love to hear your suggestion. Please use the <a href="/contact">contact us</a> page to get in touch.
      </p>
    );
  const content = (
    <div className="d-flex row gap-5 mt-4">
      <div className="col-md col-sm-12">
        <h1>EV simulation</h1>
        <br />
        <p>Based on the Telematics data that was uploaded for {iceVehicle.registrationNumber}, each journey has been 're-driven' by each of the Electric Vehicles in our comparison set. During each journey, we simulated different types of charging including inter-journey charging where charging facilities are available in between journeys and en-route charging where available battery capacity has been too low to complete the journey. From our database of over 18,000 charging stations, we diverted to the nearest fastest charging location, charged the vehicle and then continued to the destination.</p>
      </div>
      <div className="col-md col-sm-12">
        <h1>Location</h1>
        <br />
        <p>Comparing journey destination locations across all vehicles in your fleet, we compiled a list of the most commonly visited ones. For individual vehicles, the number on the map represents the number of visits per year, with the office icon representing those locations that have been visited by more than one vehicle.</p>
      </div>
      <div className="col-md col-sm-12">
        <h1>Pricing</h1>
        <br />
        <p>We have used industry data for mpg, fuel price and charging price figures to produce a comparison of Fuel versus Energy costs a summary over a year.</p>
      </div>
    </div>
  );
  return (
    <>
      {isError ? (
        <ErrorPage statusCode={"500"} />
      ) : (
        <div className="d-flex flex-column gap-4 ">
          <div>
            {returnMenu.map((e: any, index: any) => {
              //Check for last bread menu item
              if (index === returnMenu.length - 1) {
                return <span>{e}</span>;
              }
              //Check for scenario menu item to return because we need to return to exact category
              else if (index === 2) {
                return (
                  <span
                    onClick={() => {
                      navigate(-(returnMenu.length - 1 - index));
                      navigate("/plan/insight/fleet/scenario/categories", {
                        state: {
                          fleetScenarioUuid: params.fleetScenarioUuid,
                          fleetScenarioNumber: params.fleetScenarioNumber,
                          activeCategory: `Category ${params.categoryType + 1}`,
                          showCategory: [false, false, false, false].map((e: any, index: any) => {
                            if (index === params.categoryType) {
                              return true;
                            }
                          }),
                        },
                      });
                    }}>
                    <span className="hyper-link">{e}</span>
                    {"  >  "}
                  </span>
                );
              }
              return (
                <span onClick={() => navigate(-(returnMenu.length - 1 - index))}>
                  <span className="hyper-link">{e}</span>
                  {"  >  "}
                </span>
              );
            })}
          </div>
          {/* <BackButton /> */}
          {/* <Welcome paragraph={paragraph} header={header} content={content} /> */}
          <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Yearly Summary" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, true, false, false, false]), setActiveMenu("Yearly Summary"))}>
              Yearly Summary
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Charging Insight" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, false, true, false, false]), setActiveMenu("Charging Insight"))}>
              Charging Insight
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Location Insight" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, false, false, true, false]), setActiveMenu("Location Insight"))}>
              Location Insight
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Energy Insight" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, false, false, false, true]), setActiveMenu("Energy Insight"))}>
              Energy Insight
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "All Insight" ? "active" : ""}`}
              onClick={() => (setShowCategory([true, true, true, true, true]), setActiveMenu("All Insight"))}>
              All Insight
            </button>
            <div className="vl"></div>
          </div>
          {showCategory[1] && (
            <>
              <h1>Yearly Summary</h1>
              <TableVehicleInsight
                categoryType={params.categoryType}
                registrationNumber={params.iceVehicle.registrationNumber}
                fleetScenarioNumber={params.fleetScenarioNumber}
                fleetScenarioUuid={params.fleetScenarioUuid}
                rows={insightData.insightTableData}
                loading={loading}
              />
            </>
          )}
          {showCategory[2] && (
            <>
              <h1>Charging Profile</h1>
              <div className="row chart-container">
                {loading ? (
                  <>
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                    <PieChartSkelton
                      width={400}
                      height={400}
                    />
                  </>
                ) : userInfo.accountType === "FREE" ? (
                  insightData.rechargeData.map((e: any) => {
                    return (
                      <PieGraph
                        categoryType={params.categoryType}
                        fleetScenarioNumber={params.fleetScenarioNumber}
                        fleetScenarioUuid={params.fleetScenarioUuid}
                        registrationNumber={params.iceVehicle.registrationNumber}
                        key={e.description}
                        data={e}
                        width={400}
                        height={400}
                      />
                    );
                  })
                ) : (
                  <HorizontalStackedChartCharge
                    categoryType={params.categoryType}
                    fleetScenarioNumber={params.fleetScenarioNumber}
                    fleetScenarioUuid={params.fleetScenarioUuid}
                    registrationNumber={params.iceVehicle.registrationNumber}
                    data={insightData.rechargeData}
                  />
                )}
              </div>
            </>
          )}
          {showCategory[3] && (
            <>
              <h1>Frequently Visited Locations</h1>
              {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[0]?.text}</p>} */}
              <Map
                selectedLocationDwelling={[]}
                enableButton={[enableButton, setEnableButton]}
                partnerLocations={insightData.partnerLocations}
                vehicleMapData={insightData.vehicleMapData}
                height={800}
              />
              {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[1]?.text}</p>} */}
            </>
          )}
          {showCategory[4] && (
            <>
              <h1>Energy Cost Saving per Year</h1>
              <div className="row chart-container">
                {loading ? (
                  <>
                    <BarChartSkelton />
                    <BarChartSkelton />
                    <BarChartSkelton />
                    <BarChartSkelton />
                    <BarChartSkelton />
                    <BarChartSkelton />
                  </>
                ) : userInfo.accountType === "FREE" ? (
                  insightData.feulData.map((e: any) => {
                    return (
                      <BarGraph
                        categoryType={params.categoryType}
                        fleetScenarioNumber={params.fleetScenarioNumber}
                        fleetScenarioUuid={params.fleetScenarioUuid}
                        registrationNumber={params.iceVehicle.registrationNumber}
                        key={e.description}
                        data={e}
                      />
                    );
                  })
                ) : (
                  <HorizontalStackedChartPower
                    categoryType={params.categoryType}
                    fleetScenarioNumber={params.fleetScenarioNumber}
                    fleetScenarioUuid={params.fleetScenarioUuid}
                    registrationNumber={params.iceVehicle.registrationNumber}
                    data={insightData.feulData}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
