import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { IoMdClose } from 'react-icons/io';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import ModalComponent from '../../components/common/Modal/Modal';
import './ContactPage.scss';

function ContactPage(props: any) {

    const [submitShow, setSubmitShow] = useState(false)
    const [dataSent, setDataSent] = useState('')
    const [showDataSent, setShowDataSent] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    let captcha: any
    const checkCaptcha = () => {
        setSubmitShow(true)
    }
    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref
        }
    }
    const resetCaptcha = () => {
        captcha.reset()
    }

    const submitForm = async () => {
        let name = document.getElementById("name") as HTMLInputElement
        let email = document.getElementById("email") as HTMLInputElement
        let topic = document.getElementById("topic") as HTMLInputElement
        let question = document.getElementById("question") as HTMLInputElement
        let bodyData: { [name: string]: any } = {}
        bodyData['name'] = name!.value
        bodyData['email'] = email!.value
        bodyData['message'] = question!.value
        bodyData['topic'] = topic!.value
        name!.value = ''
        email!.value = ''
        topic!.value = ''
        question!.value = ''
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/sendgrid/contact_req`
        fetch(fetch_link, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(bodyData)
        })
            .then(async (res) => {
                let json_res: any = await res.json()
                if (json_res.message = "true") {
                    setDataSent("Thanks for your message")
                    openModal();
                } else {
                    setDataSent("Message Failed to send")
                }


                setShowDataSent(true)
                setTimeout(() => {
                    setShowDataSent(false)
                }, 5000)
            })

        resetCaptcha()
        setSubmitShow(false)
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className="contact-container">

                {/* <div className="contact-header-main">
                    <div className="contact-header">
                        <span className='contact-header-title'>GET IN TOUCH</span>
                        <div className="contact-header-container">
                            <div className="contact-header-content">
                                <div className="image-container"><img src={locationIcon} className="img" alt="image" /></div>
                                <h2>Address</h2>
                                <div className="section">
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>10 Allison Street </p>
                                    <p>Glasgow</p>
                                    <p>G42 8NN</p>
                                </div>

                            </div>
                            <div className="contact-header-content">
                                <div className="image-container"><img src={phoneIcon} className="img" alt="image" /></div>
                                <h2>Phone</h2>
                                <div className='section'>
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>+44 78 ********* </p>
                                    <p>Director</p>
                                </div>
                                <div className='section'>
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>+44 78 ********* </p>
                                    <p>Director</p>
                                </div>
                            </div>
                            <div className="contact-header-content">
                                <div className="image-container"><img src={emailIcon} className="img" alt="image" /></div>
                                <h2>Email</h2>
                                <div className='section'>
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>*****@evata.co.uk </p>
                                    <p>Director</p>
                                </div>
                                <div className='section'>
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>*****@evata.co.uk </p>
                                    <p>Director</p>
                                </div>
                                <div className='section'>
                                    <p><b style={{ color: 'black' }}>C/O Global Accountancy Practice</b></p>
                                    <p>*****@evata.co.uk </p>
                                    <p>Director</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}

                <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose size={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>
                            <h1>Thank you for the question!</h1>
                            <p style={{ maxWidth: 400, textAlign: 'center' }}>
                                One of our team will be in touch shortly with your query.
                            </p>
                        </div>
                    </>

                </ModalComponent>
                <div className="contact-body-container">
                    <div className="contact-body-left">
                        <h1>Message Us</h1>
                        <p>Thanks for taking the time to contact us.  Please fill out the form with your question or comments and we'll be in touch.</p>
                    </div>
                    <div className="contact-body-right">
                        <div style={{ display: 'flex', gap: 20, height: 'fit-content', width: '100%' }}>
                            <input type="text" className="textbox" placeholder="Email" name="email" id="email" />
                            <input type="text" className="textbox" placeholder="Name" id="name" />
                        </div>
                        <input type="text" className="textbox" placeholder="Topic" id="topic" />
                        <textarea placeholder="Your Question" id="question" />
                        <div style={{ alignSelf: 'flex-start' }}>

                            <ReCAPTCHA
                                ref={(r) => setCaptchaRef(r)}
                                sitekey={(process.env.REACT_APP_RECAPTCHA_KEY as string)!.toString() || ""}
                                onChange={checkCaptcha}
                            />
                            <button
                                className="primary-btn"
                                disabled={!submitShow}
                                onClick={() => submitForm()}
                            >
                                Submit
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ContactPage;