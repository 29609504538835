
import MembershipCard from '../../components/user/MembershipComponent/MembershipCard'
import './Membership.scss'
export default function Membership() {
    return (
        <div>
            <div className='header'>
                <p>Evata</p>
            </div>
            <div className='membership-page-container'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, padding: 10 }}>
                    <h1 style={{ fontWeight: '800', fontSize: 25, maxWidth: 400, textAlign: 'center' }}>
                        Flexible plans for your community's size and needs
                    </h1>
                    <h3 style={{ fontWeight: '100', color: '#757575' }}>
                        Choose your plan and transform your fleet
                    </h3>
                </div>
                <div style={{ display: 'flex', gap: 50, padding: 20, maxWidth: 1600 }}>
                    <MembershipCard type="Basic" price="Free" productID="price_1M1U78DbPfuMKbQtAkkNYFu9" />
                    <MembershipCard type="Standard" price=" £10" productID="price_1M1UGHDbPfuMKbQtFOPYl9ZE" />
                    <MembershipCard type="Premium" price=" £20" productID="price_1M1rq6DbPfuMKbQtv57Tklb1" />
                </div>

            </div>
            <div className='footer'></div>
        </div>
    )
}
