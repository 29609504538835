import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

function SiteAccessPolicyTable(props: any) {
  const navigate = useNavigate();
  const surveys = props.surveys;

  const columns: GridColDef[] = [

    {
        field: "name",
        headerName: "Name",
        sortable: false,
        width: 280,
        align: "left",
        headerAlign: "left",
        renderCell: (cell) => {
          return (
            <span
              className="hyper-link"
              onClick={() =>
                navigate("/share/business/site-access-policies/edit", {
                  state: {
                    siteAccessPolicyUuid: cell.row.uuid,
                  },
                })
              }>
              {cell.row.name}
            </span>
          );
        }
      },


    {
        field: "scope",
        headerName: "Scope",
        sortable: false,
        width: 280,
        align: "left",
        headerAlign: "left",
      },
     ];

  return (
    <>
      <div className="table-box">
        {}

        <DataGrid
          width={"100%"}
          rows={surveys}
          autoHeight
          {...surveys}
          columns={columns}
          disableColumnMenu
          pageSize={15}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          components={{ 
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                <span>No Site Access Policies</span>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                Filter returns no results
              </Stack>
            ),
          }}
          checkboxSelection={true}
          fontFamily={"Arvo"}></DataGrid>
      </div>
    </>
  );
}

export default SiteAccessPolicyTable;
