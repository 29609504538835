import PowerPurchaseAgreementQuotesTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementQuotesTable";
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from '@mui/material'; // Import Backdrop and CircularProgress from Material UI
import QuotesTable from "components/ppa-quote/QuotesTable";

function isNull(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function BuildQuotePageResults(props: any) {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [businessLocationsData, setBusinessLocationsData] = useState([]) as any;
    const [virtualChargingHubsData, setVirtualChargingHubsData] = useState([]) as any;
    const [homeLocationsData, setHomeLocationsData] = useState([]) as any;
    const [opportunityLocationsData, setOpportunityLocationsData] = useState([]) as any;
    const [showCategory, setShowCategory] = useState([false, true, false, false]);
    const [activeMenu, setActiveMenu] = useState("Business Locations");
    const [insightData, setInsightData] = useState({
        partnerLocations: []
    });
    const [enableButton, setEnableButton] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);

    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 3; // Change this index to set the active step

    const [quotes, setQuotes] = useState([]) as any

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    async function fetchQuotes() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/ppa/advanced/build/quotes/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        console.log("QUOTE : " + JSON.stringify(res_json));

        setQuotes(res_json.quotes);

        setLoading(false);
    }

    const acceptQuote = async (chargePointOperatorUuid: string, chargingPartnerRateCardUuid: string, rowId: any) => {
        console.log("cp " + chargePointOperatorUuid + "|" + chargingPartnerRateCardUuid);
        setIsDisabled(true);

        try {
            let bodyData: { [name: string]: any } = {};
            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["chargePointOperatorUuid"] = chargePointOperatorUuid;
            bodyData["chargingPartnerRateCardUuid"] = chargingPartnerRateCardUuid;

            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/cpo/ppa/quote/accept`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            if (response.ok) {
                const updatedData = quotes.map((row: { id: any; }) =>
                    row.id === rowId ? { ...row, workflowState: res_json.workflowState } : row
                );
                setQuotes(updatedData);
                setIsDisabled(false);
                toast.success('Power Purchase Agreement setup Successful', {
                    position: 'bottom-center',
                    duration: 5000,
                });
                navigate("/agree/power-purchase-agreement");

            } else {
                setIsDisabled(false);
                throw new Error(res_json.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        fetchQuotes();
    }, []);

    return (
        <div>
            {/* Breadcrumbs are always displayed */}
            <Breadcrumbs currentStep={"Quotes"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (
                <div className="container-fluid g-0" style={{marginTop: "24px"}}>
                    <div className="row gy-2">
                        <QuotesTable businessChargingCategory={userInfo.businessCategory} quotes={quotes} acceptQuote={acceptQuote} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuildQuotePageResults;
