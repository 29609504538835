import LocationTable from "components/business/LocationComponents/Components/LocationTable";
import UserListTable from "components/user/UserListPageComponent/UserListTable";
import ScenarioConfigContext from "Context/ScenarioConfigContext";
import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";

function checking_null_undefined(array: any) {
  if (array === null || array === undefined) {
    return [];
  }
  return array;
}
function UserListPage(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [users, setUsers] = useState([]) as any;
  const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false, false]);
  const [activeMenu, setActiveMenu] = useState("All");

  //   const [scenarioConfigInfo, setScenarioConfigInfo] = useContext(ScenarioConfigContext) as any;
  //   const fleetScenarioUuid = scenarioConfigInfo.draftScenario.uuid;
  const [loading, setLoading] = useState(true);

  async function fetchUser() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["accountUuid"] = userInfo.accountUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/account/users/list`;
    fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      res.json().then(async (res_obj) => {
        setUsers(res_obj.userList.map((e: any) => ({ ...e, id: e.uuid })));
        setLoading(false);
      })
    );
  }

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <div>
      {/* <h1>Current Infrastructure</h1> */}
      <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
        <div className="vl"></div>
        <button
          className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
          onClick={() => (setShowSecondaryCategory([true, false, false]), setActiveMenu("All"))}>
          All
        </button>
        <div className="vl"></div>
        <button
          className={`tab-btn ${activeMenu === "Company Locations" ? "active" : ""}`}
          onClick={() => (setShowSecondaryCategory([false, true, false]), setActiveMenu("Company Locations"))}>
          Active
        </button>
        <div className="vl"></div>
        <button
          className={`tab-btn ${activeMenu === "Home Locations" ? "active" : ""}`}
          onClick={() => (setShowSecondaryCategory([false, false, true]), setActiveMenu("Home Locations"))}>
          Delete
        </button>
        <div className="vl"></div>
      </div>
      {showSecondaryCategory[0] && (
        <>
          <UserListTable users={users} />
        </>
      )}
      {showSecondaryCategory[1] && (
        <>
          <UserListTable users={users.filter((e: any) => e.state === "ACTIVE")} />
        </>
      )}
      {showSecondaryCategory[2] && (
        <>
          <UserListTable users={users.filter((e: any) => e.state === "DELETED")} />
        </>
      )}
    </div>
  );
}

export default UserListPage;
