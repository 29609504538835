import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { useNavigate } from "react-router";

interface DataRow {
    id: number;
    uuid: string;
    siteAccessPolicyUuid: string;
    workflowState: string;
    chargePointOperator: {
        uuid: string;
        name: string;
    };
    rateCardName: string;
    ppaPricePerKWh: number;
    monthlyCommitmentInGbp: number;
    annualCommitmentInGbp: number;
    publicSavingInGbp: number;
    rowId: any;
}

interface DataGridChildProps {
    businessChargingCategory: string;
    quotes: DataRow[];
    acceptQuote: (chargePointOperatorUuid: string, rateCardUuid: string, rowId: any) => void;
}

const formatToGBP = (value: number) => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
};



const PowerPurchaseAgreementQuotesTable: React.FC<DataGridChildProps> = ({ businessChargingCategory, quotes, acceptQuote }) => {
    const [includeVAT, setIncludeVAT] = useState(true);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<GridColDef[]>([]);


    const toggleVAT = () => {
        setIncludeVAT(!includeVAT);
    };

    const formatPrice = (cost: number) => {
        if (cost === null || cost === 0) {
            return "";
        }
        const vatRate = 0.20; // Assuming 20% VAT
        const price = includeVAT ? cost : cost / (1 + vatRate);
        return formatToGBP(price);
    };

    const renderHeader = (title: string) => (
        <div>
            <b>{title}</b>
            <br />
            <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>
                {includeVAT ? "(incl VAT)" : "(excl VAT)"}
            </span>
        </div>
    );

    useEffect(() => {
        const updatedColumns: GridColDef[] = [
            {
                field: "chargePointOperatorName",
                headerName: "Charge Point Operator",
                sortable: false,
                width: 180,
                align: "left",
                headerAlign: "left",
                renderCell: (cell) => {
                    return (
                        <span
                            className="hyper-link"
                            onClick={() =>
                                navigate("/agree/charge-point-operator/map", {
                                    state: {
                                        chargePointOperatorBusinessUuid: cell.row.chargePointOperator.uuid
                                    },
                                })
                            }>
                            {cell.row.chargePointOperator.name}
                        </span>
                    );
                }
            },
            {
                field: "rateCardName",
                headerName: "Agreement Name",
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
            },
            {
                field: "ppaPricePerKWh",
                renderHeader: () => renderHeader("Price per kW/h"),
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "monthlyCommitmentInGbp",
                renderHeader: () => renderHeader("Monthly Commitment"),
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "annualCommitmentInGbp",
                renderHeader: () => renderHeader("Annual Commitment"),
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "publicSavingInGbp",
                renderHeader: () => renderHeader("Est. Annual Cost Saving"),
                sortable: false,
                width: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: (params) => (
                    params.row.rateCardName !== null ? (
                        <button onClick={() => acceptQuote(params.row.chargePointOperator.uuid, params.row.rateCardUuid, params.row.rowId)}
                        >Accept</button>
                    ) :
                        <button>Request</button>
                ),
            },
        ];
        setColumns(updatedColumns);
    }, [includeVAT, navigate, acceptQuote]);

    return (
        <>
            <div className="table-box">
                <Button onClick={toggleVAT}>
                    {includeVAT ? "Show Prices Excluding VAT" : "Show Prices Including VAT"}
                </Button>
                <DataGrid
                    rows={quotes}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                    getRowId={(row) => row.chargePointOperator.uuid}
                />
            </div>
        </>
    );
};

export default PowerPurchaseAgreementQuotesTable;
