import UserContext from "Context/UserProvider";
import React, { useContext } from "react";
import { Outlet } from "react-router";

interface RoleAuthProps {
    roles?: string[];
    products?: string[];
}

const RoleAuth: React.FC<RoleAuthProps> = ({ roles, products }) => {
    const { userInfo } = useContext(UserContext);

    let roleOk = !roles || roles.includes(userInfo.userType);
    let productOk = !products || products.some(value => userInfo.businessProducts?.includes(value));

    if (roleOk && productOk) {
        return <div><Outlet /></div>;
    } else {
        return <>This feature is not available for your user role and product.</>;
    }
}

export default RoleAuth;
