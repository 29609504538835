import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import Map from "../../components/common/MapComponent/Map";
import { useLocation } from 'react-router-dom';


function ChargePointOperatorMapPage(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { chargePointOperatorBusinessUuid } = location.state || {}; // Destructure state


    interface LatLng {
        lat: number;
        lng: number;
    }


    interface ChargePointOperator {
        virtual_charging_network_map_data: []; // Replace 'SomeType' with the actual type for this data
        polygons: [];
    }
    
    const [chargePointOperator, setChargePointOperator] = useState<ChargePointOperator>({
        virtual_charging_network_map_data: [],
        polygons: [],
    });
    const [enableButton, setEnableButton] = useState(false);
    const [activeMenu, setActiveMenu] = useState("Charging Network");

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }
    async function fetchChargePointOperatorMap() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["chargePointOperatorBusinessUuid"] = chargePointOperatorBusinessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/cpo/map/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();
        
        setChargePointOperator({
            virtual_charging_network_map_data: isNull(res_json.chargePoints),
            polygons: res_json.polygons,

        });

        setLoading(false);
    }

    function isNull(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    function setVirtualChargingHubs(obj: any) {
    }

    useEffect(() => {
        fetchChargePointOperatorMap();
    }, []);


    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            {/* <h1>Charging Partner Locations</h1> */}
            {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[0]?.text}</p>} */}
            <Map
                selectedLocationDwelling={[]}
                enableButton={[enableButton, setEnableButton]}
                vehicleMapData={[]}
                chargePoints={chargePointOperator.virtual_charging_network_map_data}
                polygons={chargePointOperator.polygons}
                height={800}
            />
            {/* {loading ? <TextSkelton /> : <p>{insightData.mapText[1]?.text}</p>} */}
        </div>
    );
}

export default ChargePointOperatorMapPage;