import { Stack } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function OpportunityLocationsTable(props: any) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "addressLine",
      headerName: "Location",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (cell) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/plan/business/location/detail/location-insight", {
                state: {
                  fleetDwellingUuid: cell.row.uuid,
                  fleetDwellingName: cell.row.dwellingName,
                },
              })
            }>
            {cell.row.addressLine}
          </span>
        );
      },
    },

    {
      field: "visitCount",
      headerName: "Visits",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "totalTimeInMins",
      headerName: "Total Dwell Time",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    },

    {
      field: "averageDwellTime",
      headerName: "Average Dwell Time",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "closestAddressLine",
      headerName: "Closest Location",
      sortable: false,
      width: 240,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "minDistance",
      headerName: "Distance",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
    }

   ];

  return (
    <div className="table-box">
      <h1>Opportunity Locations</h1>
      <br />
      <DataGrid
        width={"100%"}
        rows={props.data}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Infrastructure Locations exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no result
            </Stack>
          ),
        }}
        // selectionModel={selectionModel}
        // onSelectionModelChange={(e) => {
        //     setSelectionModel(e);
        //     const selectedIDs = new Set(e);
        //     const selectedRowData = locations.filter((row: any) =>
        //         (selectedIDs.has(row.id.toString()))
        //     )

        //     const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)]
        //     for (let i = 0; i < rowChanges.length; i++) {
        //         toggleDwelling(rowChanges[i].id)
        //     }
        //     setSelectedRow(selectedRowData)
        // }}
        // checkboxSelection={true}
        fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default OpportunityLocationsTable;
