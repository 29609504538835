import BackButton from "components/common/SiteTemplate/BackButton/BackButton";
import Footer from "components/common/SiteTemplate/Footer/Footer";
import HelpPanel from "components/common/SiteTemplate/HelpPanel/HelpPanel";
import Nav from "components/common/SiteTemplate/Nav/Nav";
import PageMenu from "components/common/SiteTemplate/PageMenu/PageMenu";
import Sidebar from "components/common/SiteTemplate/Sidebar/Sidebar";
import exportLocation from "components/fleet/FleetScenarioConfigPageComponent/InfrastructureTabScreen/ExportLocationFunction";
import UserContext from "Context/UserProvider";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import generatePageInfo from "./generatePageInfo";

function ProtectedRouteLayout(props: any) {
  const [helpPanel, setHelpPanel] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  const navigate = useNavigate();
  const {userInfo, setUserInfo, selectedProduct, setSelectedProduct} = useContext(UserContext);

  const controlSidebar = () => setSidebar((prev: any) => !prev);

  const location = useLocation();

  interface MenuItem {
    title: string;
    url: string;
    pcn?: boolean; // Using pcn?: makes it an optional property
  }
  
  interface pageInfo {
    title: string;
    second_title: string;
    active_menu: string;
    menu: MenuItem[];
    ctaButton: any[];
    back: boolean;
  }
  

  const pageInfo = generatePageInfo(location.pathname, location.state, userInfo.userType, selectedProduct, userInfo.businessCategory) as any;
  const [activeMenu, setActiveMenu] = useState(pageInfo.active_menu);

  return (
    <>
      <Sidebar
        sidebar={[sidebar, setSidebar]}
        controlSideBar={controlSidebar}
        userInfo={[userInfo, setUserInfo]}
      />
      <div className="inner-container">
        <Nav
          sidebar={sidebar}
          controlSideBar={controlSidebar}
          setSidebar={setSidebar}
          businessName={props.businessName}
          notifications={props.notifications}
          userInfo={[userInfo, setUserInfo]}
        />
        {/* <div > */}
        <div
          className="page-title-container"
          style={{ pointerEvents: sidebar ? "all" : "none" }}>
          <div className="page-menu-title">{pageInfo.title}</div>
          <div className="page-title">
            {/* {location.pathname !== '/plan/dashboard' && <BackButton />} */}
            {pageInfo.back && <BackButton />}
            <h1 className="title">{pageInfo.second_title ? pageInfo.second_title : pageInfo.active_menu}</h1>
            <div style={{ marginLeft: 64, display: "flex", gap: 24 }}>
              {pageInfo.ctaButton.map((e: any, index: any) => (
                <a
                  className="change-fleet"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (e.action) {
                      exportLocation(userInfo);
                    } else if (e.state){
                      navigate(e.url, { state: e.state} );
                    } else {
                      navigate(e.url);
                    }
                  }}>
                  {e.title}
                </a>
              ))}
            </div>
            <div
              className="icon-and-text toggle-help"
              onClick={() => setHelpPanel(!helpPanel)}>
              {!helpPanel ? (
                <>
                  <span className="icon material-symbols-outlined">help</span>Open Help
                </>
              ) : (
                <>
                  <span className="icon material-symbols-outlined">help</span>Close Help
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="page-content-container"
          style={{ pointerEvents: sidebar ? "all" : "none" }}>
          <div
            className="overlay"
            onClick={() => controlSidebar()}
            id="overlay"
            style={{ display: sidebar ? "none" : "block", pointerEvents: "all" }}></div>
          <div className="flex-full-height">
            {pageInfo.secondMenu && (
              <PageMenu
                menu={pageInfo.menu}
                activeMenu={[pageInfo.active_menu, setActiveMenu]}
                privateChargingNetworkSubscription={userInfo.privateChargingNetworkSubscription}
              />
            )}

            <div
              className="page-content"
              id="page-content">
              {props.children}
            </div>
       {/* </div> */}
       {helpPanel && (
        <HelpPanel
          title={pageInfo.title}
          content={pageInfo.helpText ?? "help menu"}
          helpPanel={helpPanel}
        />
      )}
         </div>
        </div>
        <Footer></Footer>
      </div>

    </>
  );
}

export default ProtectedRouteLayout;
