import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserProvider";
import FleetInsightTable from "../../components/fleet/FleetInsightComponent/FleetInsightTable";
import ErrorPage from "../ErrorPage/ErrorPage";
import "./FleetInsight.scss";

function FleetInsight(props: any) {
  const location = useLocation() as any;
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(location.state.activeCategory ? location.state.activeCategory : "Category 1");

  const [fleetInsight, setFleetInsight] = useState() as any;
  const [loading, setLoading] = useState(true);
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [showCategory, setShowCategory] = useState(location.state.showCategory ? location.state.showCategory : [true, false, false, false]);
  const [isError, setIsError] = useState(false);

  const returnMenu = [`Scenarios `, `Scenario ${location.state.fleetScenarioNumber} `, "Categories "];

  const fetchFleetInsight = async () => {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = location.state.fleetScenarioUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/insight`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    setFleetInsight(res_json);
    setLoading(false);
  };
  useEffect(() => {
    let cancel = false;
    fetchFleetInsight().then(() => {
      if (cancel) return;
    });

    return () => {
      cancel = true;
    };
  }, [userInfo]);
  const paragraph = <p>Having run comparisons against your vehicles, we have categorised each vehicle based on the operational impact of transitioning to electric. </p>;
  const header = (
    <h1>
      <b>Fleet Insight </b>
    </h1>
  );
  return (
    <>
      {isError ? (
        <ErrorPage statusCode="500" />
      ) : (
        <div className="drag-drop-container">
          <div>
            {returnMenu.map((e: any, index: any) => {
              if (index === returnMenu.length - 1) {
                return <span>{e}</span>;
              }
              return (
                <span onClick={() => navigate(-(returnMenu.length - 1 - index))}>
                  <span className="hyper-link">{e}</span>
                  {"  >  "}
                </span>
              );
            })}
          </div>
          {/* <BackButton /> */}
          {/* <Welcome header={header} paragraph={paragraph} /> */}
          <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
            <button
              className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
              onClick={() => (setShowCategory([true, true, true, true]), setActiveMenu("All"))}>
              All
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Category 1" ? "active" : ""}`}
              onClick={() => (setShowCategory([true, false, false, false]), setActiveMenu("Category 1"))}>
              Category 1
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Category 2" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, true, false, false]), setActiveMenu("Category 2"))}>
              Category 2
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Category 3" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, false, true, false]), setActiveMenu("Category 3"))}>
              Category 3
            </button>
            <div className="vl"></div>
            <button
              className={`tab-btn ${activeMenu === "Category 4" ? "active" : ""}`}
              onClick={() => (setShowCategory([false, false, false, true]), setActiveMenu("Category 4"))}>
              Category 4
            </button>
            <div className="vl"></div>
          </div>
          {showCategory[0] && (
            <>
              <h1>Category 1</h1>
              <p>Vehicles that don't require en-route charging</p>
              <FleetInsightTable
                type={0}
                fleetScenarioUuid={location.state.fleetScenarioUuid}
                fleetScenarioNumber={location.state.fleetScenarioNumber}
                rows={
                  fleetInsight?.cat1
                    ? fleetInsight.cat1.map((e: any) => ({
                        ...e.iceVehicle,
                        fleetSnapshotIceVehicleUuid: e.fleetSnapshotIceVehicleUuid,
                      }))
                    : []
                }
                loading={loading}
              />
            </>
          )}
          {showCategory[1] && (
            <>
              <h1>Category 2</h1>
              <p>Vehicles where 95% of journeys don't require charging and there is a saving of 50% or more on charging versus fuel costs.</p>
              <FleetInsightTable
                type={1}
                fleetScenarioUuid={location.state.fleetScenarioUuid}
                fleetScenarioNumber={location.state.fleetScenarioNumber}
                rows={
                  fleetInsight?.cat2
                    ? fleetInsight.cat2.map((e: any) => ({
                        ...e.iceVehicle,
                        fleetSnapshotIceVehicleUuid: e.fleetSnapshotIceVehicleUuid,
                      }))
                    : []
                }
                loading={loading}
              />
            </>
          )}
          {showCategory[2] && (
            <>
              <h1>Category 3</h1>
              <p>Vehicles where 70% of journeys don't require charging and there is a saving of 30% or more on charging versus fuel costs.</p>
              <FleetInsightTable
                type={2}
                fleetScenarioUuid={location.state.fleetScenarioUuid}
                fleetScenarioNumber={location.state.fleetScenarioNumber}
                rows={
                  fleetInsight?.cat3
                    ? fleetInsight.cat3.map((e: any) => ({
                        ...e.iceVehicle,
                        fleetSnapshotIceVehicleUuid: e.fleetSnapshotIceVehicleUuid,
                      }))
                    : []
                }
                loading={loading}
              />
            </>
          )}
          {showCategory[3] && (
            <>
              <h1>Category 4</h1>
              <p>Vehicles that require more en-route charging and the cost savings are not as high as the other categories.</p>
              <FleetInsightTable
                type={3}
                fleetScenarioUuid={location.state.fleetScenarioUuid}
                fleetScenarioNumber={location.state.fleetScenarioNumber}
                rows={
                  fleetInsight?.cat3
                    ? fleetInsight.cat4.map((e: any) => ({
                        ...e.iceVehicle,
                        fleetSnapshotIceVehicleUuid: e.fleetSnapshotIceVehicleUuid,
                      }))
                    : []
                }
                loading={loading}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default FleetInsight;
