import GoogleMapReact from 'google-map-react';
import { useEffect, useState, useRef } from 'react';
import { HiOutlineLocationMarker, HiOutlineOfficeBuilding } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import MapStyle from './MapStyle';
import './Map.scss';

const Marker = ({ no_visit }: any) => (
    <div>
        <div className={'marker'} style={{ margin: "-10px 0 0 -10px", padding: 20, width: 20, height: 20 }}>
            <span style={{ color: 'white', fontWeight: '800', fontSize: 20, justifyContent: 'center', alignItems: 'center' }}>{no_visit}</span>
        </div>
    </div>
);

const Partner = (props: any) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/share/business/make-advanced-booking', { state: { ...props.info } });
    };

    return (
        <div onClick={handleClick}>
            <div className="marker" style={{ margin: "-20px 0 0 -20px", width: 24, height: 24 }}>
                <HiOutlineLocationMarker
                    color={
                        props.info.workflowState === 'AGREED'
                            ? 'green'
                            : props.info.workflowState === 'POTENTIAL'
                                ? 'grey'
                                : 'grey'
                    }
                    size='20'
                />
            </div>
        </div>
    );
};

const Business = (props: any) => (
    <div>
        <div className="marker" style={{ margin: "-20px 0 0 -20px", width: 24, height: 24 }}>
            <HiOutlineOfficeBuilding color='blue' size='20' />
        </div>
    </div>
);

const ChargePoint = (props: any) => (
    <div>
        <div className="marker" style={{ margin: "-20px 0 0 -20px", width: 24, height: 24 }}>
            <HiOutlineLocationMarker color='blue' size='20' />
        </div>
    </div>
);

const SelectMarker = (props: any) => (
    <div>
        <div className="marker" style={{ margin: "-20px 0 0 -20px", height: 40, width: 40 }}>
            <h1 style={{ color: 'white' }}>S</h1>
        </div>
    </div>
);

function Map(props: any) {
    const [selectLocation, setSelectLocation] = useState<any>(null);
    const [showApproved, setShowApproved] = useState(true);
    const [showBusiness, setShowBusiness] = useState(true);
    const [showPotential, setShowPotential] = useState(true);
    const [showInProgress, setShowInProgress] = useState(true);
    const [showNull, setShowNull] = useState(true);
    const [showOther, setShowOther] = useState(true);
    const [showPolygons, setShowPolygons] = useState(true);
    const mapRef = useRef<any>(null);
    const polygonsRef = useRef<any[]>([]);
    const showButtons = props.showButtons;


    const handleApiLoaded = (map: any, maps: any) => {
        mapRef.current = { map, maps };
        renderPolygons();
    };

    useEffect(() => {
        renderPolygons();
    }, [showApproved, showBusiness, showPotential, showNull, showOther, showPolygons, showInProgress]);

    const processPolygons = (polygonsData: any, map: any, maps: any, options: any) => {
        const {
            showApproved,
            showBusiness,
            showPotential,
            showInProgress,
            showNull,
            showOther,
            polygonsRef
        } = options;

        polygonsData.forEach((polygonData: any) => {
            let strokeColor = "blue";
            let fillColor = "blue";

            if (polygonData.workflowState === 'AGREED') {
                strokeColor = "green";
                fillColor = "green";
            } else if (polygonData.workflowState == null || polygonData.workflowState === 'POTENTIAL') {
                strokeColor = "grey";
                fillColor = "grey";
            } else if (polygonData.workflowState === 'OFFERED' || polygonData.workflowState === 'REQUESTED') {
                strokeColor = "orange";
                fillColor = "orange";
            }

            const shouldDisplay = (
                (polygonData.workflowState === 'AGREED' && showApproved) ||
                (polygonData.workflowState === 'POTENTIAL' && showPotential) ||
                ((polygonData.workflowState === 'OFFERED' || polygonData.workflowState === 'REQUESTED') && showInProgress) ||
                (polygonData.workflowState == null &&  showBusiness) ||
                (polygonData.workflowState !== 'AGREED' && polygonData.workflowState !== 'POTENTIAL' && polygonData.workflowState !== 'OFFERED' && polygonData.workflowState !== 'REQUESTED' && polygonData.workflowState != null && showOther)
            );

            if (polygonData.coords && polygonData.coords.length > 0 && shouldDisplay) {
                const polygon = new maps.Polygon({
                    paths: polygonData.coords,
                    strokeColor: strokeColor,
                    strokeOpacity: 0.5,
                    strokeWeight: 1,
                    fillColor: fillColor,
                    fillOpacity: 0.1
                });

                polygon.setMap(map);
                polygonsRef.current.push(polygon);
            }
        });
    };

    const renderPolygons = () => {
        if (!mapRef.current) return;

        const { map, maps } = mapRef.current;

        // Clear existing polygons
        polygonsRef.current.forEach(polygon => polygon.setMap(null));
        polygonsRef.current = [];

        if (showPolygons) {
            // Process business polygons
            if (props.businessPolygons && props.businessPolygons.length > 0) {
                processPolygons(props.businessPolygons, map, maps, {
                    showApproved,
                    showBusiness,
                    showPotential,
                    showInProgress,
                    showNull,
                    showOther,
                    polygonsRef,
                });
            }

            // Process charging partner polygons
            if (props.chargingPartnerPolygons && props.chargingPartnerPolygons.length > 0) {
                processPolygons(props.chargingPartnerPolygons, map, maps, {
                    showApproved,
                    showBusiness,
                    showPotential,
                    showInProgress,
                    showNull,
                    showOther,
                    polygonsRef,
                });
            }
        }
    };


    const defaultProps = {
        center: props.center ? props.center : {
            lat: 53.40979,
            lng: -2.15761
        },
        zoom: props.zoom ? props.zoom : 7
    };

    function handleMapClick(obj: any) {
        setSelectLocation(<SelectMarker lat={obj.lat} lng={obj.lng} />);
    }

    useEffect(() => {
        setSelectLocation(<></>);
    }, [props.enableChangeLocation]);

    const filteredPartnerLocations = props.partnerLocations?.filter((location: any) => {
        if (location.workflowState === 'AGREED' && showApproved) return true;
        if (location.workflowState === 'POTENTIAL' && showPotential) return true;
        if ((location.workflowState === 'OFFERED' || location.workflowState === 'REQUESTED') && showInProgress) return true;
        if (location.workflowState == null && showNull) return true;
        if (location.workflowState !== 'AGREED' && location.workflowState !== 'POTENTIAL' && location.workflowState !== 'OFFERED' && location.workflowState !== 'REQUESTED' && location.workflowState != null && showOther) return true;
        return false;
    });

    const filteredChargePoints = props.chargePoints?.filter((location: any) => {
        if (location.workflowState === 'AGREED' && showApproved) return true;
        if (location.workflowState === 'POTENTIAL' && showPotential) return true;
        if ((location.workflowState === 'OFFERED' || location.workflowState === 'REQUESTED') && showInProgress) return true;
        if (location.workflowState == null && showNull) return true;
        if (location.workflowState !== 'AGREED' && location.workflowState !== 'POTENTIAL' && location.workflowState !== 'OFFERED' && location.workflowState !== 'REQUESTED' && location.workflowState != null && showOther) return true;
        return false;
    });

    const filteredBusinessLocations = props.businessLocations?.filter((location: any) => {
        if (showBusiness) return true;
        return false;
    });


    return (
        <div style={{
            position: 'relative',
            height: 'calc(100vh - 216px)',
            width: '100%',
        }}>
            {showButtons ? (
                <div style={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    zIndex: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)'
                }}>
                    <button onClick={() => setShowApproved(!showApproved)}>
                        {showApproved ? 'Hide Network' : 'Show Network'}
                    </button>
                    <button onClick={() => setShowBusiness(!showBusiness)}>
                        {showBusiness ? 'Hide Business' : 'Show Business'}
                    </button>                   
                    <button onClick={() => setShowPotential(!showPotential)}>
                        {showPotential ? 'Hide Potential Sites' : 'Show Potential Sites'}
                    </button>
                    <button onClick={() => setShowInProgress(!showInProgress)}>
                        {showInProgress ? 'Hide In Progress Sites' : 'Show In Progress Sites'}
                    </button>
                    <button onClick={() => setShowPolygons(!showPolygons)}>
                        {showPolygons ? 'Hide Polygons' : 'Show Polygons'}
                    </button>
                </div>
            ) : (null)}
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={{ styles: MapStyle.light, gestureHandling: 'cooperative' }}
                onClick={(obj: any) => { if (props.enableChangeLocation) { handleMapClick(obj) } }}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                yesIWantToUseGoogleMapApiInternals
            >
                {selectLocation && selectLocation}
                {filteredPartnerLocations?.map((e: any) => (
                    <Partner
                        key={e.uuid}
                        lat={e.centreLatitude}
                        lng={e.centreLongitude}
                        info={e}
                    />
                ))}
                {filteredChargePoints?.map((e: any) => (
                    <ChargePoint
                        key={e.uuid}
                        lat={e.centreLatitude}
                        lng={e.centreLongitude}
                        info={e}
                    />
                ))}
                {filteredBusinessLocations?.map((e: any) => (
                    <Business
                        key={e.uuid}
                        lat={e.centreLatitude}
                        lng={e.centreLongitude}
                        info={e}
                    />
                ))}
                {props.vehicleMapData.map((e: any) => (
                    <Marker
                        key={e.uuid}
                        lat={e.centreLatitude}
                        lng={e.centreLongitude}
                        no_visit={e.visitCount}
                    />
                ))}
            </GoogleMapReact>
            {props.children}
        </div>
    );
}

export default Map;
