import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router";

function FleetScenarioTable(props: any) {
  const scenarioList = props.scenarioList;
  const runScenario = props.runScenario;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "fleetScenarioNumber",
      headerName: "Number",
      width: 80,
      align: "center",
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "createdDate",
      headerName: "Created On",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 120,
    },
    // {
    //     field: 'name',
    //     headerName: 'Name',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100
    // },
    {
      field: "countLocations",
      headerName: "Number of Locations",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 150,
    },
    {
      field: "countIceVehicles",
      headerName: "Number of Vehicles",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 150,
    },
    {
      field: "countElectricVehicles",
      headerName: "Number of EVs",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 100,
    },

    {
      field: "action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 200,
      renderCell: (cellValue) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <div
              className="action-button"
              onClick={() =>
                navigate("/plan/insight/fleet/scenario/insight", {
                  state: {
                    fleetScenarioUuid: cellValue.row.uuid,
                    fleetScenarioNumber: cellValue.row.fleetScenarioNumber,
                  },
                })
              }>
              {/* <MdDeleteForever /> */}
              <span>INSIGHT</span>
            </div>
            <div className="action-button">
              {/* <MdDeleteForever /> */}
              <span>DELETE</span>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="table-box">
      <DataGrid
        width={"100%"}
        rows={scenarioList}
        autoHeight
        {...scenarioList}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[10]}
        disableColumnMenu
        checkboxSelection={false}
        // disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Fleet Scenarios exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Local filter returns no result
            </Stack>
          ),
        }}
        // selectionModel={selectionModel}
        // onSelectionModelChange={(e) => {
        //     setSelectionModel(e);
        //     const selectedIDs = new Set(e);
        //     const selectedRowData = locations.filter((row: any) =>
        //         (selectedIDs.has(row.id.toString()))
        //     )

        //     const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)]
        //     for (let i = 0; i < rowChanges.length; i++) {
        //         toggleDwelling(rowChanges[i].id)
        //     }
        //     setSelectedRow(selectedRowData)
        // }}
        fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default FleetScenarioTable;
