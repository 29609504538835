import { Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";
import { MdDeleteForever } from "react-icons/md";
import ModalComponent from "../../../../common/Modal/Modal";
import HelpIcon from "components/common/TableHelpIconComponent/HelpIcon";
import { Popover, Button } from "react-bootstrap";

export default function IceVehicleTable(props: any, { data }: any) {
    //State to record all the current vehicle
    const [allVehicles, setAllVehicles] = props.allVehicles;
    const userInfo = props.userInfo;

    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]) as any;
    const [selectedRow, setSelectedRow] = useState([]) as any;

    //State for record the previous file that have uploaded
    // const [previousFile, setPreviousFile] = props.previousFile;

    //State to record the state of delete modal
    const [modalDeleteEvent, setModalDeleteEvent] = useState() as any;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    //This function are you for delete modal to confirm the delete of current list vehicle
    function openModal(value: any) {
        setModalDeleteEvent(value);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const columnHelpContent = [
        { title: "VRN", description: "This is the description for column 1" },
        { title: "Model", description: "This is the description for column 2" },
        { title: "Journey Counts", description: "This is the description for column 3" },
    ];
    // HelpAction component to render the help action menu
    const HelpAction = ({ title, description }: any) => {
        const [show, setShow] = useState(false);

        const handleShow = () => setShow(true);
        const handleHide = () => setShow(false);

        return (
            <>
                {/* <Button
                    variant="link"
                    onClick={handleShow}>
                    <i className="fa fa-question-circle"></i>
                </Button> */}

                {/* <Popover show={show}>
                    <Popover.Header>
                        <Popover.Header>{title}</Popover.Header>
                    </Popover.Header>
                    <Popover.Body>{description}</Popover.Body>
                </Popover> */}
            </>
        );
    };

    // Custom column header component with the HelpAction component
    const CustomHeader = ({ column }: any) => {
        const { title, description } = columnHelpContent.find((c) => c.title === column.name) as any;

        return (
            <>
                {column.name}
                <HelpAction
                    title={title}
                    description={description}
                />
            </>
        );
    };

    function toggleIceVehicle(iceVehicleId: any) {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["fleetScenarioUuid"] = props.fleetScenarioUuid;
        bodyData["fleetSnapshotIceVehicleUuid"] = iceVehicleId;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/ice-vehicles/toggle`;
        fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status !== 200) {
                setIsError(true);
                return;
            }
            res.json().then((res_obj) => { });
        });
    }

    function deleteVehicle(iceVehicle: any) {
        let bodyData: { [name: string]: any } = {};
        let uuid = userInfo.uuid;
        let access_token = userInfo.token;
        bodyData["access_token"] = access_token;
        bodyData["uuid"] = uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["iceVehicle"] = iceVehicle;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/fleet/icevehicle/delete`;
        try {
            let reponse = fetch(fetch_link, requestOptions);
        } catch {
            setIsError(true);
        }
    }
    function removeRows() {
        // setPreviousFile((prev: any) => prev.filter((e: any) => e.fileName.substring(0, e.fileName.indexOf(".csv")).toUpperCase() !== modalDeleteEvent.row.registrationNumber.toUpperCase()));
        deleteVehicle(modalDeleteEvent.row);
        setAllVehicles((prev: any) => prev.filter((e: any) => e.id !== modalDeleteEvent.id));
        closeModal();
    }
    function difference(a1: any, a2: any) {
        var result = [];
        for (var i = 0; i < a1.length; i++) {
            if (a2.indexOf(a1[i]) === -1) {
                result.push(a1[i]);
            }
        }
        return result;
    }

    function toggleAll(selected: boolean) {
        let bodyData: { [name: string]: any } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        bodyData["allSelected"] = selected;
        bodyData["fleetScenarioUuid"] = props.fleetScenarioUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/ice-vehicles/toggle/all`;
        fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status !== 200) {
                setIsError(true);
                return;
            }
            res.json().then((res_obj) => { });
        });
    }


    //Function used for navigate to insight page
    function navigateScreen(value: any, path: any) {
        switch (path) {
            case "/plan/insight/fleet/scenario/categories/vehicle-insight": {
                navigate(path, {
                    state: {
                        iceVehicle: value.row,
                    },
                });
                return;
            }
            case "/plan/insight/fleet/scenario/categories": {
                navigate(path);
                return;
            }
            default: {
                //statements;
                break;
            }
        }
    }

    //Column header for table list
    const columns: GridColDef[] = [
        {
            field: "registrationNumber",
            // headerName: "VRN",
            sortable: false,
            renderHeader: () => {
                return (
                    <div style={{ display: "flex", position: "static", flexDirection: "row", gap: 20 }}>
                        <span>
                            <b>VRN</b>
                        </span>
                        <HelpIcon
                            content={"the vrn is "}
                            header={"VRN"}
                        />
                    </div>
                );
            },
        },
        {
            field: "make",
            headerName: "Make",
            sortable: false,
            // align: 'center',
            renderHeader: () => {
                return (
                    <div style={{ display: "flex", position: "static", flexDirection: "row", gap: 20 }}>
                        <span>
                            <b>Make</b>
                        </span>
                        <HelpIcon
                            content={"the make is "}
                            header={"Make"}
                        />
                    </div>
                );
            },
            headerAlign: "left",
            width: 130,
        },
        {
            field: "model",
            headerName: "Model",
            headerAlign: "left",
            sortable: false,
        },
        {
            field: "journeyCount",
            headerName: "Journey Counts",
            width: 130,
            headerAlign: "right",
            align: "right"
        },
        {
            field: "sampleStartDate",
            headerName: "Date From",
            sortable: false,
            headerAlign: "left",
            width: 130,
        },
        {
            field: "sampleEndDate",
            headerName: "Date To",
            sortable: false,
            headerAlign: "left",
            width: 130,
            valueGetter: (params: { value: any }) => new Date(params.value),
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            align: "center",
            headerAlign: "center",
            width: 100,
            renderCell: (cellValue) => {
                return (
                    <>
                        {/* {true ? */}

                        <div
                            className="action-button"
                            onClick={(event) => {
                                openModal(cellValue);
                            }}>
                            <MdDeleteForever />
                            <span>DELETE</span>
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        const initialSelectedRow = allVehicles.filter((e: any) => e.selected);
        setSelectionModel(initialSelectedRow.map((r: any) => r.id));
        setSelectedRow(initialSelectedRow);
    }, [allVehicles]);
    useEffect(() => {
        props.fetchVehicleList();
    }, []);
    return (
        <>
            <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                <div
                    onClick={() => {
                        closeModal();
                    }}
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IoMdClose size={20} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        marginTop: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 30,
                    }}>
                    <AiOutlineCloseCircle
                        size={32}
                        color={"red"}
                    />
                    <h3>Are you sure?</h3>
                    <p style={{ maxWidth: 400 }}>Do you really want to delete this record? This process cannot be undone.</p>
                    <div style={{ display: "flex", flexDirection: "row", gap: 90 }}>
                        <button
                            className="primary-btn"
                            onClick={() => {
                                closeModal();
                            }}>
                            Cancel
                        </button>
                        <button
                            className="warning-btn"
                            onClick={() => {
                                removeRows();
                            }}>
                            Delete
                        </button>
                    </div>
                </div>
            </ModalComponent>
            <div className="table-box">
                {props.children}
                <DataGrid 
                    rows={allVehicles}
                    autoHeight
                    {...allVehicles}
                    columns={columns}
                    pageSize={15}
                    headerRenderer={CustomHeader}
                    disableColumnMenu
                    components={{
                        NoRowsOverlay: () => (
                            <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center">
                                <span style={{ zIndex: 0 }}>No Fleet Vehicles have been uploaded.</span>
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center">
                                Local filter returns no result
                            </Stack>
                        ),
                    }}
                    rowsPerPageOptions={[10]}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(e: any) => {
                        setSelectionModel(e);
                        const selectedIDs = new Set(e);
                        const selectedRowData = allVehicles.filter((row: any) => selectedIDs.has(row.id.toString()));

                        const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)];
                        if (e.length == allVehicles.length) {
                            toggleAll(true);
                        } else if (e.length == 0) {
                            toggleAll(false);
                        } else {
                            for (let i = 0; i < rowChanges.length; i++) {
                                toggleIceVehicle(rowChanges[i].fleetSnapshotIceVehicleUuid);
                            }
                        }

                        setSelectedRow(selectedRowData);
                    }}
                    checkboxSelection={true}
                    disableSelectionOnClick
                    fontFamily={"Arvo"}></DataGrid>
            </div>
        </>
    );
}
