export const fromHourValues = [
    { label: "00", value: "00", name: "fromHour" },
    { label: "01", value: "01", name: "fromHour" },
    { label: "02", value: "02", name: "fromHour" },
    { label: "03", value: "03", name: "fromHour" },
    { label: "04", value: "04", name: "fromHour" },
    { label: "05", value: "05", name: "fromHour" },
    { label: "06", value: "06", name: "fromHour" },
    { label: "07", value: "07", name: "fromHour" },
    { label: "08", value: "08", name: "fromHour" },
    { label: "09", value: "09", name: "fromHour" },
    { label: "10", value: "10", name: "fromHour" },
    { label: "11", value: "11", name: "fromHour" },
    { label: "12", value: "12", name: "fromHour" },
    { label: "13", value: "13", name: "fromHour" },
    { label: "14", value: "14", name: "fromHour" },
    { label: "15", value: "15", name: "fromHour" },
    { label: "16", value: "16", name: "fromHour" },
    { label: "17", value: "17", name: "fromHour" },
    { label: "18", value: "18", name: "fromHour" },
    { label: "19", value: "19", name: "fromHour" },
    { label: "20", value: "20", name: "fromHour" },
    { label: "21", value: "21", name: "fromHour" },
    { label: "22", value: "22", name: "fromHour" },
    { label: "23", value: "23", name: "fromHour" },
];