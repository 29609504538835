import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import './HomePage_blank.css'; // Import the stylesheet

const HomePage_blank = () => {

    const goDashboard = () => {
        navigate("/login");
    };

    const goRegister = () => {
        navigate("/register");
    }

    let classes = `fixed-top nav-journey`;
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    let navigate = useNavigate();

    return (
        <div className="homepage">
            <div className="icon-container">
                <nav className={classes}>
                    <span className="btn-primary2-nav strapline" onClick={goDashboard}>
                        Login
                    </span>
                </nav>

                <span style={{ fontFamily: 'Qualy', flex: 1, fontSize: 80, color: 'white', cursor: 'pointer' }}><b>Evata</b></span>
                <p className="strapline">Building Europe's most affordable charging network.</p>
            </div>
        </div>
    );
};

export default HomePage_blank;
