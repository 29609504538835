import Question from "components/homepage/FrequenceQuestionComponent/Question";
import ModalComponent from "components/common/Modal/Modal";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";
import BusinessInfoDropList from "./BusinessInfoDropList";
import "./SelectedFleetModal.css";
function checking_null_undefined(array: any) {
  if (array === null || array === undefined) {
    return [];
  }
  return array;
}

function SelectedFleetModal(props: any) {
  const [modalIsOpen, setIsOpen] = props.modalIsOpen;
  const [userInfo, setUserInfo] = props.userInfo;
  const [isError, setIsError] = useState(false);
  const [businessInfo, setBusinessInfo] = useState([]) as any;
  const [loading, setLoading] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }
  async function fetchBusinessInfo() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["accountUuid"] = userInfo.accountUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/business/list`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    setBusinessInfo(checking_null_undefined(res_json.businesses));
    setLoading(false);
  }


  useEffect(() => {
    fetchBusinessInfo();
  }, []);
  return (
    <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
      {
        <div style={{ overflow: "hidden" }}>
          <div
            onClick={() => {
              closeModal();
            }}
            className={"top-modal"}>
            <IoMdClose size={30}
            />
          </div>

          <div className="mid-modal">
            <h1>Choose Fleet</h1>
            {loading ? (
              <>Loading...</>
            ) : (
              <div className="mid-modal-content">
                {businessInfo.map((item: any, index: any) => {
                  return (
                    <BusinessInfoDropList
                      closeModal={closeModal}
                      userInfo={props.userInfo}
                      key={index}
                      title={item.name}
                      uuid={item.uuid}
                      businessProducts={item.products}
                      list={checking_null_undefined(item.fleets)}
                    />
                  );
                })}
              </div>
            )}
            {/* <button onClick={() => goHome()} className='primary-btn' >Home</button> */}
          </div>
        </div>
      }
    </ModalComponent>
  );
}

export default SelectedFleetModal;
