import { Select, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";

import TableSkelton from "components/common/SkeltonComponent/TableSkelton";
import UserContext from "Context/UserProvider";
import { AnyNaptrRecord } from "dns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";

function difference(a1: any, a2: any) {
  var result = [];
  for (var i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

function UserListTable(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const users = props.users;
  // const [fileUploaded, setFileUploaded] = useState() as any
  // const [previousFile, setPreviousFile] = useState([]) as any

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]) as any;
  const [selectedRow, setSelectedRow] = useState([]) as any;
  // const [showCategory, setShowCategory] = props.showCategory
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "last_name",
      headerName: "Last Name",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "state",
      headerName: "Status",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
    },
  ];
  // const [locations, setLocations] = useState() as any

  //   function toggleDwelling(EvId: any) {
  //     let bodyData: { [name: string]: string } = {};
  //     bodyData["uuid"] = userInfo.uuid;
  //     bodyData["fleetUuid"] = userInfo.fleetUuid;
  //     bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
  //     bodyData["fleetSnapshotDwellingUuid"] = EvId;
  //     const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
  //     let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/dwellings/toggle`;
  //     fetch(fetch_link, {
  //       method: "POST",
  //       body: JSON.stringify(bodyData),
  //       headers: {
  //         cache: "no-cache",
  //         pragma: "no-cache",
  //         "Cache-Control": "no-cache",
  //         "Content-Type": "application/json",
  //       },
  //     }).then((res) => {
  //       if (res.status !== 200) {
  //         setIsError(true);
  //         return;
  //       }
  //       res.json().then((res_obj) => {});
  //     });
  //   }
  //   useEffect(() => {
  //     const selectedRow = locations.filter((e: any) => e.selected);
  //     setSelectionModel(selectedRow.map((r: any) => r.id));
  //     setSelectedRow(selectedRow);
  //     setLoading(false);
  //   }, [locations]);
  return (
    <>
      {loading ? (
        <TableSkelton />
      ) : (
        <div className="table-box">
          <DataGrid
            rows={users}
            autoHeight
            {...users}
            columns={columns}
            pageSize={15}
            disableColumnMenu
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center">
                  <span>No User exist.</span>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center">
                  Selected filter returns no result
                </Stack>
              ),
            }}
            rowSelectionModel={selectionModel}
            // onSelectionModelChange={(e) => {
            //   setSelectionModel(e);
            //   const selectedIDs = new Set(e);
            //   const selectedRowData = locations.filter((row: any) => selectedIDs.has(row.id.toString()));

            //   const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)];
            //   for (let i = 0; i < rowChanges.length; i++) {
            //     toggleDwelling(rowChanges[i].id);
            //   }
            //   setSelectedRow(selectedRowData);
            // }}
            checkboxSelection={true}></DataGrid>
        </div>
      )}
    </>
  );
}

export default UserListTable;
