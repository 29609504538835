import React, { useState } from 'react';
import './LogoGrid.css';

const logos = [
    { id: 1, name: 'Ionity', url: 'https://www.zap-map.com/sites/default/files/media/image/2023-12/IONITY%20%C3%94%C3%87%C3%B4%20Master%20Logo_Networkguide_RGB.jpg' },
    { id: 2, name: 'bp pulse', url: 'https://www.bppulse.co.uk/menu-logo.png?w=384&fm=webp' },
    { id: 3, name: 'Pod Point', url: 'https://cdn-www.pod-point.com/_widthSmFit/pod-point-logo.svg?v=1721894635' },
    { id: 4, name: 'GRIDSERVE', url: 'https://www.gridserve.com/wp-content/uploads/2022/12/Sustainable-Energy-Logos-02.svg' },
    { id: 5, name: 'Shell Recharge', url: 'https://shellrecharge.com/_next/static/media/logo_shellrecharge.7cf3a5b6.svg' },
    { id: 6, name: 'Osprey Charging', url: 'https://ospreyaad.cdn.prismic.io/ospreyaad/dfe5be46-4bb0-4e02-9a62-6d6bdc806f29_osprey-logo.svg' },
    { id: 7, name: 'Mer', url: 'https://uk.mer.eco/wp-content/uploads/sites/9/2022/11/Mer_Logo_English_Positive_RGB_Endorsed-1-3200x1157.png' },
    { id: 8, name: 'Ubitricity', url: 'https://ubitricity.com/wp-content/uploads/ubitricity-shell-logo-expansion-charging-infrastructure-cities-local-authorities-ac-dc-charging-solutions.svg' },
    { id: 9, name: 'InstaVolt', url: 'https://instavolt.co.uk/wp-content/uploads/2022/04/InstaVolt-Logo.png' },
    

];

interface LogoGridProps {
    selectedLogos: string[];
    onLogoClick: (name: string) => void;
}

const LogoGrid: React.FC<LogoGridProps> = ({ selectedLogos, onLogoClick }) => {

  
    return (
      <div className="logo-grid">
        {logos.map((logo) => (
          <div
            key={logo.id}
            className={`logo-item ${selectedLogos.includes(logo.name) ? 'selected' : ''}`}
            onClick={() => onLogoClick(logo.name)}
          >
            <img src={logo.url} alt={logo.name} />
          </div>
        ))}
      </div>
    );
  };
  
  export default LogoGrid;