import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router";
import TableSkelton from "../../common/SkeltonComponent/TableSkelton";

function FleetInsightTable(props: any) {
  const navigate = useNavigate();

  function navigateScreen(value: any, path: any) {
    switch (path) {
      case "/plan/insight/fleet/scenario/categories/vehicle-insight": {
        navigate(path, {
          state: {
            iceVehicle: value.row,
            fleetScenarioNumber: props.fleetScenarioNumber,
            fleetScenarioUuid: props.fleetScenarioUuid,
            categoryType: props.type,
          },
        });
        return;
      }
      case "/plan/insight/fleet/scenario/categories": {
        navigate(path);
        return;
      }
      default: {
        //statements;
        break;
      }
    }
  }
  const columns: GridColDef[] = [
    {
      field: "registrationNumber",
      headerName: "VRN",
      sortable: false,
      editable: true,
    },
    {
      field: "make",
      headerName: "Make",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "model",
      headerName: "Model",
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sampleStartDate",
      headerName: "Date From",
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sampleEndDate",
      headerName: "Date To",
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "recommendedEv",
      headerName: "Recommend EV",
      sortable: false,
      width: 530,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValue) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <div
              className="action-button"
              onClick={(event) => {
                navigateScreen(cellValue, "/plan/insight/fleet/scenario/categories/vehicle-insight");
              }}>
              <AiFillEye />
              <span>INSIGHT</span>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="table-box">
      {props.loading ? (
        <TableSkelton />
      ) : (
        <DataGrid
          rows={props.rows}
          autoHeight
          {...props.rows}
          columns={columns}
          disableColumnMenu
          pageSize={15}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      )}
      {/* <Button onClick={()=>compareVehicle(seletedRow)}>Compare {seletedRow.length} file</Button> */}
    </div>
  );
}

export default FleetInsightTable;
