import { DataGrid, GridColDef, GridValueFormatter } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { isChargePointOperator } from "utils/categoryUtils";

interface DataRow {
    id: number; // or string, depending on your data
    uuid: string;
    siteAccessPolicyUuid: string;
    workflowState: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    businessChargingCategory: string;
    chargingPartnerRateCards: DataRow[];
    onButtonClick: (uuid: string, siteAccessPolicyUuid: string, rowId: number) => void;
}

const formatToGBP = (value: number) => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
  };

const ChargingPartnerRateCardTable: React.FC<DataGridChildProps> = ({ businessChargingCategory, chargingPartnerRateCards, onButtonClick }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const columns: GridColDef[] = [

        {
            field: "name",
            headerName: "Name",
            sortable: false,
            width: 180,
            align: "left",
            headerAlign: "left",
            renderCell: (cell) => {
                return (
                    <span
                        className="hyper-link"
                        onClick={() =>
                            navigate(isChargePointOperator(businessChargingCategory) ? "/agree/charge-point-operator/rate-cards/edit" :"/share/business/charging-partners/rate-cards/edit", {
                                state: {
                                    chargingPartnerRateCardUuid: cell.row.uuid,
                                    chargingPartnerRateCardName: cell.row.name
                                },
                            })
                        }>
                        {cell.row.name}
                    </span>
                );
            }
        },

        {
            field: "allowanceKWh",
            headerName: "Allowance kW/h",
            sortable: false,
            width: 180,
            align: "right",
            headerAlign: "right",
        },

        {
            field: "energyCostDcGbpPerKWhPeak",
            headerName: "Price per kW/h",
            sortable: false,
            width: 180,
            align: "right",
            headerAlign: "right",
            valueFormatter: (params) => {
                // Assuming the price is in a format that can be converted to a number
                const energyCostDcGbpPerKWhPeak = params;
                // Format the price as currency, e.g., '$123.45'
                // Adjust 'en-US' and 'USD' to fit the locale and currency you need
                return new Intl.NumberFormat('en-UK', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(energyCostDcGbpPerKWhPeak);
            }
        },
    ];

    return (
        <>
            <div className="table-box">

            <DataGrid
                    rows={chargingPartnerRateCards}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                />
                
            </div>
        </>
    );
}

export default ChargingPartnerRateCardTable;
