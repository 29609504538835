import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Importing a green tick icon from Material-UI (optional)
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import UserContext from "Context/UserProvider";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import {
    hasChargingRequirement,
    hasSharedInfrastructure,
    SHARE,
    CHARGE,
    SHARE_AND_CHARGE,
} from "utils/categoryUtils";
import Popup from '../../common/Popup'; // Adjust the import path based on your file structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faChargingStation } from '@fortawesome/free-solid-svg-icons';



interface PopupProps {
    onClose: () => void; // Defines onClose as a function that returns nothing
    children: React.ReactNode; // Defines children to accept any valid React node
}

interface DataRow {
    uuid: string;
    siteAccessPolicyUuid: string;
    workflowState: string;
    vistorWorkflowState: string;
    rateCard: string;
    businessUuid: string;
    chargingPartnerBusinessUuid: string;
    businessCategory: string;
    canOfferCharging: boolean;
    canRequestCharging: boolean;
    supplierChargingPartnerUuid: string;
    consumerChargingPartnerUuid: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    chargingPartners: DataRow[];
    requestPartnership: (uuid: string, siteAccessPolicyUuid: string, rowId: number, workflowState: string, businessCategory: string, canOfferCharging: boolean, canRequestCharging: boolean, partnershipRequestType: string, businessUuid: string, chargingPartnerBusinessUuid: string, supplierChargingPartnerUuid: string, consumerChargingPartnerUuid: string) => void;
}

const ChargingPartnerTable: React.FC<DataGridChildProps> = ({ chargingPartners, requestPartnership }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(UserContext);

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState('');

    const reviewOffer = async (chargingPartnerUuid: any, chargingPartnerBusinessName: any, rowId: any, workflowState: string, requestType: string, businessUuid: string, chargingPartnerBusinessUuid: string) => {

        navigate("/share/business/charging-partners/offer/review", {
            state: {
                chargingPartnerUuid: chargingPartnerUuid,
                chargingPartnerBusinessName: chargingPartnerBusinessName,
                workflowState: workflowState,
                businessUuid: chargingPartnerBusinessUuid,
                chargingPartnerBusinessUuid: businessUuid,
                requestType: requestType,
            },
        })
    };

    const makeOffer = async (chargingPartnerUuid: any, chargingPartnerBusinessName: any, rowId: any, workflowState: string, requestType: string, businessUuid: string, chargingPartnerBusinessUuid: string, businessCategory: string, canOfferCharging: boolean, canRequestCharging: boolean, partnershipRequestType: string) => {

        navigate("/share/business/charging-partners/request", {
            state: {
                chargingPartnerUuid: chargingPartnerUuid,
                chargingPartnerBusinessName: chargingPartnerBusinessName,
                workflowState: workflowState,
                businessCategory: businessCategory,
                partnershipRequestType: partnershipRequestType,
                canOfferCharging: canOfferCharging,
                canRequestCharging: canRequestCharging,
                businessUuid: businessUuid,
                chargingPartnerBusinessUuid: chargingPartnerBusinessUuid,
                requestType: requestType,

            },
        })

    };

    const showDetails = async (chargingPartnerBusinessName: any, workflowState: string, requestType: string, businessUuid: string, chargingPartnerBusinessUuid: string) => {

        navigate("/share/business/charging-partners/offer/review", {
            state: {
                chargingPartnerBusinessName: chargingPartnerBusinessName,
                workflowState: workflowState,
                businessUuid: businessUuid,
                chargingPartnerBusinessUuid: chargingPartnerBusinessUuid,
                requestType: requestType,
            },
        })
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
    };

    const columns: GridColDef[] = [
        {
            field: "chargingPartnerBusinessName",
            headerName: "Partner Name",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
            renderCell: (cell) => {
                return (
                    <span
                        className="hyper-link"
                        onClick={() =>
                            navigate("/share/business/charging-partners/edit", {
                                state: {
                                    chargingPartnerBusinessUuid: cell.row.chargingPartnerBusinessUuid,
                                    chargingPartnerBusinessName: cell.row.chargingPartnerBusinessName,
                                    mode: "VIEW"
                                },
                            })
                        }>
                        {cell.row.chargingPartnerBusinessName}
                    </span>
                );
            }
        },
        ...(hasSharedInfrastructure(userInfo.businessCategory)
            ? [
                {

                    field: "workflowState",
                    headerName: "Charging at our Location",
                    sortable: false,
                    width: 280,
                    align: 'left' as const, // Correct type for align
                    headerAlign: 'left' as const, // Correct type for headerAlign
                    renderCell: (params: GridRenderCellParams) => {
                        let displayValue = params.value;
                        let isClickable = false; // A flag to determine if the value is clickable
                        let showNotification = false; // Flag to show red dot

                        if (params.value === "OFFERED") {
                            displayValue = "OFFER MADE TO THEM";
                            isClickable = true;
                        } else if (params.value === "REQUESTED") {
                            displayValue = "PARTNERSHIP REQUEST RECEIVED";
                            isClickable = true;
                            showNotification = true; // Show red dot for "OFFER MADE TO THEM"
                        } else if (params.value === "POTENTIAL") {
                            displayValue = "OFFER PARTNERSHIP";
                            isClickable = true;
                        } else if (params.value === "AGREED") {
                            displayValue = "AGREED";
                            isClickable = true;
                        }

                        const onClick = () => {
                            const apiCallParams = {
                                chargingPartnerUuid: params.row.uuid,
                                chargingPartnerBusinessName: params.row.chargingPartnerBusinessName,
                                chargingPartnerBusinessUuid: params.row.chargingPartnerBusinessUuid,
                                businessUuid: params.row.businessUuid,
                                canOfferCharging: params.row.canOfferCharging,
                                canRequestCharging: params.row.canRequestCharging,
                                rowId: params.row.id,
                                businessCategory: params.row.businessCategory,
                            };

                            // Call the parent's API function
                            if (params.value === "OFFERED") {
                                reviewOffer(
                                    apiCallParams.chargingPartnerUuid,
                                    apiCallParams.chargingPartnerBusinessName,
                                    apiCallParams.rowId,
                                    params.value,
                                    "TO",
                                    apiCallParams.chargingPartnerBusinessUuid,
                                    apiCallParams.businessUuid
                                );
                            } else if (params.value === "POTENTIAL" || params.value === "REQUESTED") {
                                requestPartnership(
                                    apiCallParams.chargingPartnerUuid,
                                    apiCallParams.chargingPartnerBusinessName,
                                    apiCallParams.rowId,
                                    params.value,
                                    apiCallParams.businessCategory,
                                    apiCallParams.canOfferCharging,
                                    apiCallParams.canRequestCharging,
                                    "OFFER",
                                    apiCallParams.businessUuid,
                                    apiCallParams.chargingPartnerBusinessUuid,
                                    params.row.supplierChargingPartnerUuid,
                                    params.row.consumerChargingPartnerUuid

                                );
                            } else if (params.value === "AGREED") {
                                showDetails(
                                    apiCallParams.chargingPartnerBusinessName,
                                    params.value,
                                    "AGREE",
                                    apiCallParams.businessUuid,
                                    apiCallParams.chargingPartnerBusinessUuid,
                                );
                            }
                        };

                        return (
                            <div
                                className={isClickable ? 'hyper-link' : ''}
                                onClick={onClick}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >

                                {displayValue}
                                {displayValue === 'AGREED' && (
                                    <CheckCircleIcon style={{ color: 'green', marginLeft: 8 }} />
                                )}
                                {showNotification && (
                                    <div
                                        style={{
                                            width: 8,
                                            height: 8,
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            marginLeft: 8,
                                        }}
                                    ></div>
                                )}
                            </div>
                        );
                    },
                },
            ]
            : []),

        ...(hasChargingRequirement(userInfo.businessCategory)
            ? [
                {
                    field: "visitorWorkflowState",
                    headerName: "Charging at Partner Location",
                    sortable: false,
                    width: 280,
                    align: 'left' as const, // Correct type for align
                    headerAlign: 'left' as const, // Correct type for headerAlign
                    renderCell: (params: GridRenderCellParams) => {
                        let displayValue = params.value;
                        let isClickable = false; // A flag to determine if the value is clickable
                        let showNotification = false; // Flag to show red dot

                        if (params.value === "OFFERED") {
                            displayValue = "OFFER RECEIVED";
                            isClickable = true;
                            showNotification = true; // Show red dot for "OFFER RECEIVED"
                        } else if (params.value === "REQUESTED") {
                            displayValue = "PARTNERSHIP REQUEST SENT";
                        } else if (params.value === "POTENTIAL") {
                            displayValue = "REQUEST PARTNERSHIP";
                            isClickable = true;
                        } else if (params.value === "AGREED") {
                            displayValue = "AGREED";
                            isClickable = true;
                        }

                        const onClick = () => {
                            const apiCallParams = {
                                chargingPartnerUuid: params.row.uuid,
                                chargingPartnerBusinessName: params.row.chargingPartnerBusinessName,
                                businessUuid: params.row.businessUuid,
                                chargingPartnerBusinessUuid: params.row.chargingPartnerBusinessUuid,
                                businessCategory: params.row.businessCategory,
                                canOfferCharging: params.row.canOfferCharging,
                                canRequestCharging: params.row.canRequestCharging,
                                rowId: params.row.id,
                            };

                            // Call the parent's API function
                            if (params.value === "OFFERED") {
                                reviewOffer(
                                    params.row.consumerChargingPartnerUuid,
                                    apiCallParams.chargingPartnerBusinessName,
                                    apiCallParams.rowId,
                                    params.value,
                                    "FROM",
                                    apiCallParams.businessUuid,
                                    apiCallParams.chargingPartnerBusinessUuid,
                                );
                            } else if (params.value === "POTENTIAL") {
                                requestPartnership(
                                    apiCallParams.chargingPartnerUuid,
                                    apiCallParams.chargingPartnerBusinessName,
                                    apiCallParams.rowId,
                                    params.value,
                                    apiCallParams.businessCategory,
                                    apiCallParams.canOfferCharging,
                                    apiCallParams.canRequestCharging,
                                    "REQUEST",
                                    apiCallParams.businessUuid,
                                    apiCallParams.chargingPartnerBusinessUuid,
                                    params.row.supplierChargingPartnerUuid,
                                    params.row.consumerChargingPartnerUuid);
                            } else if (params.value === "AGREED") {
                                    showDetails(
                                        apiCallParams.chargingPartnerBusinessName,
                                        params.value,
                                        "AGREE",
                                        apiCallParams.chargingPartnerBusinessUuid,
                                        apiCallParams.businessUuid,
                                    );
                                }
                            };

                        return (
                            <div
                                className={isClickable ? 'hyper-link' : ''}
                                onClick={onClick}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {displayValue}
                                {displayValue === 'AGREED' && (
                                    <CheckCircleIcon style={{ color: 'green', marginLeft: 8 }} />
                                )}
                                {showNotification && (
                                    <div
                                        style={{
                                            width: 8,
                                            height: 8,
                                            backgroundColor: 'red',
                                            borderRadius: '50%',
                                            marginLeft: 8,
                                        }}
                                    ></div>
                                )}
                            </div>);
                    },
                },
                {
                    field: "chargingPartnerBusinessCategory",
                    headerName: "Partner's Business Category",
                    sortable: false,
                    width: 320,
                    align: "left" as const,
                    headerAlign: "left" as const,
                    renderCell: (cell: GridRenderCellParams) => {
                        return (
                            <span>
                                {cell.row.chargingPartnerBusinessCategory === CHARGE && (
                                    <>
                                        <FontAwesomeIcon color="teal" size="2x" icon={faTruck} style={{ marginRight: '8px', marginTop: '12px' }} />
                                    </>
                                )}
                                {cell.row.chargingPartnerBusinessCategory === SHARE_AND_CHARGE && (
                                    <>
                                        <FontAwesomeIcon color="teal" size="2x" icon={faChargingStation} style={{ marginRight: '8px', marginTop: '12px' }} />
                                        <FontAwesomeIcon color="teal" size="2x" icon={faTruck} style={{ marginRight: '8px', marginTop: '12px' }} />
                                    </>
                                )}
                                {cell.row.chargingPartnerBusinessCategory === SHARE && (
                                    <>
                                        <FontAwesomeIcon color="teal" size="2x" icon={faChargingStation} style={{ marginRight: '8px', marginTop: '12px' }} />
                                    </>
                                )}
                            </span>
                        );
                    }
                },



            ]
            : []),
    ];

    return (
        <>
            {isPopupVisible && <Popup onClose={() => setPopupVisible(false)}>
                <p>This is the popup content!</p>
            </Popup>}

            <div className="table-box">

                <DataGrid
                    rows={chargingPartners}
                    autoHeight
                    {...chargingPartners}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}></DataGrid>
            </div >
        </>
    );
}

export default ChargingPartnerTable;
