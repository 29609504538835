import { Bar, BarChart, Cell, YAxis } from 'recharts';
import "./BarChartSkelton.scss";

function BarChartSkelton(props: any) {
  const data = [
    {
      name: '',
      price: 6000
    },
    {
      name: '',
      price: 7000
    }
  ]
  return (
    <div className="bar-chart col-auto">
      <div className="top-title-skelton skelton"></div>
      <div className="side-text">
        Price of energy comsumption
      </div>
      <BarChart
        width={400}
        height={400}
        data={data}
        barGap={40}
        barSize={40}
        margin={{ top: 100, right: 0, left: 0, bottom: 25 }}
      >
        <YAxis hide />
        <Bar
          dataKey="price"
          barSize={80}
          fontFamily="sans-serif"
        >
          {data.map((entry: any, index: any) => (
            <Cell key={index} className={"bar-skelton"} />
          ))}
        </Bar>
      </BarChart>
      <div className="below-title">Energy Price Comparison (£)</div>
    </div>
  );
}

export default BarChartSkelton;