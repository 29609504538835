import React from "react";
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from "react-icons/ai";
import { FiEdit, FiPieChart } from "react-icons/fi";
import { BsKanban, BsBarChart } from "react-icons/bs";
import { BiColorFill } from "react-icons/bi";
import { IoMdContacts } from "react-icons/io";
import { TbChecklist } from "react-icons/tb";
import { MdOutlineManageAccounts } from "react-icons/md";
import { RiContactsLine, RiStockLine, RiPagesLine } from "react-icons/ri";
import { MdDashboard, MdPieChartOutlined } from "react-icons/md";

import { GiLouvrePyramid } from "react-icons/gi";

export const links = [

  {
    title: "Dashboard",
    url: "plan/dashboard",
    icon: "dashboard",
    acc: false,
    links: [
    ],
  },
  {
    title: "Business",
    url: "plan/business/locations",
    icon: "location_city",
    acc: false,
    links: [
      {
        name: "Locations",
        url: "plan/business/locations",
        pcn: false,
      },
      {
        name: "User Surveys",
        url: "plan/business/surveys",
        pcn: false,
    },
      {
        name: "Operational Cost Models",
        url: "plan/insight/operational-cost-model-summary",
        pcn: false,
      },
      {
        name: "Business Insight",
        url: "plan/business/insight",
        pcn: false,
      },
      {
        name: "Charging Network",
        url: "share/business/partner-charging-network",
        pcn: true,
      },
      {
        name: "Site Access Policies",
        url: "share/business/site-access-policies",
        pcn: true,
      },
      {
        name: "Charging Partners",
        url: "share/business/charging-partners",
        pcn: true,
      },
      {
        name: "Charging Partner Map",
        url: "share/business/charging-partners/map",
        pcn: true,
      },
     {
        name: "Charging Transactions",
        url: "share/business/charging-transactions",
        pcn: true,
      },
      {
        name: "Electric Vehicles",
        url: "share/business/electric-vehicles",
        pcn: true,
      },
   ],
  },

  {
    title: "Fleet",
    url: "plan/scenario/configuration",
    icon: "tune",
    acc: false,
    links: [
      {
        name: "Ice Vehicles",
        url: "plan/scenario/configuration/ice-vehicles",
        icon: <AiOutlineShoppingCart fontSize={25} />,
        pcn: false,
    },
      {
        name: "Electric Vehicles",
        url: "plan/scenario/configuration/ev-vehicles",
        icon: <IoMdContacts fontSize={25} />,
        pcn: false,
      },
       {
        name: "Settings",
        url: "plan/scenario/configuration/parameters",
        icon: <RiContactsLine fontSize={25} />,
        pcn: false,
      },
      {
        name: "Run",
        url: "plan/scenario/configuration/run",
        icon: <RiContactsLine fontSize={25} />,
        pcn: false,
      },
    ],
  },


];