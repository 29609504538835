import ModalComponent from "components/common/Modal/Modal";
import ScenarioConfigContext from "Context/ScenarioConfigContext";
import UserContext from "Context/UserProvider";
import UseInterval from "custom_hook/useInterval";
import React, { useContext, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";

function RunPage(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [scenarioConfigInfo, setScenarioConfigInfo] = useContext(ScenarioConfigContext) as any;
  const [runningScenario, setRunningScenario] = useState(scenarioConfigInfo.runScenario);
  const [draftScenario, setDraftScenario] = useState(scenarioConfigInfo.draftScenario);
  const [statusPolling, setStatusPolling] = useState(runningScenario !== undefined);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();

  async function fetchDraft() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/draft`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    if (res_json.runningScenario !== null) {
      // setRunningScenario()
      setRunningScenario({ ...res_json.runningScenario, percentageComplete: res_json.runningScenario.percentageComplete.toFixed(2) });
      setDraftScenario(res_json.draftScenario);
      setStatusPolling(true);
      setLoading(false);
      return;
    }
    setDraftScenario(res_json.draftScenario);
    setScenarioConfigInfo((prev: any) => ({
      ...prev,
      draftScenario: res_json.draftScenario,
      runningScenario: null,
    }));
    setLoading(false);
  }

  async function runScenario() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = draftScenario.uuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/run`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    if (response.status !== 200) {
      switch (res_json.code) {
        case "FLEET_SCENARIO_NO_ELECTRIC_VEHICLES_SELECTED":
          setErrorMessage("No electric vehicles have been selected.");
          break;
        case "FLEET_SCENARIO_NO_ELECTRIC_VEHICLE_COMPARISONS":
          setErrorMessage(`No suitable electric vehicles selected for ${res_json.fields.map((e: any) => e + ",")}.`);
          break;
        case "FLEET_SCENARIO_NO_DWELLINGS_FOR_INFRASTRUCTURE_ACTUAL":
          setErrorMessage("No locations have been selected although ‘Actual Locations’ was selected.");
          break;
        case "FLEET_SCENARIO_NO_ICE_VEHICLES_SELECTED":
          setErrorMessage("No ICE vehicle was selected.");
          break;
        default:
          setErrorMessage("There error during running scenario");
          break;
      }
      openModal();
      setIsError(true);
      return;
    }
    setRunningScenario(draftScenario);
    fetchDraft();

    // setFleetScenario((lists: any) => lists.map((e: any) => {
    //     if (draftScenario.uuid === scenarioUuid) {
    //         return ({ ...e, status: 'IN_PROGRESS' })
    //     }
    //     return e
    // }
    // ))
    setStatusPolling(true);
  }

  async function checkScenarioProgress() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = runningScenario.uuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/progress`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    if (res_json.percentageComplete === 1) {
      fetchDraft();
      setStatusPolling(false);
      openModal();
      // fetchDraft()
    }
    setRunningScenario((prev: any) => ({
      ...prev,
      percentageComplete: res_json.percentageComplete,
    }));
    // updateRunningScenario(res_json.scenario)
  }

  //UseInterval(checkScenarioProgress, 3000, statusPolling);
  useEffect(() => {
    if (scenarioConfigInfo.runningScenario !== null) {
      setRunningScenario({ ...scenarioConfigInfo.runningScenario, percentageComplete: scenarioConfigInfo.runningScenario.percentageComplete.toFixed(2) });
      // fetchDraft();
      setStatusPolling(true);
      setLoading(false);
    } else {
      fetchDraft();
    }
  }, []);
  useEffect(() => {}, [statusPolling]);

  return (
    <>
      <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
        <>
          <div
            onClick={() => {
              closeModal();
            }}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <IoMdClose size={30}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              margin: 10,
              padding: 50,
              justifyContent: "center",
              alignItems: "center",
              gap: 30,
            }}>
            <>
              {isError ? (
                <h1> {errorMessage} </h1>
              ) : (
                <>
                  <h1>Scenario {runningScenario?.fleetScenarioNumber} is completed</h1>
                  <p style={{ maxWidth: 400, textAlign: "center" }}>
                    Please click <a href="/plan/insight/fleet">here</a> to see the insight
                  </p>
                </>
              )}
            </>
          </div>
        </>
      </ModalComponent>
      {loading ? (
        <>Loading...</>
      ) : statusPolling ? (
        // <div className="scenario-container">
        //   <span className="scenario-title">Scenario {runningScenario.fleetScenarioNumber}</span>
        //   <span>Created : {runningScenario.createdDate}</span>
        //   <span>Number of Locations : {runningScenario.countLocations}</span>
        //   <span>Number of Ice Vehicles : {runningScenario.countIceVehicles}</span>
        //   <span>Number of Electric Vehicles : {runningScenario.countElectricVehicles}</span>
        //   <span>Status : In progress</span>
        //   <ProgressBar
        //     now={Math.round(runningScenario.percentageComplete * 100)}
        //     label={`${Math.round(runningScenario.percentageComplete * 100)}%`}
        //   />
        //   <span>You can now configure your next scenario whilst this one is running</span>

        //   <hr style={{ marginTop: 50 }} />
        //   <div style={{ display: "flex", justifyContent: "space-around" }}>
        //     <button
        //       className="primary-btn"
        //       onClick={() => navigate("/plan/scenario/configuration")}>
        //       NEW CONFIGURATION
        //     </button>
        //     <button className="warning-btn">CANCEL</button>
        //   </div>
        // </div>
        <div className="col-xs-12 col-md-6 col-xl-4">
          <div className="dashboard-card">
            <div className="dashboard-card-title">
              <span className="icon material-symbols-outlined">view_timeline</span>
              Scenario {runningScenario.fleetScenarioNumber}
            </div>
            <div
              className="dashboard-card-content"
              style={{ gap: 20 }}>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Created :
                </span>
                <span className="data">{runningScenario.createdDate}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Locations :
                </span>
                <span className="data">{runningScenario.countLocations}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Ice Vehicles :
                </span>
                <span className="data">{runningScenario.countIceVehicles}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Electric Vehicles :
                </span>
                <span className="data">{runningScenario.countElectricVehicles}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Status :
                </span>
                <span className="data">In Progress</span>
              </div>
              <ProgressBar
                now={Math.round(runningScenario.percentageComplete * 100)}
                label={`${Math.round(runningScenario.percentageComplete * 100)}%`}
              />
              <span>You can now configure your next scenario whilst this one is running</span>
            </div>
            <div
              className="dashboard-card-actions"
              style={{ display: "flex", justifyContent: "center", gap: 50 }}>
              <button
                className="primary-btn"
                onClick={() => navigate("/plan/scenario/configuration")}>
                NEW CONFIGURATION
              </button>
              <button className="warning-btn">CANCEL</button>
            </div>
          </div>
        </div>
      ) : (
        // <div className="scenario-container">
        //   <span className="scenario-title">Scenario {draftScenario.fleetScenarioNumber}</span>
        //   <span>Created : {draftScenario.createdDate}</span>
        //   <span>Number of Locations : {draftScenario.countLocations}</span>
        //   <span>Number of Ice Vehicles : {draftScenario.countIceVehicles}</span>
        //   <span>Number of Electric Vehicles : {draftScenario.countElectricVehicles}</span>
        //   <span>Status : Draft</span>
        //   <hr style={{ marginTop: 85 }} />
        //   <div style={{ display: "flex", justifyContent: "space-around" }}>
        //     <button
        //       className="primary-btn"
        //       onClick={() => navigate("/plan/scenario/configuration")}>
        //       CONFIGURE
        //     </button>
        //     <button
        //       className={"primary-btn"}
        //       onClick={() => runScenario()}>
        //       RUN
        //     </button>
        //   </div>
        // </div>

        <div className="col-xs-12 col-md-6 col-xl-4">
          <div className="dashboard-card">
            <div className="dashboard-card-title">
              <span className="icon material-symbols-outlined">view_timeline</span>
              Scenario {draftScenario.fleetScenarioNumber}
            </div>
            <div
              className="dashboard-card-content"
              style={{ gap: 20 }}>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Created :
                </span>
                <span className="data">{draftScenario.createdDate}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Locations :
                </span>
                <span className="data">{draftScenario.countLocations}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Ice Vehicles :
                </span>
                <span className="data">{draftScenario.countIceVehicles}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Number of Electric Vehicles :
                </span>
                <span className="data">{draftScenario.countElectricVehicles}</span>
              </div>
              <div className="box-info">
                <span
                  className="item"
                  style={{ width: 200 }}>
                  Status :
                </span>
                <span className="data">Draft</span>
              </div>

              <div
                className="dashboard-card-actions"
                style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                <button
                  className="primary-btn"
                  onClick={() => navigate("/plan/scenario/configuration")}>
                  CONFIGURE
                </button>
                <button
                  className={"primary-btn"}
                  onClick={() => runScenario()}>
                  RUN
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RunPage;
