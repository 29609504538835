import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";


interface Question {
    text: string;
    type: string;
    id: string;
}

interface QuestionTableProps {
    data: Question[];
    onUpdate: (updatedData: Question[]) => void;
}

function QuestionTable(props: QuestionTableProps) {



    // const [loading, setLoading] = useState(true);
    // const [modalIsOpen, setIsOpen] = useState(false);

    const { data, onUpdate } = props;

    // const [questions, setQuestions] = React.useState<Question[]>(data);

    const handleDelete = (idToDelete: string) => {
        const updatedData = data.filter((question) => question.id !== idToDelete);
        onUpdate(updatedData);
    };

    useEffect(() => {
    }, [data]);

    // function openModal() {
    //     setIsOpen(true);
    // }
    // function closeModal() {
    //     setIsOpen(false);
    // }

    // const handleCellEditCommit = (params: GridCellEditStopParams) => {
    //     const { id, field, value } = params;
    //     const updatedData = data.map((q) =>
    //         q.id === id.toString() ? { ...q, [field]: value } : q
    //     );
    //     // Inform parent component of data update
    //     onUpdate(updatedData);
    // };

    const columns: GridColDef[] = [
        {
            field: "text",
            headerName: "Question Text",
            editable: true,
            sortable: false,
            width: 800,
            align: "left",
            headerAlign: "left",
        },

    ];

    return (
        <div className="table-box">
                <DataGrid
                    rows={data}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                />
        </div >
    );
}

export default QuestionTable;
