import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";
import { useLocation } from "react-router-dom";

import { ChargingPartner } from "../BusinessChargingNetworkParameters/chargingPartner";

interface DriverInstructionsPanelProps {
    chargingPartnerOffer: ChargingPartner
}

const DriverInstructionsPanel: React.FC<DriverInstructionsPanelProps> = ({ chargingPartnerOffer }) => {

    const location = useLocation();

    const questionColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Question',
            width: 800,
            renderCell: (params: any) => (
                <a href={params.row.signedUrl} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </a>
            )
        }
    ];

    const questionRows: GridRowsProp = chargingPartnerOffer.questions?.map((question: any, index: number) => ({
        id: index,
        name: question.text
    }));

    return (
        <div className="dashboard-card" >
        <div className="dashboard-card-title">
            <span className="icon material-symbols-outlined">view_timeline</span>
            Driver Site Instructions
        </div>
        <div
            className="dashboard-card-content"
            style={{ gap: 20 }}>
            {(!chargingPartnerOffer.siteAccessRestriction || chargingPartnerOffer.questions == null) ? (
                <div className="box-info">
                    <span
                        className="item"
                        style={{ width: 300 }}>
                        No questions
                    </span>
                </div>

            ) : (

                <div>
                    <div className="box-info">
                        <DataGrid rows={questionRows} columns={questionColumns} />
                    </div>

                </div>

            )}

        </div>
    </div>





    )
}
export default DriverInstructionsPanel