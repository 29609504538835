import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function RecentFleets(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();


    async function selectFleet(fleetUuid: any, fleetName: any, businessName: any, businessUuid: any) {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["access_token"] = userInfo.access_token;
        bodyData["fleetUuid"] = fleetUuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/fleet/active/set`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        setUserInfo((prev: any) => ({
            ...prev,
            businessName: businessName,
            businessUuid: businessUuid,
            fleetUuid: fleetUuid,
            currentFleetName: fleetName,
        }));
        navigate("/plan/dashboard");
    }


    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <span className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">electric_car</span>
                    Recent Fleets{" "}
                </span>
                <div className="dashboard-card-content">
                    <ul className="ul">
                        {dashboardInfo.fleetsLastActiveFleets?.map((fleet: any, index: any) => {
                            return (
                                <li
                                    className="hyper-link"
                                    key={index}
                                    onClick={() => selectFleet(fleet.uuid, fleet.name, fleet.businessName, fleet.businessUuid)}>
                                    {fleet.name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="dashboard-card-actions">
                    <div
                        className="icon-and-text"
                        onClick={() =>
                            navigate("/common/add-business-fleet", {
                                state: {
                                    optionCategory: [false, true],
                                    business: {
                                        name: userInfo.businessName,
                                        uuid: userInfo.businessUuid,
                                    },
                                },
                            })
                        }>
                        <span className="icon material-symbols-outlined">add</span>
                        Add Fleet
                    </div>
                    <div
                        className="icon-and-text has-left-border"
                        onClick={() =>
                            navigate("/common/add-business-fleet", {
                                state: {
                                    optionCategory: [true, false],
                                    business: {
                                        name: userInfo.businessName,
                                        uuid: userInfo.businessUuid,
                                    },
                                },
                            })
                        }>
                        <span className="icon material-symbols-outlined">add_business</span>
                        Add Business
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RecentFleets;
