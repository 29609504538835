import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "./AreaChart.scss"
// const data = [
//     { x: 1, y: Math.random() * 20000000, veracity: "low" },
//     { x: 2, y: Math.random() * 20000000, veracity: "low" },
//     { x: 3, y: Math.random() * 20000000, veracity: "low" },
//     { x: 4, y: Math.random() * 20000000, veracity: "medium" },
//     { x: 5, y: Math.random() * 20000000, veracity: "medium" },
//     { x: 6, y: Math.random() * 20000000, veracity: "low" },
//     { x: 7, y: Math.random() * 20000000, veracity: "high" },
//     { x: 8, y: Math.random() * 20000000, veracity: "high" },
//     { x: 9, y: Math.random() * 20000000, veracity: "high" },
//     { x: 10, y: Math.random() * 20000000, veracity: "high" },
//     { x: 11, y: Math.random() * 20000000, veracity: "low" },
//     { x: 12, y: Math.random() * 20000000, veracity: "low" },
//     { x: 13, y: Math.random() * 20000000, veracity: "low" },
//     { x: 14, y: Math.random() * 20000000, veracity: "medium" },
//     { x: 15, y: Math.random() * 20000000, veracity: "medium" },
//     { x: 16, y: Math.random() * 20000000, veracity: "high" }
// ];
const MulticolorAreaChart = ({ data, extractColor }: any) => {
    const dataSets = [] as any;

    let previousColor = null;

    for (const element of data.sort((a: any, b: any) => a.index - b.index)) {
        const color = extractColor(element);

        let dataSet;
        if (dataSets.length === 0 || previousColor !== color) {
            const previousDataSet =
                dataSets.length !== 0 ? dataSets[dataSets.length - 1] : null;
            dataSet = {
                name: `${element.type}`,
                color: color,
                // copy ending point from previous dataset
                data:
                    previousDataSet === null
                        ? []
                        : [previousDataSet.data[previousDataSet.data.length - 1]]
            };
            dataSets.push(dataSet);
        } else {
            dataSet = dataSets[dataSets.length - 1];
        }

        // add point
        dataSet.data.push([element.index, +(parseFloat(element.amount).toFixed(2)) * 100]);
        // dataSet.data.push([element.x, element.y]);

        // update previous element's color
        previousColor = color;
    }


    const options = {
        chart: {
            type: "area",
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            }
        },
        title: {
            text: "Daily Battery Usage Per Journey",
            align: "center"
        },
        tooltip: {
            shared: true
        },
        legend: {
            show: true,
            markers: {
                width: 20,
                height: 20,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            formatter: (value: any) => {
                switch (value) {
                    case "HOME_CHARGE":
                        return "Home Charge"
                    case "USE":
                        return "In Journey"
                    case "EN_ROUTE_CHARGE_PUBLIC":
                        return "Public Charge"
                    case "PRE_CHARGE_PUBLIC":
                        return "Public Pre Charge"
                    case "EN_ROUTE_CHARGE_VIRTUAL":
                            return "Virtual Hub Charge"
                    case "PRE_CHARGE_VIRTUAL":
                            return "Virtual Hub Pre Charge"
                    case "DWELLING_CHARGE":
                        return "Dwelling Charge"
                }

            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                colors: undefined
            },
            formatter: function (val: any, opts: any) {
                return Math.floor(val).toString() + '%'
            },
        },
        noData: {
            text: "Loading...",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000000",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        },
        stroke: {
            show: true,
            curve: "smooth",
            colors: ["silver"],
            width: 2
        },
        fill: {
            colors: [(series: any) => dataSets[series.seriesIndex]?.color || "black"],
            type: "rgb",

        },
        xaxis: {
            show: false,
            type: "numeric",
            decimalsInFloat: Number,
            tickPlacement: 'between',
            // groups: [{
            //     title: "ABC",
            //     cols: 3
            // }, {
            //     title: "XYZ",
            //     cols: 4
            // }]
            labels: {
                show: false,
                formatter: (val: any) => val
            }
        },
        yaxis: {
            labels: {
                formatter: (val: any) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            axisBorder: {
                show: true
            },
            min: 0,
            max: 100
        }
    };

    return <Chart type="area" width="100%" height="100%" options={options} series={dataSets} />;
};
function checkNull(data: any) {
    if (data === null || data === undefined) {
        return []
    }
    else {
        return data
    }
}
export default function AreaChart(props: any) {
    const data = checkNull(props.data[props.selectedJourney]?.map((e: any, index: any) => ({ ...e, index: index + 1 })))
    // data.push({ type: 'USE', amount: 0, index: data.length + 1 })

    return (
        <div className='area_chart_container'>

            <MulticolorAreaChart
                data={data}
                extractColor={(element: any) => {
                    switch (element.type) {
                        case "USE":
                            return "#BEF213";
                        case "HOME_CHARGE":
                            return "#B2BEB5";
                        case "EN_ROUTE_CHARGE_PUBLIC":
                            return "#F24713";
                        case "PRE_CHARGE_PUBLIC":
                            return "#4FF213";
                        case "EN_ROUTE_CHARGE_VIRTUAL":
                            return "#FFCC00";
                        case "PRE_CHARGE_VIRTUAL":
                            return "#FFCC00";
                        case "DWELLING_CHARGE":
                            return "#B4DBBE"
                        default:
                            return "black";
                    }
                }}
            />
        </div>
    );
};


