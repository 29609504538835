import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import ChargingPartnerRateCardTable from "../../components/charging/ChargingPartnerRateCardComponent/ChargingPartnerRateCardTable";

function ChargingPartnerRateCardListPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [chargingPartnerRateCards, setChargingPartnerRateCards] = useState() as any;
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("All");

    const fetchChargingPartnerRateCards = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/charging-partner/rate-card/list`;

        console.log(fetch_link);
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();
        console.log(res_json.chargingPartnerRateCards);
        setChargingPartnerRateCards(res_json.chargingPartnerRateCards.map((e: any) => ({ ...e, id: e.uuid, name: e.name })));
        setLoading(false);
    };

    const submitChargingPartnerRateCard = async (uuid: any, siteAccessChargingPartnerRateCardUuid: any, rowId: any) => {
        try {
            let bodyData: { [name: string]: string } = {};

            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["chargingPartnerRateCardUuid"] = uuid;

            console.log(bodyData);

            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/charging-partner/rate-card/submit`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            if (response.ok) {
                // If the API call is successful, update the status of the corresponding row.
                const updatedData = chargingPartnerRateCards.map((row: { id: any; }) =>
                    row.id === rowId ? { ...row, workflowState: res_json.workflowState /* or whatever your actual key is */ } : row
                );
                setChargingPartnerRateCards(updatedData);
            } else {
                throw new Error(res_json.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            // Handle your error states appropriately
        }
    };



    useEffect(() => {
        fetchChargingPartnerRateCards();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <ChargingPartnerRateCardTable businessChargingCategory={userInfo.businessCategory} chargingPartnerRateCards={chargingPartnerRateCards} onButtonClick={submitChargingPartnerRateCard} />
            )}
        </>
    );
}

export default ChargingPartnerRateCardListPage;
