import './TableSkelton.scss';

function TableSkelton(props: any) {
    return (
        <table className="table">
            <tbody>

                <tr>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                    <th className="tg-cly1">
                        <div className="line skelton"></div>
                    </th>
                </tr>
                <tr>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                </tr>
                <tr>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                </tr>
                <tr>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                </tr>
                <tr>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                    <td className="tg-cly1">
                        <div className="line skelton"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default TableSkelton;