import React from 'react';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import './TermConditionPage.scss'
function TermConditionPage(props: any) {
    return (

        <div>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className="term-container">
                <div className="term-header">
                    <div className='term-title'>
                        Terms and Conditions
                    </div>
                    <br />
                    <div>These current consolidated Terms were published on on 29th August 2021.</div>
                    <br />
                    <div>Welcome to yourbalancesheet.com ("we", "us", and "our"), brought to you by I I 10 Consultancy Limited (ii10) a company registered in England and Wales with company number 05251779.</div>
                    <br />
                    <div>Our Agreement constitutes a legally binding contract between you, the user, and us. Please take the time to read our Agreement carefully as it explains the legal relationship between you and us and will govern your use of the Services. By accessing or using the Services, you are agreeing to be legally bound by our Agreement.</div>

                </div>
                <br />
                <div className='term-contain'>
                    <div className='term-header-1'>
                        1. Definitions and interpretation
                    </div>
                    <br />
                    <div className='term-header-2'>
                        1.1 The definitions and rules of interpretation set out in the schedule shall apply to our Agreement.
                    </div>
                    <br />
                    <div className='term-header-2'>
                        1.2 In our Agreement your access or use of the website and Service incorporates these Terms together with the Policies for so long as you have an Account (our Agreement);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        2. Rights of use

                    </div>
                    <br />
                    <div className='term-header-2'>
                        2.1 Upon Order Acceptance and subject to the terms of our Agreement, we grant you a non-exclusive, non-transferable, personal right to use the Service during Service Hours for the Permitted Purpose.
                    </div>
                    <br />
                    <div className='term-header-2'>
                        2.2 You acknowledge that in order to access the Service you must have an Account and that use of the Service is at all times subject to your compliance with our Agreement and the requirements identified in our Agreement (including all minimum system requirements).
                    </div>
                    <br />
                    <div className="term-header-2">
                        2.3 You acknowledge that the Service does not include:
                    </div>
                    <br />
                    <div className="term-header-3">
                        2.3.1 any services, systems or equipment required to access the internet (and that you are solely responsible for procuring access to the internet and for all costs and expenses in connection with internet access, communications, data transmission and wireless or mobile charges incurred by it in connection with use of the Service);
                    </div>
                    <br />
                    <div className="term-header-3">
                        2.3.1 any services, systems or equipment required to access the internet (and that you are solely responsible for procuring access to the internet and for all costs and expenses in connection with internet access, communications, data transmission and wireless or mobile charges incurred by it in connection with use of the Service);
                    </div>
                    <br />
                    <div className="term-header-3">
                        2.3.1 any services, systems or equipment required to access the internet (and that you are solely responsible for procuring access to the internet and for all costs and expenses in connection with internet access, communications, data transmission and wireless or mobile charges incurred by it in connection with use of the Service);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        3. Indemnity
                    </div>
                    <br />
                    <div className='term-header-2'>
                        3.1 You shall indemnify, keep indemnified and hold us harmless from and against any losses, claims, damages, liability, Data Protection Losses, costs (including legal and other professional fees) and expenses incurred by us as a result of your breach of our Agreement.
                    </div>
                    <br />
                    <div className='term-header-2'>
                        3.2 This clause 3 shall survive termination or expiry of our Agreement.
                    </div>
                    <br />
                    <div className="term-header-1">
                        4. Support
                    </div>
                    <br />
                    <div className="term-header-2">
                        4.1 We will use reasonable endeavours to notify you in advance of scheduled maintenance but you acknowledge that you may receive no advance notification for downtime caused by Force Majeure or for other emergency maintenance
                    </div>
                    <br />
                    <div className='term-header-1'>
                        5.  Changes to services and terms
                    </div>
                    <br />
                    <div className="term-header-2">
                        5.1 We may at our absolute discretion make, and notify you of, updated versions of the documents referred to in any part of our Agreement (excluding in each case the Order Form) from time to time by displaying a prominent notice seeking your agreement within the Service or by e-mail (together with a copy of the update or a link to a copy of the update) or by any other reasonable means which we elect (<b>Update Notification</b>). We will comply with our related obligations in the Privacy Policy.
                    </div>
                    <br />
                    <div className="term-header-2">
                        5.2 The document(s) subject to such Update Notification shall replace the preceding version of the same document(s) for the purposes of our Agreement from the date 10 Business Days’ after Update Notification of such revised document(s) (the <b>Update</b>) (or at such later date as we may specify).
                    </div>
                    <br />
                    <div className="term-header-2">
                        5.3 In the event that you reasonably believe that any Update materially impacts you negatively in any manner you may elect to terminate your Account and our Agreement in respect of the Service provided you exercise such right prior to such Update taking effect pursuant to clause 5.2.
                    </div>
                    <br />
                    <div className="term-header-2">
                        5.4 You acknowledge that we shall be entitled to modify the features and functionality of the Service including limiting the volume of data which may be used, stored or transmitted and making alterations to data retention periods. We shall use reasonable endeavours to ensure that any such modification does not materially adversely affect the use of the Service by our customers generally. We will comply with our related obligations in the Privacy Policy.
                    </div>
                    <br />
                    <div className="term-header-1">
                        6. Fees
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.1 The Fee and any other charges expressly agreed between the parties in writing shall be paid by you at the rates and in the manner described in the Pricing Terms.
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.2 We shall charge you at Order Acceptance for all Fees due under our Agreement, and the Fees shall be paid immediately at Order Acceptance.
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.3 The Fees are inclusive of VAT.
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.4 Fees payable to us under our Agreement shall be via debit or credit card unless otherwise notified by us to you in writing in accordance with our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.5 We shall have the right to charge interest on overdue Fees at the rate of 4% per year above the base rate of Lloyds Bank PLC calculated from the date when payment of the Fees become due for payment up to and including the date of actual payment whether before or after judgment.
                    </div>
                    <br />
                    <div className="term-header-2">
                        6.6 To the extent our Agreement terminates or expires you shall not be entitled to any refund or discount of Fees paid.
                    </div>
                    <br />
                    <div className="term-header-1">
                        7.  Warranties
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.1 Subject to the remainder of this clause 7, we warrant that:
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.1.1 the Service shall operate materially in accordance with its description that is set out on the Order Form and Account when used in accordance with our Agreement under normal use and normal circumstances; and
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.1.2 we will provide the Service with reasonable care and skill.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.2 You acknowledge that clause 7.1 does not apply to Free or Trial Services. Without prejudice to our obligations under our Agreement in respect of Protected Data, Free or Trial Services are provided ‘as is’ and without warranty to the maximum extent permitted by law.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.3 The Service may be subject to delays, interruptions, errors, defects, viruses or other problems or harmful components resulting from use of the internet or public electronic communications networks used by the parties or third parties. You acknowledge that such risks are inherent in cloud services and that we shall have no liability for any such delays, interruptions, errors or other problems.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.4 You warrant and represent that you shall keep confidential and not share with any third party your password or access details for the Service.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.5 If there is a breach of any warranty in clause 7.1 we shall at our option: use reasonable endeavours to repair or replace the impacted Services within a reasonable time or (whether or not it has first attempted to repair or replace the impacted Service) refund the Fees for the impacted Service which were otherwise payable for the period during which we were in breach of any such warranty (provided such period is at least 7 consecutive days). To the maximum extent permitted by law, this clause 7.5 sets out your sole and exclusive remedy (however arising, whether in contract, negligence or otherwise) for any breach of any of the warranties in clause 7.1.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.6 The warranties in clause 7.1 are subject to the limitations set out in clause 13 and shall not apply to the extent that any error in the Service arises as a result of:
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.1 incorrect operation or use of the Service by you, (including any failure to follow the User Policy or failure to meet minimum specifications);
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.2 use of the Service other than for the purposes for which it is intended;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.3 use of the Service with other software or services or on equipment with which it is incompatible;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.4 any act by any third party (including hacking or the introduction of any virus or malicious code;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.5 any modification of the Service (other than that undertaken by us or at our direction); or
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.6.6 any breach of our Agreement by you.
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.7 We may make Non-Supplier Materials available for your use in connection with the Service. You agree that:
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.7.1 we have no responsibility for the use or consequences of use of any Non-Supplier Materials;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.7.2 your use of any Non-Supplier Materials shall be governed by the applicable terms between you and the owner or licensor of the relevant Non-Supplier Materials;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.7.3 you are solely responsible for any Non-Supplier Materials used in connection with the Service and for compliance with all applicable third-party terms which may govern the use of such Non-Supplier Materials; and
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.7.4 the continued availability, compatibility with the Service and performance of the Non-Supplier Materials is outside our control and we have no responsibility for any unavailability of or degradation in the Service to the extent resulting from the availability, incompatibility or performance of any of the Non-Supplier Materials;
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.8 You acknowledge that we accept no liability or obligation (howsoever arising whether under contract, tort, in negligence or otherwise):
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.8.1 that the Service shall meet your individual needs, whether or not such needs have been communicated to us;
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.8.2 that the operation of the Service shall not be subject to minor errors or defects; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        7.8.3 that the Service shall be compatible with any other software or service or with any other hardware or equipment
                    </div>
                    <br />
                    <div className="term-header-2">
                        7.9 Other than as set out in this clause 7, and subject to clause 13.5, all warranties, conditions, terms, undertakings or obligations whether express or implied and including any implied terms relating to quality, fitness for any particular purpose or ability to achieve a particular result are excluded to the fullest extent allowed by applicable law.
                    </div>
                    <br />
                    <div className="term-header-1">
                        8.  Customer’s responsibilities
                    </div>
                    <br />
                    <div className="term-header-2">
                        8.1 You shall at all times comply with all applicable laws relating to the use or receipt of the Services, including laws relating to privacy, data protection and use of systems and communications.
                    </div>
                    <br />
                    <div className="term-header-2">
                        8.2 You shall at all times comply with the User Policy and all other provisions of our Terms.
                    </div>
                    <br />
                    <div className="term-header-1">
                        9.  Intellectual property
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.1 All Intellectual Property Rights in and to the Service (including in all Applications, Policies and all Supplier Provided Materials) belong to and shall remain vested in us or the relevant third party owner.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.2 We have no obligation to deliver any copies of any software to you in connection with our Agreement or the Service.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.3 You will be able to store Customer Data using the Service. You hereby grant a royalty-free, non-transferable, non-exclusive licence for us (and each of its direct and indirect sub-contractors) to use, copy and other otherwise utilise the Customer Data to the extent necessary to perform or provide the Service and producing statistical analysis and other analysis designed to improve the provision of the Service or to exercise or perform our rights, remedies and obligations under our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.4 To the extent Non-Supplier Materials are made available to or used by or on behalf of you in connection with the use or provision of the Service, such use of Non-Supplier Materials (including all licence terms) shall be exclusively governed by applicable third party terms notified or made available by the Supplier or the third party and not by our Agreement. The Supplier grants no Intellectual Property Rights or other rights in connection with any Non-Supplier Materials.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.5 We may use any feedback and suggestions for improvement relating to the Service provided by you without charge or limitation (Feedback). You hereby assign (or shall procure the assignment of) all Intellectual Property Rights in the Feedback with full title guarantee (including by way of present assignment of future Intellectual Property Rights) to us at the time such Feedback is first provided to us.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.6 Except for the rights expressly granted in our Agreement, you or any direct and indirect sub-contractors shall not acquire in any way any title, rights of ownership, or Intellectual Property Rights of whatever nature in the Service (or any part including the Applications or Policies) and no Intellectual Property Rights of either party are transferred or licensed as a result of our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        9.7 This clause 9 shall survive the termination or expiry of our Agreement.
                    </div>
                    <br />
                    <div className="term-header-1">
                        10.  Customer Data
                    </div>
                    <br />
                    <div className="term-header-2">
                        10.1 Customer Data shall at all times remain the property of you or your licensors.
                    </div>
                    <br />
                    <div className="term-header-2">
                        10.2 Except to the extent we have direct obligations under data protection laws, you acknowledge that the Supplier has no control over any Customer Data hosted as part of the provision of the Service and may not actively monitor or have access to the content of the Customer Data. You shall ensure (and is exclusively responsible for) the accuracy, quality, integrity and legality of the Customer Data and that its use (including use in connection with the Service) complies with all applicable laws and Intellectual Property Rights.
                    </div>
                    <br />
                    <div className="term-header-2">
                        10.3 If we become aware of any allegation that any Customer Data may not comply with the User Policy or any other part of our Agreement we shall have the right to permanently delete or otherwise remove or suspend access to any Customer Data which is suspected of being in breach of any of the foregoing from the Service and/or disclose Customer Data to law enforcement authorities (in each case without the need to consult you). Where reasonably practicable and lawful we shall notify you before taking such action.
                    </div>
                    <br />
                    <div className="term-header-2">
                        10.4 Except as otherwise expressly agreed in our Agreement, we shall not be obliged to provide you with any assistance extracting, transferring or recovering any data. You acknowledge and agree that you are responsible for maintaining safe backups and copies of any Customer Data, including as necessary to ensure the continuation of your business. You shall, without limitation, ensure that you back up (or procures the back up of) all Customer Data regularly (in accordance with your needs) and extracts it from the Service prior to the termination or expiry of our Agreement or the cessation or suspension of the Service.
                    </div>
                    <br />
                    <div className="term-header-2">
                        10.5 We routinely undertake regular backups of the Service (which will include Customer Data) for our own business continuity purposes. You acknowledge that such steps do not in any way make us responsible for ensuring the Customer Data does not become inaccessible, damaged or corrupted. To the maximum extent permitted by applicable law, we shall not be responsible (under any legal theory, including in negligence) for any loss of availability of, or corruption or damage to, any Customer Data.

                    </div>
                    <br />
                    <div className="term-header-2">
                        10.6 Unless otherwise set out in the Order Form or subsequently agreed by the parties in writing, you hereby instruct that we shall within 60 days of the earlier of the end of the provision of the Service relating to the processing of the Customer Data securely dispose of such Customer Data processed in relation to the Service which have ended (and all existing copies of it) except to the extent that any Applicable Laws (as defined in the Privacy Policy) require us to store such Customer Data. We shall have no liability (howsoever arising, including in negligence) for any deletion or destruction of any such Customer Data undertaken in accordance with our Agreement.
                    </div>
                    <br />
                    <div className="term-header-1">
                        11.  Confidentiality and security of Customer Data
                    </div>
                    <br />
                    <div className="term-header-2">
                        11.1 We shall maintain the confidentiality of the Customer Data and shall not without your prior written consent or in accordance with our Agreement, disclose or copy the Customer Data other than as necessary for the performance of the Service and producing statistical analysis and other analysis designed to improve the provision of the Service or our express rights and obligations under our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        11.2 We:
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.2.1 undertake to disclose the Customer Data only to those of our officers, employees, agents, contractors and direct and indirect sub-contractors to whom, and to the extent to which, such disclosure is necessary for the purposes contemplated under our Agreement or as otherwise reasonably necessary for the provision or receipt of the Service, and
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.2.2 shall be responsible to you for any acts or omissions of any of the persons referred to in clause 11.2.1 in respect of the confidentiality and security of the Customer Data as if they were our own.
                    </div>
                    <br />
                    <div className="term-header-2">
                        11.3 The provisions of this clause 12 shall not apply to information which:
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.3.1 is or comes into the public domain through no fault ours, our officers, employees, agents or contractors;
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.3.2 is lawfully received by us from a third party free of any obligation of confidence at the time of its disclosure;
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.3.3 is independently developed by us (or any person acting on our behalf), without access to or use of such Confidential Information; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        11.3.4 is required by law, by court or governmental or regulatory order to be disclosed, provided that clauses 11.3.1 to 11.3.3 (inclusive) shall not apply to Protected Data.
                    </div>
                    <br />
                    <div className="term-header-2">
                        11.4 This clause 11 shall survive the termination or expiry of our Agreement for a period of [ten] years.
                    </div>
                    <br />
                    <div className="term-header-2">
                        11.5 To the extent any Customer Data is Protected Data, we shall ensure that such Customer Data may be disclosed or used only to the extent such disclosure or use does not conflict with any of our obligations under the Privacy Policy. Clauses 11.1 to 11.4 (inclusive) are subject to this clause 11.5.
                    </div>
                    <br />
                    <div className="term-header-1">
                        12.  Relief
                    </div>
                    <br />
                    <div className="term-header-2">
                        To the maximum extent permitted by law, we shall not be liable (under any legal theory, including negligence) for any breach, delay or default in the performance of our Agreement to the extent the same (or the circumstances giving rise to the same) arises or was contributed to by any Relief Event.
                    </div>
                    <br />
                    <div className="term-header-1">
                        13.  Limitation of liability
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.1 The extent of our liability under or in connection with our Agreement (regardless of whether such liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation or under any indemnity) shall be as set out in this clause 13.
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.2 Subject to clauses 13.2, 13.3 and 13.5, our total liability howsoever arising under or in connection with our Agreement shall not exceed the total sum actually paid by you for the Service.
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.3 Subject to clause 13.5, we shall not be liable for consequential, indirect or special losses.
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.4 Subject to clause 13.5, we shall not be liable for any of the following (whether direct or indirect):
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.1 loss of profit;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.2 destruction, loss of use or corruption of data;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.3 loss or corruption of software or systems;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.4 loss or damage to equipment;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.5 loss of use;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.6 loss of production;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.7 loss of contract;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.8 loss of opportunity;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.9 loss of savings, discount or rebate (whether actual or anticipated); and/or
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.4.10 harm to reputation or loss of goodwill.
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.5 Notwithstanding any other provision of our Agreement, our liability shall not be limited in any way in respect of the following:
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.5.1 death or personal injury caused by negligence;
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.5.2 fraud or fraudulent misrepresentation; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        13.5.3 any other losses which cannot be excluded or limited by applicable law.
                    </div>
                    <br />
                    <div className="term-header-2">
                        13.6 This clause 13 shall survive the termination or expiry of our Agreement.
                    </div>
                    <br />
                    <div className="term-header-1">
                        14.  Suspension
                    </div>
                    <br />
                    <div className="term-header-2">
                        14.1 We may suspend or terminate your Account and access to the Service if:
                    </div>
                    <br />
                    <div className="term-header-3">
                        14.1.1 we suspect that there has been any misuse of the Service or breach of our Agreement; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        14.1.2 you fail to pay any sums due to us by the due date for payment.
                    </div>
                    <br />
                    <div className="term-header-2">
                        14.2 Where the reason for the suspension is suspected misuse of the Service or breach of our Agreement, without prejudice to its rights under clause 15, we will take steps to investigate the issue and may restore or continue to suspend access at our discretion.
                    </div>
                    <br />
                    <div className="term-header-2">
                        14.3 In relation to suspensions under clause 14.1.2, access to your Account and the Service will be restored promptly after we receive payment in full and cleared funds.
                    </div>
                    <br />
                    <div className="term-header-1">
                        15.  Term and termination
                    </div>
                    <br />
                    <div className="term-header-2">
                        15.1 You may terminate our Agreement or the provision of the Service for convenience at any time.
                    </div>
                    <br />
                    <div className="term-header-2">
                        15.2 You may terminate our Agreement immediately at any time via our website.
                    </div>
                    <br />
                    <div className="term-header-2">
                        15.3 We may terminate or suspend the provision of Free or Trial Services at any time with or without notice.
                    </div>
                    <br />
                    <div className="term-header-2">
                        15.4 We reserve the right to terminate your Account if have not accessed the Service for a continuous period of 18 months.
                    </div>
                    <br />
                    <div className="term-header-2">
                        15.5 Any breach by you of the User Policy or of clause 8 shall be deemed a material breach of our Agreement which is not remediable.
                    </div>
                    <br />
                    <div className="term-header-1">
                        16.  Consequences of termination
                    </div>
                    <br />
                    <div className="term-header-2">
                        16.1 Immediately on termination or expiry of our Agreement (for any reason), the rights granted by us under our Agreement and your Account shall terminate and you shall stop using the Service.
                    </div>
                    <br />
                    <div className="term-header-2">
                        16.2 Termination or expiry of our Agreement shall not affect any accrued rights and liabilities of either party at any time up to the date of termination or expiry and shall not affect any provision of our Agreement that is expressly or by implication intended to continue beyond termination.
                    </div>
                    <br />
                    <div className="term-header-1">
                        17.  Entire agreement
                    </div>
                    <br />
                    <div className="term-header-2">
                        17.1 Our Agreement constitutes the entire agreement between the parties and supersedes all previous agreements, understandings and arrangements between them in respect of its subject matter, whether in writing or oral.
                    </div>
                    <br />
                    <div className="term-header-2">
                        17.2 Each party acknowledges that it has not entered into our Agreement in reliance on, and shall have no remedies in respect of, any representation or warranty that is not expressly set out in our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        17.3 Nothing in our Agreement shall limit or exclude any liability for fraud.
                    </div>
                    <br />
                    <div className="term-header-1">
                        18.  Notices
                    </div>
                    <br />
                    <div className="term-header-2">
                        18.1 Any notice given by a party under our Agreement shall be:
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.1.1 in writing and in English;
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.1.2 signed by, or on behalf of, the party giving it (except for notices sent by email); and
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.1.3 sent to the relevant party at the address set out in clause 18.3 .
                    </div>
                    <br />
                    <div className="term-header-2">
                        18.2 Notices may be given, and are deemed received by email on receipt of a delivery receipt email from the correct address.
                    </div>
                    <br />
                    <div className="term-header-2">
                        18.3 Notices and other communications shall be sent to:
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.3.1 in the case of those to us, to I I 10 Consultancy Limited at:
                    </div>
                    <br />
                    <div className="term-header-3">
                        (a)
                    </div>
                    <div className="term-header-3">
                        I I 10 Consultancy Limited
                    </div>
                    <div className="term-header-3">
                        7 Beechwood Close
                    </div>
                    <div className="term-header-3">
                        Fleet
                    </div>
                    <div className="term-header-3">
                        Hampshire
                    </div>
                    <div className="term-header-3">
                        GU51 5PT
                    </div>
                    <div className="term-header-3">
                        UK
                    </div>
                    <br />
                    <div className="term-header-3">
                        (b) via our <a href="#">Contact Us</a> page.
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.3.2 in the case of those to you, to any email or physical address or contact details notified on the Order Form (as updated from time to time pursuant to clause 18.4).
                    </div>
                    <br />
                    <div className="term-header-2">
                        18.4 Any change to the contact details of a party as set out in clause 18.3 shall be notified to the other party in accordance with clause 18.1 and shall be effective:
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.4.1 on the date specified in the notice as being the date of such change; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        18.4.2 if no date is so specified, five Business Days after the notice is deemed to be received.
                    </div>
                    <br />
                    <div className="term-header-2">
                        18.5 This clause does not apply to notices given in legal proceedings or arbitration.
                    </div>
                    <br />
                    <div className="term-header-1">
                        19.  Variation
                    </div>
                    <br />
                    <div className="term-header-2">
                        19.1 No variation of our Agreement shall be valid or effective unless it is:
                    </div>
                    <br />
                    <div className="term-header-3">
                        19.1.1 an Update made in accordance with our Agreement; or
                    </div>
                    <br />
                    <div className="term-header-3">
                        19.1.2 made in writing, refers to our Agreement and is duly signed or executed by, or on behalf of, each party.
                    </div>
                    <br />
                    <div className="term-header-1">
                        20.  Assignment and subcontracting
                    </div>
                    <br />
                    <div className="term-header-2">
                        20.1 Except as expressly provided in our Agreement, we may at any time assign, sub-contract, sub-licence (including by multi-tier), transfer, mortgage, charge, declare a trust of or deal in any other manner with any or all of its rights or obligations under our Agreement.
                    </div>
                    <br />
                    <div className="term-header-2">
                        20.2 Except as expressly permitted by our Agreement, you shall not assign, transfer, sub-contract, sub-licence, mortgage, charge, declare a trust of or deal in any other manner with any or all of its rights or obligations under our Agreement (including the licence rights granted), in whole or in part, without our prior written consent.
                    </div>
                    <br />
                    <div className="term-header-1">
                        21.  Set off
                    </div>
                    <br />
                    <div className="term-header-2">
                        Each party shall pay all sums that it owes to the other party under our Agreement without any set-off, counterclaim, deduction or withholding of any kind, save as may be required by law.
                    </div>
                    <br />
                    <div className="term-header-1">
                        22.  No partnership or agency
                    </div>
                    <br />
                    <div className="term-header-2">
                        The parties are independent and are not partners or principal and agent and our Agreement does not establish any joint venture, trust, fiduciary or other relationship between them, other than the contractual relationship expressly provided for in it. Neither party shall have, nor shall represent that it has, any authority to make any commitments on the other party’s behalf.
                    </div>
                    <br />
                    <div className="term-header-1">
                        23.  Severance
                    </div>
                    <br />
                    <div className="term-header-2">
                        23.1 If any provision of our Agreement (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of our Agreement shall not be affected.
                    </div>
                    <br />
                    <div className="term-header-2">
                        23.2 If any provision of our Agreement (or part of any provision) is or becomes illegal, invalid or unenforceable but would be legal, valid and enforceable if some part of it was deleted or modified, the provision or part-provision in question shall apply with such deletions or modifications as may be necessary to make the provision legal, valid and enforceable. In the event of such deletion or modification, the parties shall negotiate in good faith in order to agree the terms of a mutually acceptable alternative provision.                    </div>
                    <br />
                    <div className="term-header-1">
                        24.  Waiver
                    </div>
                    <br />
                    <div className="term-header-2">
                        24.1 No failure, delay or omission by either party in exercising any right, power or remedy provided by law or under our Agreement shall operate as a waiver of that right, power or remedy, nor shall it preclude or restrict any future exercise of that or any other right, power or remedy.
                    </div>
                    <br />
                    <div className="term-header-2">
                        24.2 No single or partial exercise of any right, power or remedy provided by law or under our Agreement shall prevent any future exercise of it or the exercise of any other right, power or remedy.
                    </div>
                    <br />
                    <div className="term-header-2">
                        24.3 A waiver of any term, provision, condition or breach of our Agreement shall only be effective if given in writing and signed by the waiving party, and then only in the instance and for the purpose for which it is given.
                    </div>
                    <br />
                    <div className="term-header-1">
                        25.  Costs and expenses
                    </div>
                    <br />
                    <div className="term-header-2">
                        Each party shall pay its own costs and expenses incurred in connection with the negotiation, preparation, signature and performance of our Agreement (and any documents referred to in it).
                    </div>
                    <br />
                    <div className="term-header-1">
                        26.  Third party rights
                    </div>
                    <br />
                    <div className="term-header-2">
                        A person who is not a party to our Agreement shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of its provisions.
                    </div>
                    <br />
                    <div className="term-header-1">
                        27.  Authority
                    </div>
                    <br />
                    <div className="term-header-2">
                        Each party represents and warrants to the other that it has the right, power and authority to enter into our Agreement and grant to the other the rights (if any) contemplated in our Agreement and to perform its obligations under our Agreement.
                    </div>
                    <br />
                    <div className="term-header-1">
                        28.  Governing law
                    </div>
                    <br />
                    <div className="term-header-2">
                        Our Agreement and any dispute or claim arising out of, or in connection with, it, its subject matter or formation (including non-contractual disputes or claims) shall be governed by, and construed in accordance with, the laws of England and Wales.
                    </div>
                    <br />
                    <div className="term-header-1">
                        29.  Jurisdiction
                    </div>
                    <br />
                    <div className="term-header-2">
                        The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, our Agreement, its subject matter or formation (including non-contractual disputes or claims).
                    </div>
                    <br />













                </div>
                <div className='term-title'>
                    Definitions and Intepretation
                </div>
                <div className="term-contain">

                    <div className='term-header-1'>
                        1  In our Agreement:
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Account
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means our continuing relationship which exists from the point that you register with us as a customer or potential customer and pursuant to which you are permitted to use the Applications upon the terms of Our Agreement ;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Applications
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the software or applications used by or on behalf of the Supplier to provide the Subscribed Services;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Business Day
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means a day other than a Saturday, Sunday or bank or public holiday in England;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Customer Data
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means all data (in any form) that is provided to us or uploaded or hosted on any part of the Service by you (but excluding Feedback as defined in clause 9.5);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Data Protection Losses
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means all liabilities, including all:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a)  costs (and legal costs), claims, demands, actions, settlements, interest, charges, procedures, expenses, losses and damages (including relating to material or non-material damage) and;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b)  to the extent permitted by law;
                    </div>
                    <br />
                    <div className='term-header-4'>
                        (i) administrative fines, penalties, sanctions, liabilities or other remedies imposed by a Supervisory Authority;
                    </div>
                    <br />
                    <div className='term-header-4'>
                        (ii) compensation which is ordered by a court or Supervisory Authority; and
                    </div>
                    <br />
                    <div className='term-header-4'>
                        (iii) the reasonable costs of compliance with investigations by a Supervisory Authority;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Fees
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the fee together with any other amounts payable to us under our Agreement;
                    </div>
                    <div className='term-header-1'>
                        Fees
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the fee together with any other amounts payable to us under our Agreement;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Force Majeure
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means an event or sequence of events beyond a party’s reasonable control preventing or delaying it from performing its obligations under our Agreement (provided that an inability to pay is not Force Majeure), including any matters relating to transfer of data over public communications networks and any delays or problems associated with any such networks or with the internet;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Free or Trial Service
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the Service identified as being provided on a trial basis or provided without charge (for the duration of the period during which it is provided on such basis);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Intellectual Property Rights
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means any and all copyright, rights in inventions, patents, know-how, trade secrets, trade marks and trade names, service marks, design rights, rights in get-up, database rights and rights in data, semiconductor chip topography rights, utility models, domain names and all similar rights and, in each case:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a) whether registered or not;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b) including any applications to protect or register such rights;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (c) including all renewals and extensions of such rights or applications;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (d) whether vested, contingent or future; and
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (e) wherever existing;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Materials
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means all services, data, information, content, Intellectual Property Rights, websites, software and other materials provided by or on behalf of us in connection with the Service, but excluding all Customer Data;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Non-Supplier Materials
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means Materials provided, controlled or owned by or on behalf of a third party the use of which is subject to a separate agreement or licence between the Customer and the relevant third party (including such Non-Supplier Materials which may be linked to, interact with or used by the Services) and all other Materials expressly identified as Non-Supplier Materials in our Agreement;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Order Acceptance
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the effective date of the relevant Order Form;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Order Form
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the electronic or physical form (including its schedules, annexes and appendices (if any)) ordering the Service entered into by you and us, incorporating these Terms and our Agreement (and as varied by the parties by agreement in writing from time to time);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Permitted Downtime
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a) scheduled maintenance which we use reasonable endeavours to undertake from 8 pm to 12 pm (UK time);
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b) emergency maintenance; or
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (c) downtime caused in whole or part by Force Majeure.
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Permitted Purpose
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means use solely for your internal business operations in accordance with the applicable Policies and our Agreement. Permitted Purpose expressly excludes any of the following to the maximum extent permitted by law:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a) copying, reproducing, publishing, distributing, redistributing, broadcasting, transmitting, modifying, adapting, editing, abstracting, storing, archiving, displaying publicly or to third parties, selling, licensing, leasing, renting, assigning, transferring, disclosing (in each case whether or not for charge) or in any way commercially exploiting any part of the Service or Policies;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b) permitting any use of the Service or Policies in any manner by any third party (including permitting use in connection with any timesharing or service bureau, outsourced or similar service to third parties or making the Service or Policies (or any part) available to any third party or allowing or permitting a third party to do any of the foregoing;
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (c) combining, merging or otherwise permitting the Service (or any part of it or any Application) to become incorporated in any other program or service, or arranging or creating derivative works based on it (in whole or in part); or
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (d) attempting to reverse engineer, observe, study or test the functioning of or decompile the Applications or the Service (or any part),
                    </div>
                    <br />
                    <div className='term-header-2'>
                        except as expressly permitted under our Agreement.
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Policies
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means each of the following:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a) our policy on acceptable use of the Service and relevant instructions as to how to use that Service (as Updated from time to time), which as at Order Acceptance is the latest version available at [Insert URL] (the User Policy); and
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b) our privacy policy in relation to the Services identifying certain respective rights and obligations of the parties’ in respect of personal data and privacy under our Agreement (as Updated from time to time), which as at Order Acceptance is the latest version available at [Insert URL] (the Privacy Policy);
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Pricing Terms
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means the details of pricing and fees in respect of the Service, as initially provided under the Order Form and updated from time to time in accordance with clause 6.5;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Protected Data
                    </div>
                    <br />
                    <div className='term-header-2'>
                        has the meaning given in the Privacy Policy;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Relief Event
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means:
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (a) any breach of our Agreement by the you; or
                    </div>
                    <br />
                    <div className='term-header-3'>
                        (b) any Force Majeure;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Service Hours
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means 24 hours a day, seven days a week excluding Permitted Downtime;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Service
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means access to the cloud service you have purchased as set out in the Order Form provided for so long as you have an Account with us;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Supervisory Authority
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering Data Protection Laws;
                    </div>
                    <br />
                    <div className='term-header-1'>
                        Supplier Provided Materials
                    </div>
                    <br />
                    <div className='term-header-2'>
                        means all of the Materials provided or made available by or on behalf of us, but excluding all Customer Data and all Non-Supplier Materials;
                    </div>
                    <br />


                </div>
            </div>
        </div>
    );
}

export default TermConditionPage;