import React, { PureComponent } from "react";
import { useNavigate } from "react-router";
import { Line, Area, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, BarChart, Legend } from "recharts";
import "./HorizontalStackedChart.scss";
// const data = [
//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     },
//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     },
//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     },
//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     },

//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     }
//     ,

//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     }
//     ,

//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     },

//     {
//         name: "Peugeot e-Expert Standard 1000 100kW 50kWh S Auto",
//         single_charge: 10,
//         multi_charge: 10,
//         non_charge: 80
//     }

// ];

export default function HorizontalStackedChartCharge(props: any) {
  const navigate = useNavigate();
  const data = props.data.map(({ description, data, nob, id }: any) => {
    return {
      id: id,
      name: description,
      non_charge: Math.round((data[0].value / nob) * 100),
      single_charge: Math.round((data[1].value / nob) * 100),
      multi_charge: Math.round((data[2].value / nob) * 100),
    };
  });
  return (
    <div className="horizontal-chart-container">
      <ResponsiveContainer
        width={"100%"}
        height={"100%"}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            right: 30,
            left: 20,
            bottom: 5,
            top: 20,
          }}
          onClick={(e: any) =>
            navigate("/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight", {
              state: {
                id: e.activePayload[0].payload.id,
                categoryType: props.categoryType,
                fleetScenarioNumber: props.fleetScenarioNumber,
                fleetScenarioUuid: props.fleetScenarioUuid,
                description: e.activeLabel,
                registrationNumber: props.registrationNumber,
              },
            })
          }>
          <XAxis
            type="number"
            axisLine={false}
            unit={"%"}
          />
          <YAxis
            interval={0}
            dataKey="name"
            type="category"
            orientation={"right"}
            width={600}
            axisLine={false}
            tickLine={false}
            fontSize={10}
          />
          <Tooltip />
          <Legend
            formatter={(value: any) => {
              switch (value) {
                case "non_charge":
                  return "Zero Charge";
                case "multi_charge":
                  return "Multiple Charge";
                case "single_charge":
                  return "Single Charge";
                default:
                  return;
              }
            }}
          />
          <Bar
            dataKey="non_charge"
            stackId="a"
            fill="#BEF213"
          />
          <Bar
            dataKey="single_charge"
            stackId="a"
            fill="#C1D1D6"
          />
          <Bar
            dataKey="multi_charge"
            stackId="a"
            fill="#B2BEB5"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
