import { Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../Context/UserProvider";
import TableSkelton from "../../common/SkeltonComponent/TableSkelton";
import { RxUpdate } from "react-icons/rx";
import ModalComponent from "components/common/Modal/Modal";
import { IoMdClose } from "react-icons/io";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UpdateSurveyPage from "pages/SurveyPage/UpdateSurveyPage";
import { useNavigate } from "react-router";

interface DataRow {
    id: string; // or string, depending on your data
    registrationNumber: string;
    make: string;
    model: string;
    combinedRange: string;
    // ... other fields
}

// Define the props your DataGridChild component will accept
interface DataGridChildProps {
    electricVehicles: DataRow[];
}

const ElectricVehicleTable: React.FC<DataGridChildProps> = ({ electricVehicles }) => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const columns: GridColDef[] = [

        {
            field: "registrationNumber",
            headerName: "Registration Number",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "vin",
            headerName: "VIN",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "make",
            headerName: "Make",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "model",
            headerName: "Model",
            sortable: false,
            width: 280,
            align: "left",
            headerAlign: "left",
        },
    ];

    return (
        <>
            <div className="table-box">
                {/* <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose className="close-btn" fontSize={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>

                            <UpdateSurveyPage currentSelected={currentSelected} fetchEmails={fetchEmails} />

                        </div>
                    </>

                </ModalComponent> */}

                <DataGrid
                    rows={electricVehicles}
                    autoHeight
                    {...electricVehicles}
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection={true}></DataGrid>
            </div>
        </>
    );
}

export default ElectricVehicleTable;
