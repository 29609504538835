import auth0 from "auth0-js";

const webAuth = new auth0.WebAuth({
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  responseType: `${process.env.REACT_APP_AUTH0_RESPONSE_TYPE}`,
  redirectUri: `${window.location.origin + "/plan/dashboard"}`,
  scope: "openid profile email offline_access",
});

export default webAuth;
