export const READ_ONLY_ROLES = ["ACCOUNT_READER", "BUSINESS_READER"];
export const ACCOUNT_ROLES = ["ACCOUNT_OWNER", "ACCOUNT_ADMIN", "ACCOUNT_READER"];
export const BUSINESS_ROLES = ["BUSINESS_OWNER", "BUSINESS_ADMIN", "BUSINESS_READER"];
export const OWNER_ROLES = ["BUSINESS_OWNER", "ACCOUNT_OWNER"];

export const isReadOnlyRole = (userType: string): boolean => {
    return READ_ONLY_ROLES.includes(userType);
};

export const isAccountRole = (userType: string): boolean => {
    return ACCOUNT_ROLES.includes(userType);
}

export const isBusinessRole = (userType: string): boolean => {
    return BUSINESS_ROLES.includes(userType);
}
