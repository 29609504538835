
import React, { InputHTMLAttributes, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "Context/UserProvider";
import Select from "react-select";
import { WeekDay, weekDays as initialWeekDays } from "../../charging/BusinessChargingNetworkParameters/weekDays";
import { fromHourValues } from "../../charging/BusinessChargingNetworkParameters/fromHourValues";
import { toHourValues } from "../../charging/BusinessChargingNetworkParameters/toHourValues";
import { reservationOptions } from "components/charging/BusinessChargingNetworkParameters/reservationOptions";
import WeeklyChargingReservation from "../../charging/BusinessChargingNetworkParameters/WeeklyChargerReservation";
import { RateCardOption, RateCardValue } from "../BusinessChargingNetworkParameters/rateCards";

import { ChargingPartner } from "../BusinessChargingNetworkParameters/chargingPartner";
interface ChargingPartnerParams {
    chargingPartnerUuid: string;
    chargingPartnerBusinessName: string;
    mode: 'VIEW' | 'EDIT';  // Assuming mode has specific string values
}

interface EnergyCostPanelProps {

    panelTitle: string;
    singlePhase: number;
    threePhase: number;
    dc: number;
}

const EnergyCostPanel: React.FC<EnergyCostPanelProps> = ({ panelTitle, singlePhase, threePhase, dc }) => {

    const location = useLocation();

    return (


        <div className="dashboard-card">
            <div className="dashboard-card-title">
                <span className="icon material-symbols-outlined">view_timeline</span>
                {panelTitle}
            </div>
            <div
                className="dashboard-card-content"
                style={{ gap: 20 }}>
                <div className="box-info">
                    <span
                        className="item"
                        style={{ width: 300 }}>
                        AC Single Phase :
                    </span>
                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>
                        <span className="data">{singlePhase}</span>
                    </div>
                </div>
                <div className="box-info">
                    <span
                        className="item"
                        style={{ width: 300 }}>
                        AC Three Phase :
                    </span>
                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                        <span className="data">{threePhase}</span>
                    </div>
                </div>
                <div className="box-info">
                    <span
                        className="item"
                        style={{ width: 300 }}>
                        DC :
                    </span>
                    <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                        <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                        <span className="data">{dc}</span>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default EnergyCostPanel;