import React, { createContext, useState, ReactNode } from "react";
import { UserInfo } from "interfaces/UserInfo";
import { UserProfile } from "interfaces/UserProfile";
import { AuthUser } from "interfaces/AuthUser";


interface UserContextType {
    userInfo: UserInfo;
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
    selectedProduct: string | null;
    setSelectedProduct: React.Dispatch<React.SetStateAction<string | null>>;
    theme: string;
    setTheme: React.Dispatch<React.SetStateAction<string>>;
    // Add other state variables and functions here
}

export const defaultAuthUser: AuthUser = {
    email: '',
    sub: '',
    name: '',
    nickname: '',
    picture: '',
    updated_at: '',
    email_verfied: true,
};

export const defaultUserProfile: UserProfile = {
    distanceUnitShortDisplayName: '',
    distanceUnit: '',
    distanceUnitDisplayName: '',
};

export const defaultUserInfo: UserInfo = {
    uuid: '',
    accountUuid: '',
    fleetUuid: '',
    businessUuid: '',
    access_token: '',
    userType: '',
    businessName: '',
    currentFleetName: '',
    privateChargingNetworkSubscription: false,
    userProfile: defaultUserProfile,
    accountType: '',
    authUser: defaultAuthUser,
    authenticated: false,
    completedRegistration: false,
    businessProducts: [], // Initialize with an empty array
    overnightVehicleLocation: '',
    businessCategory: '',
};



const defaultContextValue: UserContextType = {
    userInfo: defaultUserInfo,
    setUserInfo: () => { },
    selectedProduct: null,
    setSelectedProduct: () => { },
    theme: 'light',
    setTheme: () => { },
    // Initialize other variables and functions
};

const UserContext = createContext<UserContextType>(defaultContextValue);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null); // Explicitly set the type
    const [theme, setTheme] = useState<string>('light');

    const value = {
        userInfo,
        setUserInfo,
        selectedProduct,
        setSelectedProduct,
        theme,
        setTheme,
        // ... other state values
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
