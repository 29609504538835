import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

interface PercentagePieProps {
    percentage: number;
    color: string;
}

const PercentagePie: React.FC<PercentagePieProps> = ({ percentage, color }) => {
    const data = [
        { name: 'Percentage', value: percentage },
        { name: 'Remaining', value: 100 - percentage },
    ];

    return (
        <PieChart width={48} height={48}>
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={15}
                outerRadius={25}
                startAngle={90}     // Starting point at 12 o'clock position
                endAngle={-270}     // End at 12 o'clock (clockwise)
                dataKey="value"
                stroke="none"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={index === 0 ? color : '#D3D3D3'} />
                ))}
            </Pie>
        </PieChart>
    );
};

export default PercentagePie;
