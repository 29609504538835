type DashboardCardTitleProps = {
    title: string;
};

function DashboardCardTitle({ title }: DashboardCardTitleProps) {

    return (
        <div className="dashboard-card-title">
            <span className="icon material-symbols-outlined">account_box</span>
            {title}
        </div>
    )
}
export default DashboardCardTitle;