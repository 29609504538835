import AppRoutes from "AppRoutes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

// page imports
// import Home from './pages/Home/Home'

/* TEMPORARY */

// component imports
import ScrollToTop from "./components/common/ScrollToTop";

import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";

import NotificationProvider from "./components/common/Notifications/NotificationProvider";
import { UserProvider } from "Context/UserProvider";

export default function App(props: any) {
    const [sitePassAuthed, setSitePassAuthed] = useState(false);
    const navigate = useNavigate();
    // const [notifications, setNotifications] = useState([]) as any
    const [error, setError] = useState(false);
    // const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [user, setUser] = useState({}) as any;
    // const { isLoading } = useAuth0();
    const [loading, setLoading] = useState(true);

    const [scenarioConfigInfo, setScenarioConfigInfo] = useState({
        unCompletedScenario: {} as any,
    });
    const [siteLoaded, setSiteLoaded] = useState(false);

    return (
        <NotificationProvider>
            <>
                {
                    <ScrollToTop>
                        <UserProvider>
                            <Toaster />
                            <AppRoutes />

                        </UserProvider>
                    </ScrollToTop>
                }
            </>
       </NotificationProvider>
    );
}
