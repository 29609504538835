import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function GettingStarted(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">start</span>
                    Getting Started (Coming Soon)
                </div>
                <div className="dashboard-card-content">
                    <ul className="ul">
                        <li>
                            <a href="">Upload your first set of telematics</a>
                        </li>
                        <li>
                            <a href="">Analysing Vehicle and Fleet Insight </a>
                        </li>
                        <li>
                            <a href="">How to add more credit</a>
                        </li>
                        <li>
                            <a href="">Using our maps</a>
                        </li>
                        <li>
                            <a href="">How to give feedback</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default GettingStarted;
