import React, { useContext, useEffect, useState } from "react";
import { Button, Backdrop, CircularProgress } from '@mui/material';
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import DashboardCardTitle from "components/dashboard/common/DashboardCardTitle";
import DashboardCardItem from "components/dashboard/common/DashboardCardItem";

interface QuoteSummary {
    id: string,
    planNumberOfVehicles: number,
    planAverageDistancePerDay: number,
    planAverageChargesPerMonth: number,
    planAverageChargeSizeKWh: number,
    planMonthlyChargingRequirementKWh: number,
    connectedNumberOfVehicles: number,
    connectedAverageDistancePerDay: number,
    connectedAverageChargesPerMonth: number,
    connectedAverageChargeSizeKWh: number,
    connectedMonthlyChargingRequirementKWh: number
}

function BuildQuotePageSummary(props: any) {
    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useContext(UserContext);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [quoteSummary, setQuoteSummary] = useState<QuoteSummary | null>(null);
    const navigate = useNavigate();

    const quoteSummaryMapping = (e: QuoteSummary): QuoteSummary & { id: string } => ({
        ...e,
        planNumberOfVehicles: e.planNumberOfVehicles,
        planAverageDistancePerDay: e.planAverageDistancePerDay,
        planAverageChargesPerMonth: e.planAverageChargesPerMonth,
        planAverageChargeSizeKWh: e.planAverageChargeSizeKWh,
        planMonthlyChargingRequirementKWh: e.planMonthlyChargingRequirementKWh,
        connectedNumberOfVehicles: e.connectedNumberOfVehicles,
        connectedAverageDistancePerDay: e.connectedAverageDistancePerDay,
        connectedAverageChargesPerMonth: e.connectedAverageChargesPerMonth,
        connectedAverageChargeSizeKWh: e.connectedAverageChargeSizeKWh,
        connectedMonthlyChargingRequirementKWh: e.connectedMonthlyChargingRequirementKWh
    });

    const fetchSummary = async () => {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/ppa/advanced/build/summary/get`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        console.log(JSON.stringify(res_json));
        setQuoteSummary(quoteSummaryMapping(res_json));
        setLoading(false);
    };

    useEffect(() => {
        fetchSummary();
    }, []);

    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 2; // Set the active step

    const handleNext = () => {
        navigate("/agree/ppa/quote/build/results");
    };

    return (
        <div>
            {/* Breadcrumbs always displayed */}
            <Breadcrumbs currentStep={"Summary"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && quoteSummary && (
                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">
                        {/* Plan Based Vehicles */}
                        <div className="col-xs-12 col-md-6 col-xl-6">
                            <div className="dashboard-card">
                                <DashboardCardTitle title="Plan Based Vehicles" />
                                <div className="dashboard-card-content">
                                    <DashboardCardItem itemTitle="Number of vehicles :" itemValue={quoteSummary.planNumberOfVehicles.toFixed(0)} />
                                    <DashboardCardItem itemTitle="Average distance per day :" itemValue={quoteSummary.planAverageDistancePerDay.toFixed(2) + "km"} />
                                    <DashboardCardItem itemTitle="Average charges per month :" itemValue={quoteSummary.planAverageChargesPerMonth.toFixed(0)} />
                                    <DashboardCardItem itemTitle="Average size of charge :" itemValue={quoteSummary.planAverageChargeSizeKWh.toFixed(2) + "kW/h"} />
                                    <DashboardCardItem itemTitle="Monthly Charging Requirement :" itemValue={quoteSummary.planMonthlyChargingRequirementKWh.toFixed(2) + "kW/h"} />
                                </div>
                            </div>
                        </div>

                        {/* Connected Vehicles */}
                        <div className="col-xs-12 col-md-6 col-xl-6">
                            <div className="dashboard-card">
                                <DashboardCardTitle title="Connected Vehicles" />
                                <div className="dashboard-card-content">
                                    <DashboardCardItem itemTitle="Number of vehicles :" itemValue={quoteSummary.connectedNumberOfVehicles.toFixed(0)} />
                                    <DashboardCardItem itemTitle="Average distance per day :" itemValue={quoteSummary.connectedAverageDistancePerDay.toFixed(2) + "km"} />
                                    <DashboardCardItem itemTitle="Average charges per month :" itemValue={quoteSummary.connectedAverageChargesPerMonth.toFixed(0)} />
                                    <DashboardCardItem itemTitle="Average size of charge :" itemValue={quoteSummary.connectedAverageChargeSizeKWh.toFixed(2) + "kW/h"} />
                                    <DashboardCardItem itemTitle="Monthly Charging Requirement :" itemValue={quoteSummary.connectedMonthlyChargingRequirementKWh.toFixed(2) + "kW/h"} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <div className="col-xs-12 col-md-12 col-xl-12">
                        <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                            <button className="primary-btn" disabled={isDisabled} onClick={handleNext}>
                                Get Quotes
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuildQuotePageSummary;