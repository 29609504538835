import UserContext from 'Context/UserProvider';
import React, { useContext, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
// import { Item } from 'semantic-ui-react';
import './BusinessInfoDropList.css'

function BusinessInfoDropList(props: any) {
    const [active, setActive] = useState(false)
    const { userInfo, setUserInfo, selectedProduct, setSelectedProduct, theme, setTheme } = useContext(UserContext);
    const [isError, setIsError] = useState(false)
    const navigate = useNavigate()
    const closeModal = props.closeModal

    async function selectFleet(fleetUuid: any, fleetName: any) {
        let bodyData: { [name: string]: string } = {}
        bodyData['uuid'] = userInfo.uuid
        bodyData['access_token'] = userInfo.access_token
        bodyData['fleetUuid'] = fleetUuid
        bodyData['accountUuid'] = userInfo.accountUuid

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/fleet/active/set`
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        }
        let response = (await fetch(fetch_link, fetch_option))
        if (response.status !== 200) {
            setIsError(true)
            return
        }
        let res_json = await response.json()

        console.log(JSON.stringify(res_json));
        setUserInfo((prev: any) => ({ ...prev, businessName: props.title, businessUuid: props.uuid, fleetUuid: fleetUuid, currentFleetName: fleetName, businessProducts: props.businessProducts, businessCategory: res_json.fleet.businessCategory  }))
        navigate(`/${selectedProduct?.toLowerCase()}`);
        closeModal();




    }
    return (
        <div className="business-drop-list-box">
            <div className="business-drop-list-title" onClick={() => setActive(pre => (!pre))}>
                <div> {props.title}</div>
                <AiOutlinePlusCircle />
            </div>
            {active &&
                (props.list.length > 0 ?
                    props.list.map((item: any, index: any) => {
                        return (
                            <div onClick={() => selectFleet(item.uuid, item.name)} key={index} className="business-drop-list-element">
                                <br />
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                    :
                    <div className="business-drop-list-element">
                        <br />
                        <p>There is not yet any fleet in this business </p>
                        <button onClick={() => (navigate('/common/add-business-fleet', { state: { optionCategory: [false, true], business: { uuid: props.uuid, name: props.title } } }), closeModal())}>Add Fleet</button>
                    </div>)


            }
        </div>
    );
}

export default BusinessInfoDropList;