import { rgbToHex } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HeatMap from "react-heatmap-grid";
import useWindowSize from "../../../custom_hook/useWindowSize";

interface DataPoint {
    uuid: string | null;
    pill: string;
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED';
    hourOfDay: number;
    available: boolean;
    reserved: boolean;
    unavailable: boolean;
}

interface Slot {
    uuid: string | null;
    pill: string;
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED';
    hourOfDay: number;
    available: boolean;
    reserved: boolean;
    unavailable: boolean;
    connectorUuid: string;
}

interface Connector {
    uuid: string;
    connectorType: string;
    connectorTypeAsString: string;
    rateKW: number;
    powerType: 'DC' | 'AC' | 'SINGLE_PHASE' | 'THREE_PHASE';
    statusAsString: string;
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED';
    available: boolean;
    price: number | null;
    tethered: boolean;
    slots: Slot[];
    ocppConnectorId: number;
    reservedByElectricVehicleDriverUuid: string | null;
    currentStatus: string | null;
    ccs: boolean;
    chademo: boolean;
    type2: boolean;
    charging: boolean;
}

interface Properties {
    uuid: string;
    label: string;
    connectorString: string;
    businessUuid: string;
    accountUuid: string;
    connectors: Connector[];
    chargingPointRawRoute: any; // Adjust the type based on actual data structure
    destinationRawRoute: any; // Adjust the type based on actual data structure
    connectorUsed: any; // Adjust the type based on actual data structure
    destinationDistance: number;
}

interface Data {
    properties: Properties;
}

interface Cell {
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED';
    originalStatus: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED'; // New property to track original status
    uuid: string | null;
    pill: string;
    connectorUuid: string;
    reserved: boolean;
    available: boolean;
    identifier?: string; // Optional, if you're adding this property dynamically
}

interface HeatmapCellProps {
    cell: Cell;
    onClick: (cell: Cell, chargePointConnectorUuid: string) => void; // Assuming your onClick function takes a Cell object as argument
    chargePointConnectorUuid: string;
}

interface AdvancedBookingMapProps {
    data: any; // Adjust the type according to what 'data' actually is
    labels: any; // Adjust the type according to what 'labels' actually is
    onCellClick: (cell: Cell, chargePointConnectorUuid: string) => void; // Use the correct type for 'cell'
    isCellSelected: (cell: Cell) => boolean;
}

// Assuming the interfaces are defined as you provided

const AdvancedBookingMap: React.FC<AdvancedBookingMapProps> = ({ data, labels, onCellClick, isCellSelected }) => {
    const windowSize = useWindowSize();
    const [rect, setRect] = useState({ width: 0 });
    const [height, setHeight] = useState(0);
    const ref = useCallback((node: any) => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, [windowSize.width]);

    useEffect(() => {
        dynamicHeatBox(rect.width);
    }, [rect.width]);

    const dynamicHeatBox = (width: any) => {
        setHeight((width - 140) / 24);
    };

    const chargePoint = data;
    const connectors = chargePoint.properties.connectors;

    const yLabels2 = connectors.map((connector: Connector) => connector.connectorTypeAsString);

    // Transform each connector's slots into the desired format
    const transformedData2 = connectors.map((connector: Connector) =>
        connector.slots.map((slot: Slot) => ({
            status: slot.status,
            uuid: slot.uuid,
            pill: slot.pill,
            hourOfDay: slot.hourOfDay,
            available: slot.available,
            reserved: slot.reserved,
            unavailable: slot.unavailable,
            connectorUuid: connector.uuid,
            originalStatus: slot.status
        }))
    );


    const HeatmapCell = ({ cell, onClick, chargePointConnectorUuid }: HeatmapCellProps) => {
        // Check if the cell is currently selected
        const isSelected = isCellSelected(cell);
    
        // Determine the background color based on the cell's selection state and original status
        const backgroundColor = (() => {
            if (isSelected) {
                // If cell is selected and its original status was AVAILABLE, use the SELECTED color
                if (cell.originalStatus === 'AVAILABLE') {
                    return getCellBackground('RESERVED'); // Assuming 'SELECTED' is defined in getCellBackground
                }
                // If cell is selected and its original status was RESERVED, use the AVAILABLE color
                else if (cell.originalStatus === 'RESERVED') {
                    return getCellBackground('AVAILABLE');
                }
            }
            // For non-selected cells or other conditions, use the color based on the original status
            return getCellBackground(cell.originalStatus || cell.status);
        })();
    
        return (
            <div
                onClick={() => onClick(cell, chargePointConnectorUuid)}
                style={{
                    width: '56px',
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px',
                    margin: '1px',
                    borderRadius: '8px',
                    background: backgroundColor,
                    color: cell.status === 'AVAILABLE' || cell.status === 'RESERVED' ? 'black' : 'white',
                    cursor: cell.status !== 'UNAVAILABLE' ? 'pointer' : 'not-allowed',
                }}
            >
                {cell.pill}
            </div>
        );
    };
    
    // Function to determine cell background color
    const getCellBackground = (status: 'AVAILABLE' | 'UNAVAILABLE' | 'RESERVED') => {

        switch (status) {
            case 'AVAILABLE':
                return '#ABB28D';
            case 'UNAVAILABLE':
                return '#006B6A';
            case 'RESERVED':
                return '#BEF213';
            // case 'SELECTED':
            //     return '#FFE038';
            default:
                return 'rgba(255, 255, 255, 1.0)';
        }
    };


    return (
        <div
            style={{
                width: "100%",
                boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)",
                padding: "20px",
                borderRadius: 16,
                display: "flex",
                flexDirection: "column",
                gap: "8px",
            }}
            ref={ref}
        >
            {chargePoint.properties.label}
            {transformedData2.map((row: any, rowIndex: any) => (
                <div key={rowIndex} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        marginRight: "10px",
                        fontWeight: "normal",
                        width: "160px"
                    }}>{yLabels2[rowIndex]}</div> {/* This is the y-label for each row */}
                    {row.map((cell: any, cellIndex: number, chargePointConnectorUuid: string) => (
                        <HeatmapCell key={cellIndex} cell={cell} onClick={onCellClick} chargePointConnectorUuid={chargePointConnectorUuid}/>
                    ))}
                </div>
            ))}
            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: 24, marginTop: 16, marginBottom: 8}}>
                <div style={{ display: "flex", gap: 8 }}>
                    <div style={{ width: 48, height: 24, backgroundColor: "#ABB28D" }}></div>
                    <span>AVAILABLE</span>
                </div>
                <div style={{ display: "flex", gap: 8 }}>
                    <div style={{ width: 48, height: 24, backgroundColor: "#BEF213" }}></div>
                    <span> RESERVED</span>
                </div>
                <div style={{ display: "flex", gap: 8 }}>
                    <div style={{ width: 48, height: 24, backgroundColor: "#006B6A" }}></div>
                    <span> UNAVAILABLE</span>
                </div>
                {/* <div style={{ display: "flex", gap: 8 }}>
                    <div style={{ width: 48, height: 24, backgroundColor: "#FFE038" }}></div>
                    <span> SELECTED</span>
                </div> */}

                {/* <div>
                    <span style={{ fontWeight: "bold" }}>Note : </span>
                    The number on each cell represent current parking vehicle.
                </div> */}
            </div>

            {/* Additional components and legends can be added here */}
        </div>
    );
}

export default AdvancedBookingMap;
