import React from 'react';
import './Breadcrumbs.css';

interface BreadcrumbProps {
  currentStep: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentStep }) => {
    const steps = ['Vehicles', 'Locations', 'Summary', 'Quotes'];

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {steps.map((step, index) => (
          <span
            key={index}
            className={`breadcrumb__step ${
              currentStep === step ? 'breadcrumb__step--active' : ''
            }`}
            aria-current={currentStep === step ? 'page' : undefined}
          >
            {currentStep === step ? (
              step
            ) : (
              <span>{step}</span>
            )}
          </span>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
