import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChargingPartnerTable from "../../components/charging/ChargingPartnerComponent/ChargingPartnerTable";
import PowerPurchaseAgreementTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementTable";

function PowerPurchaseAgreementListPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [agreements, setAgreements] = useState() as any;
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("All");
    const navigate = useNavigate();

    const fetchAgreements = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/cpo/ppa/agreements/get`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        setAgreements(res_json.agreements?.map((e: any) => ({ ...e, id: e.uuid, businessName: e.businessName, businessUuid: e.businessUuid, rateCardName: e.rateCardName, ppaPricePerKWh: e.ppaPricePerKWh, startDate: e.startDate, numberOfVehicles: e.numberOfVehicles, monthlyCommitmentInKWh: e.monthlyCommitmentInKWh, currentBalance: e.currentBalance})));

        setLoading(false);
    };



    const submitChargingPartner = async (chargingPartnerUuid: string, chargingPartnerBusinessName: string, rowId: any, workflowState: string, businessCategory: string, canOfferCharging: boolean, canRequestCharging: boolean, partnershipRequestType: string) => {

        navigate("/share/business/charging-partners/request", {
            state: {
                chargingPartnerUuid: chargingPartnerUuid,
                chargingPartnerBusinessName: chargingPartnerBusinessName,
                businessCategory: businessCategory,
                canOfferCharging: canOfferCharging,
                canRequestCharging: canRequestCharging,
                workflowState: workflowState,
                partnershipRequestType: partnershipRequestType,
            },
        })
    };

    useEffect(() => {
        fetchAgreements();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <PowerPurchaseAgreementTable agreements={agreements} />
                </div>
            )}
        </>
    );
}

export default PowerPurchaseAgreementListPage;
