// Popup.tsx
import React from 'react';

interface PopupProps {
  onClose: () => void;
  children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ onClose, children }) => {
  return (
    <div style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000 }}>
      <button onClick={onClose}>Close</button>
      {children}
      <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card">
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Charging Cost at  locations per kWh (Peak)
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Single Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data"></span>
                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            AC Three Phase :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data"></span>

                                        </div>
                                    </div>
                                    <div className="box-info">
                                        <span
                                            className="item"
                                            style={{ width: 300 }}>
                                            DC :
                                        </span>
                                        <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 120 }}>
                                            <span style={{ marginLeft: 10, marginRight: 8 }}>£</span>

                                            <span className="data"></span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

    </div>
  );
};

export default Popup;
