import React from "react";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";
import "./VerticalStackedChart.scss";

function VerticalStackedChart(props: any) {
  let data = props.data.map((elem: any) => ({ ...elem, endOfDayCharge: +elem.endOfDayCharge, homeCharge: +elem.homeCharge, startingBatteryLevel: elem.startingBatteryLevel - +elem.homeCharge }));
  //   const test_data = [
  //     {
  //       date: "20211012",
  //       endOfDayCharge: 0.14,
  //       homeCharge: 0.86,
  //       enRouteCharge: false,
  //       startingBatteryLevel: 0.14,
  //     },
  //   ];
  const [selectedJourney, setSelectedJourney] = props.selectedJourney;
  const [selectedDistance, setSelectedDistance] = props.selectedDistance;

  return (
    <div className="multi-color-container">
      <h2 style={{ fontSize: 25, fontWeight: "bold" }}>Battery Usage Profile</h2>
      <ResponsiveContainer
        width={"100%"}
        height={"100%"}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={(e: any) => {
            setSelectedDistance(e.activePayload[0].payload.distance);
            setSelectedJourney(e.activePayload[0].payload.date.toString());
        }}>
          {/* <XAxis
            axisLine={false}
            tickLine={false}
          /> */}
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="endOfDayCharge"
            stackId="a"
            fill="#BEF213"
            isAnimationActive={false}
          />
          <Bar
            dataKey="homeCharge"
            stackId="a"
            fill="#C1D1D6"
            isAnimationActive={false}
          />
          {/* <Bar dataKey="startingBatteryLevel" stackId="a" fill="#C1D1D6" /> */}
          <Legend
            iconType="circle"
            formatter={(value, entry, index) => {
              switch (value) {
                case "homeCharge":
                  return "Home Charge";
                case "endOfDayCharge":
                  return "Remaining Charge Level";
              }
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VerticalStackedChart;
