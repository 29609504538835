import Modal from 'react-modal';
function ModalComponent(props: any) {
    const [modalIsOpen, setIsOpen] = props.modalIsOpen;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            fontFamily: 'Poppins , sans-serif',
            transform: 'translate(-50%, -50%)',
            minWidth: '500px',
            borderWidth: 0,
            borderRadius: 20,
            paddingBottom: 80,
            boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)",
             // Adjust this value as needed

        },
    };
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            appElement={document.getElementById('root') || undefined}
            contentLabel="Example Modal"
            style={customStyles}
        >
            {props.children}
        </Modal>
    );
}

export default ModalComponent;