import UserContext from "Context/UserProvider";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UnauthenticatedSurveyPage from "pages/SurveyPage/UnauthenticatedSurveyPage";
import SurveyPage from "pages/SurveyPage/UnauthenticatedSurveyPage";
import React, { useContext, useEffect, useState } from "react";
import { FaGalacticSenate } from "react-icons/fa";
import BackButton from "../../components/common/SiteTemplate/BackButton/BackButton";
import SiteAccessPolicyTable from "../../components/charging/SiteAccessPolicyComponent/SiteAccessPolicyTable";

function SiteAccessPolicyListPage(props: any) {
  const [showCategory, setShowCategory] = useState([true, false, false]) as any;
  const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false, false]);
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [surveys, setSurveys] = useState() as any;
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState("All");

  const fetchSurveys = async () => {
    let bodyData: { [name: string]: string } = {};

    bodyData["uuid"] = userInfo.uuid;
    bodyData["businessUuid"] = userInfo.businessUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/site-access-policy/list`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();

    setSurveys(res_json.surveys.map((e: any) => ({ ...e, id: e.uuid, name: e.name, scope: e.scope })));
    setLoading(false);
  };

  useEffect(() => {
    fetchSurveys();
  }, []);
  
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
         
          {showCategory[0] && (
            <>
              {/* <h1>Site Access Policies</h1>
              <br /> */}
              <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                  className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                  onClick={() => (setShowSecondaryCategory([true, false, false]), setActiveMenu("All"))}>
                  All
                </button>
               </div>
              <br />
              {showSecondaryCategory[0] && (
                <>
                  <SiteAccessPolicyTable surveys={surveys} />
                </>
              )}

            </>
          )}
          {/* {showCategory[1] &&
                <>
                    <br />
                    <h1>Email Address</h1>
                    <br />
                    <SurveyEmailTable />
                </>
            } */}
        </div>
      )}
    </>
  );
}

export default SiteAccessPolicyListPage;
