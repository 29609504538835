import JourneyNav from '../../components/homepage/HomePageComponent/Nav';

function PowerPurchaseAgreementPublicSuccessPage(props: any) {



    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
                <JourneyNav fixed="fixed-top" homePage={true} />
                <div className="contact-container" style={{ marginTop: "200px" }}>
                    <div>
                        <div className="container-fluid g-0">
                            <div className="row gy-2 justify-content-center">
                                <div className="col-xs-6 col-md-6 col-xl-6">
                                    <div className="dashboard-card" style={{padding: "24px"}}>
                                        Thank you for submitting a Power Purchase Agreement Quote.
                                        <br/>
                                        <br/>
                                        We will be in touch shortly to discuss options

                                    </div>
                                </div>
                            </div >
                        </div>
                        <br />

                    </div>
                </div>
            </div>
        </>
    );
}

export default PowerPurchaseAgreementPublicSuccessPage;