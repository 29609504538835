export default function exportLocation(userInfo: any) {
    let bodyData: { [name: string]: string } = {}
    bodyData['uuid'] = userInfo.uuid
    bodyData['fleetUuid'] = userInfo.fleetUuid
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
    let fetch_link = `${rootUrl}/infrastructure/dwelling/list/export`
    fetch(fetch_link, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
            'cache': "no-cache",
            'pragma': "no-cache",
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json().then((res_obj) => {
        const link = document.createElement('a');
        link.href = res_obj.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    ))
    return
}