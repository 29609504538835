import { Stack } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function BusinessLocationsTable(props: any) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "addressLine",
      headerName: "Location",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (cell) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/plan/business/location/charge-points/list", {
                state: {
                  dwellingUuid: cell.row.uuid,
                  fleetDwellingName: cell.row.dwellingName,
                },
              })
            }>
            {cell.row.addressLine}
          </span>
        );
      },
    },

    {
      field: "countFastChargers",
      headerName: "Fast Charging Units",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "substationName",
      headerName: "Fast Charging Cost",
      sortable: false,
      width: 150,
      align: "left",
      headerAlign: "center",
    },

    {
      field: "countRapidChargers",
      headerName: "Rapid Charging Units",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "substationRange",
      headerName: "Rapid Charging Cost",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "center",
    },
  ];

  return (
    <div className="table-box">
      <h1>Business Locations</h1>
      <br />
      <DataGrid
        rows={props.data}
        width={"100%"}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              <span>No Infrastructure Locations exist.</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center">
              Selected filter returns no result
            </Stack>
          ),
        }}
       fontFamily={"Arvo"}></DataGrid>
    </div>
  );
}

export default BusinessLocationsTable;
