import VirtualChargingHubsTable from "components/business/InfrastructureComponent/VirtualChargingHubsTable";
import HomeLocationsTable from "components/business/InfrastructureComponent/HomeLocationsTable";
import OpportunityLocationsTable from "components/business/InfrastructureComponent/OpportunityLocationsTable";
import UserContext from "Context/UserProvider";
import { useLocation } from "react-router";
import React, { useContext, useEffect, useState } from "react";
import Map from "../../components/common/MapComponent/Map";
import ChargePointListTable from "components/charging/ChargePointList/ChargePointListTable";

function isNull(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function LocationChargePointsListPage(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [chargePoints, setChargePoints] = useState([]) as any;
    const [virtualChargingHubsData, setVirtualChargingHubsData] = useState([]) as any;
    const [homeLocationsData, setHomeLocationsData] = useState([]) as any;
    const [opportunityLocationsData, setOpportunityLocationsData] = useState([]) as any;
    const [showCategory, setShowCategory] = useState([true, false, false, false]);
    const [activeMenu, setActiveMenu] = useState("All");
    const location = useLocation() as any;
    const dwellingUuid = location.state.dwellingUuid;

    const [insightData, setInsightData] = useState({
        partnerLocations: []
    });
    const [enableButton, setEnableButton] = useState(false);

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }


    async function fetchChargePoints() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["dwellingUuid"] = dwellingUuid;

        console.log(userInfo.uuid);
        console.log(userInfo.businessUuid);
        console.log(dwellingUuid);

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/cpo/charge-points/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.chargePoints = checking_null_undefined(res_json.chargePoints);
        console.log(res_json.chargePoints);

        res_json.virtualChargingHubs = checking_null_undefined(res_json.virtualChargingHubs);
        res_json.homeLocations = checking_null_undefined(res_json.homeLocations);
        res_json.opportunityLocations = checking_null_undefined(res_json.opportunityLocations);
        res_json.companyDwellings = isNull(res_json.companyDwellings);

        setChargePoints(res_json.chargePoints.map((e: any) => ({ ...e, id: e.uuid })));
        setVirtualChargingHubsData(res_json.virtualChargingHubs.map((e: any) => ({ ...e, id: e.uuid })));
        setHomeLocationsData(res_json.homeLocations.map((e: any) => ({ ...e, id: e.uuid, nearestPublicChargingDistance: e.nearestPublicCharging != null ? e.nearestPublicChargingDistance < 0.1 ? "< 0.1 " + userInfo.userProfile.distanceUnitShortDisplayName : parseInt(e.nearestPublicChargingDistance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName : "" })));
        setOpportunityLocationsData(res_json.opportunityLocations.map((e: any) => ({ ...e, id: e.uuid, minDistance: e.minDistance != null ? e.minDistance < 1 ? "< 1 " + userInfo.userProfile.distanceUnitShortDisplayName : parseInt(e.minDistance).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName : "", totalTimeInMins: parseInt(e.totalTimeInMins).toLocaleString() + " mins", averageDwellTime: parseInt(e.averageDwellTime).toLocaleString() + " mins" })));
        setCompanyDwellings(res_json);

        setLoading(false);
    }

    function setCompanyDwellings(obj: any) {

        let company_map_data = isNull(obj.companyDwellings);

        setInsightData({
            partnerLocations: company_map_data,

        });
    }


    useEffect(() => {
        fetchChargePoints();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                    onClick={() => (setShowCategory([true, false, false, false, false, false]), setActiveMenu("All"))}>
                    All
                </button>
                <div className="vl"></div>
            </div>
            {showCategory[0] && (
                <>
                    { }
                    {chargePoints.map((chargePoint: any, index: any) => (
                        <ChargePointListTable key={index} chargePointData={chargePoint} />
                    ))}

                </>
            )}
 
        </div>
    );
}

export default LocationChargePointsListPage;
