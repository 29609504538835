import LoaderModal from "components/common/Modal/LoaderModal";
import ModalComponent from "components/common/Modal/Modal";
import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { GiConsoleController } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { Navigate, useLocation, useNavigate } from "react-router";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import Select from "react-select";

function reducer(state: any, action: any) {
    switch (action.input) {
        case "email":
            return { ...state, email: action.value };
        case "fullName":
            return { ...state, fullName: action.value };
        case "password":
            return { ...state, password: action.value };
        case "businessOptions":
            return { ...state, businessOptions: action.value };
        case "userRoleOptions":
            return { ...state, userRoleOptions: action.value };
        default:
            return state;
    }
}

function AddUserPage(props: any) {
    //   const businesses = props.businesses;
    const [options, setOptions] = useState([]) as any;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [toggle, setToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("There was an error during creating user.");
    const location = useLocation() as any;

    const [userRoleOptions, setUserRoleOptions] = useState({ label: "None", value: "NONE", name: "userRoleOptions" }) as any;
    const [userRoleValue, setUserRoleValue] = useState([]) as any;

    const initialState = {
        email: "",
        fullName: "",
        password: "",
        businessOptions: [],
        userRoleOptions: [],
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    function onChange(e: any) {

        if (e.name === "userRoleOptions") {


            const selectedUserRole = userRoleOptions.find((i: any) => i === e);

            if (selectedUserRole != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedUserRole.name, // assuming it has a 'name' field for the label
                    value: selectedUserRole.uuid, // the unique identifier, used as value
                    ...selectedUserRole, // spread the rest of the object properties if there are any others
                };
                setUserRoleValue(selectedValue);

            }
        } else {

            let action: any;

            if (e.target) {
                action = {
                    input: e.target.name,
                    value: e.target.value,
                };
            } else {
                action = {
                    input: e[0]?.name,
                    value: e.map((item: any) => {
                        return item.value;
                    }),
                };
            }

            dispatch(action);
        }
    }

    function toggleButton() {
        setToggle((prev) => !prev);
    }
    async function fetchBusinessInfo() {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["access_token"] = userInfo.access_token;
        bodyData["accountUuid"] = userInfo.accountUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/business/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setError(true);
            return;
        }
        let res_json = await response.json();
        const options = res_json.businesses.map((e: any) => ({
            label: e.name,
            value: e.uuid,
            name: "businessOptions",
        }));

        const userRoleOptions = res_json.roles.map((e: any) => ({
            label: e,
            value: e,
            name: "userRoleOptions",
        }));

        setUserRoleOptions(userRoleOptions);

        // const selectedSurvey = res_json.userRoles.find((i: any) => i.uuid === res_json.business.selectedSiteAccessPolicyUuid);

        // if (selectedSurvey != null) {
        //     // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
        //     const selectedValue = {
        //         label: selectedSurvey.name, // assuming it has a 'name' field for the label
        //         value: selectedSurvey.uuid, // the unique identifier, used as value
        //         ...selectedSurvey, // spread the rest of the object properties if there are any others
        //     };
        //     setSiteAccessPolicyValue(selectedValue);

        // } else {
        //     const selectedValue = {
        //         label: "", // assuming it has a 'name' field for the label
        //         value: "", // the unique identifier, used as value
        //     };
        //     setSiteAccessPolicyValue(selectedValue);
        // }



        setOptions(options);
        setLoading(false);
    }

    useEffect(() => {
        fetchBusinessInfo();
    }, []);

    const submit = async () => {
        // const validationStatus = validateForm()
        // if (!validationStatus) {
        //     return
        // }
        setSubmitLoading(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        bodyData["businessUuids"] = state.businessOptions;
        bodyData["emailAddress"] = state.email;
        bodyData["password"] = state.password;
        bodyData["fullName"] = state.fullName;
        bodyData["userRole"] = userRoleValue.value;
        // bodyData["makeActiveOnceCreated"] = toggle;
        // bodyData['survey'] = state
        // bodyData['surveyUuid'] = splitURL[3]

        let fetch_link = `${rootUrl}/account/users/create`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();
        if (response.status !== 200) {
            switch (res_json.code) {
                case "REASON_USER_ALREADY_EXISTS":
                    setErrorMessage("User already exists");
                    break;
                case "REASON_PASSWORD_TOO_WEAK":
                    setErrorMessage("Your input password is too weak");
                    break;
                case "BUSINESS_ROLES_CAN_ONLY_HAVE_ONE_BUSINESS":
                    setErrorMessage("Business user roles must be allocated to a single business");
                    break;
                default:
                    break;
            }
            setError(true);
            openModal();
            setSubmitLoading(false);
            return;
        } else {

            // if (toggle) {
            //   setUserInfo((prev: any) => ({
            //     ...prev,
            //     businessUuid: state.businessUuid,
            //     fleetUuid: res_json.fleet.uuid,
            //     currentfullName: res_json.fleet.name,
            //   }));
            //   navigate("/plan");
            // }
            dispatch({
                input: "fullName",
                value: "",
            });
            dispatch({
                input: "password",
                value: "",
            });
            dispatch({
                input: "email",
                value: "",
            });
            setSubmitLoading(false);
            setErrorMessage("User Created.");
            openModal();
        }
    };

    return (
        <>
            {loading ? (
                <>Loading...</>
            ) : (
                <div
                    className="col-xs-12 col-md-6 col-xl-4 "
                    style={{ width: 350 }}>
                    {submitLoading && <LoaderModal />}
                    <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                        <>
                            <div
                                onClick={() => {
                                    closeModal();
                                }}
                                style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IoMdClose
                                    size={30}
                                />
                            </div>
                            {isError ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        margin: 10,
                                        padding: 50,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 30,
                                    }}>
                                    <h1>{errorMessage}</h1>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        margin: 10,
                                        padding: 50,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 30,
                                    }}>
                                    <h1>User created.</h1>
                                </div>
                            )}
                        </>
                    </ModalComponent>

                    <div className="inputGroup">
                        <input
                            type="text"
                            required={true}
                            onChange={onChange}
                            autoComplete="off"
                            name="fullName"
                            id="fullName"
                        />
                        <label>Full Name</label>
                    </div>
                    <br />
                    <div className="inputGroup">
                        <input
                            type="text"
                            required={true}
                            onChange={onChange}
                            autoComplete="off"
                            name="email"
                            id="email"
                        />
                        <label>Email Address</label>
                    </div>
                    <br />
                    <div className="inputGroup">
                        <input
                            type="text"
                            required={true}
                            onChange={onChange}
                            autoComplete="off"
                            name="password"
                            id="password"
                        />
                        <label>Password</label>
                    </div>
                    <br />
                    <span>Choose Business (Optional):</span>
                    <br />
                    <br />
                    <Select
                        // defaultValue={options}
                        onChange={onChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        name="chargingFacility"
                        // menuPosition="fixed"
                        options={options}
                    // styles={colourStyles}
                    />
                    <br />
                    <br />
                    <span>Choose User Role:</span>
                    <br />
                    <br />
                    <Select
                        value={userRoleValue}
                        name="userRoleOptions"
                        onChange={onChange}
                        options={userRoleOptions}
                    />

                    <br />
                    {/* <label
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <span>Mark as active</span>
            <Switch
              onChange={toggleButton}
              checked={toggle}
              name={"markActive"}
            />
          </label> */}
                    {/* <br /> */}
                    <button
                        className="primary-btn"
                        style={{ width: 200 }}
                        onClick={() => {
                            submit();
                        }}>
                        Create User
                    </button>
                </div>
            )}
        </>
    );
}

export default AddUserPage;
