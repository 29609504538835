import auth0 from "auth0-js";
import { resolve } from "path";
import auth0Service from "./Auth0Service";
import webAuth from "./WebAuth";

export default class AuthService {
  public static async login(username: string, password: string, setErrorMessage: any, rememberMe: boolean) {
    webAuth.client.login(
      {
        realm: `${process.env.REACT_APP_AUTH0_REALM}`,
        username: username,
        password: password,
      },
      (err, result) => {
        if (err) {
          setErrorMessage(err.description);
          return;
        }
        auth0Service.handleAuthentication(result, rememberMe);
        return;
      }
    );
  }
  public static isAuthenticated(rememberMe: boolean) {
    let access_token;
    let expires_at;

    if (rememberMe) {
      access_token = localStorage.getItem("access_token") as string;
      expires_at = parseInt(localStorage.getItem("expires_at") as string);
    } else {
      access_token = sessionStorage.getItem("access_token") as string;
      expires_at = parseInt(sessionStorage.getItem("expires_at") as string);
    }
    if (expires_at > Date.now()) {
      if (access_token) {
        return true;
      }
      return false;
    }
    return false;
  }
  public static logout() {
    sessionStorage.clear();
    localStorage.clear();
    document.location.href = `${document.location.origin + "/login"}`;
  }
}

// const authenticate = async (result) => {
//   auth0Service.handleAuthentication(result);
//   await auth0Service.setUserProfile(result.accessToken, result.idToken, dispatch);
// };
