import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChargingPartnerTable from "../../components/charging/ChargingPartnerComponent/ChargingPartnerTable";

function ChargingPartnerListPage(props: any) {
    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [chargingPartners, setChargingPartners] = useState() as any;
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState("All");
    const navigate = useNavigate();

    const fetchChargingPartners = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/charging-partner/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        setChargingPartners(res_json.chargingPartners.map((e: any) => ({ ...e, id: (e.consumerChargingPartnerUuid === null ? e.supplierChargingPartnerUuid : e.consumerChargingPartnerUuid), chargingPartnerBusinessName: e.name, workflowState: e.supplierWorkflowState, visitorWorkflowState: e.consumerWorkflowState, partnerUuid: null, rateCard: null, chargingPartnerBusinessUuid: e.partnerBusinessUuid, businessUuid: e.businessUuid, businessCategory: e.businessCategory, canOfferCharging: e.canOfferCharging, canRequestCharging: e.canRequestCharging, chargingPartnerBusinessCategory: e.partnerCategory })));

        setLoading(false);
    };

    

    const requestPartnership = async (chargingPartnerUuid: string, chargingPartnerBusinessName: string, rowId: any, workflowState: string, businessCategory: string, canOfferCharging: boolean, canRequestCharging: boolean, partnershipRequestType: string, businessUuid: string, chargingPartnerBusinessUuid: string) => {

        navigate("/share/business/charging-partners/request", {
            state: {
                chargingPartnerUuid: chargingPartnerUuid,
                businessUuid: businessUuid,
                chargingPartnerBusinessUuid: chargingPartnerBusinessUuid,
                chargingPartnerBusinessName: chargingPartnerBusinessName,
                businessCategory: businessCategory,
                canOfferCharging: canOfferCharging,
                canRequestCharging: canRequestCharging,
                workflowState: workflowState,
                partnershipRequestType: partnershipRequestType,
            },
        })
    };

    useEffect(() => {
        fetchChargingPartners();
    }, []);
    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>

                    {showCategory[0] && (
                        <>
                            {/* <h1>Charging Partners</h1>
                            <br /> */}
                            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                <div className="vl"></div>
                                <button
                                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                                    onClick={() => (setShowSecondaryCategory([true, false]), setActiveMenu("All"))}>
                                    All
                                </button>
                                <div className="vl">
                                </div>
                            </div>
                            <br />
                            {showSecondaryCategory[0] && (
                                <>
                                    <ChargingPartnerTable chargingPartners={chargingPartners} requestPartnership={requestPartnership}/>
                                </>
                            )}


                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default ChargingPartnerListPage;
