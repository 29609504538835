import UserContext from "Context/UserProvider";
import { useContext } from "react";
import "./RegisterBusinessName.scss";
export default function RegisterBusinessName(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);

  const getClientId = () => {
    const storage: any = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::api-dev.evata.co.uk::openid profile email`) as string);
    return [storage.body.decodedToken.user.sub, storage.body.access_token];
  };

  const submitBusinessName = () => {
    let businessInput = document.getElementById("business-name") as HTMLInputElement;
    let businessName = (businessInput as HTMLInputElement)!.value as string;
    let fleetInput = document.getElementById("fleet-name") as HTMLInputElement;
    let fleetNameInput = (fleetInput as HTMLInputElement)!.value as string;
    let bodyData: { [name: string]: string } = {};
    bodyData["businessName"] = businessName;
    bodyData["fleetName"] = fleetNameInput;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["uuid"] = userInfo.uuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/user_auth/register`;
    fetch(fetch_link, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    }).then(async (res) => {
      let data = await res.json();
      window.location.reload();
    });
  };
  const paragraph =
      <p>
        <br />
        Thank you for choosing Evata.
        <br />
        <br />
        We have registered your details and your account is being set up.
        <br />
        <br />
        We'll be in touch soon to complete the process.
          </p>
  return (
    
        <>
          <h2 className="h2">Registration</h2>
          {paragraph}
    </>
  );
}
