import React from 'react';
import { Outlet } from 'react-router-dom';

function UnProtectedRouteLayout(props: any) {
    return (
        <div className='site-container'>
            <Outlet />
        </div>
    );
}

export default UnProtectedRouteLayout;