import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function Bookings(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    function cutString(str: string) {
        if (str.length > 10) {
            return str.substring(0, 10) + "...";
        } else {
            return str;
        }
    }


    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <span className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">electric_car</span>
                    Upcoming Outbound Bookings{" "}
                </span>
                <div className="dashboard-card-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>VRN</th>
                                <th>Business Name</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dashboardInfo.reservations?.map((reservation: any, index: number) => (
                                <tr className="data" key={index}>
                                    {/* Uncomment and modify the onClick handler as needed */}
                                    {/* onClick={() => selectFleet(reservation.uuid, reservation.name, reservation.businessName, reservation.businessUuid)} */}
                                    <td>{reservation.reservationDate}</td>
                                    <td>{reservation.vrn}</td>
                                    <td>{cutString(reservation.businessName)}</td>
                                    <td>{`${reservation.startHour}:00 to ${reservation.endHour}:00`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="dashboard-card-actions">
                </div>
            </div>
        </div>
    )
}
export default Bookings;
