import React from 'react';
import Footer from '../../components/homepage/HomePageComponent/Footer';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import './PrivacyPolicy.scss'

function PrivacyPolicy(props: any) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className="privacy-policy-title" >
                <div>
                    <span style={{ fontSize: '2rem' }}>Evata Privacy and Cookie Policy</span>
                    <div className='horizontal_line'></div>
                </div>
            </div>
            <div style={{ maxWidth: 1600, display: 'flex', flexDirection: 'column', padding: 20 }}>
                <p><b>Evata respects your privacy. All information you give us is held with the utmost care and security. The Evata privacy policy explains how we collect and use your personal information.</b></p>
                <br />
                <p>
                    Please take time to review this privacy policy as it sets out our privacy practices for www.evata.co.uk
                    and tells you how your personal information will be treated by us. This privacy policy relates to customer prospects (meaning here anyone who is considering purchasing products and services from Evata). If you are an Evata customer, please review our Customer Privacy Notice.
                </p>
                <br />
                <p>Any questions regarding Evata’s privacy policy should be directed to Legal@evata.co.uk or alternatively, click on <a href="/contact">here</a>.</p>
                <br />
                <p><b>WHAT INFORMATION DO WE COLLECT?</b></p>

                <br />
                <p>Evata will collect personal information provided by you when you contact us, make an enquiry, request a brochure, request a demo, phone us, email us or visit our website.</p>
                <br />
                <p>We collect: name (first and second), company name, postal address, email address, phone number, any other contact details provided by you, details of website pages you visited, features and product usage you might be interested in. </p>
                <br />
                <p>Through surveys or when speaking to our staff over the phone or email, we may also ask you for other optional information such as what sort of equipment/device/software you use and what subjects related to vehicle tracking and telematics interest you. If you choose to give us this information, we will use it to help us to provide you with the best possible service that is personalized to your needs and preferences.</p>
                <br />
                <p>Although we do not make it compulsory to give us every item of information we ask for, the more information volunteered by our prospect customers (and the more accurate it is), the better we can match our services to your needs.</p>
                <br />
                <p>We also collect information from you if you choose to take part in any of our website surveys or promotions. We will use this information to administer the promotion, help us to plan other promotions and improve the services we provide.</p>
                <br />
                <p><b>DO WE RECORD PHONE CALLS?</b></p>
                <br />
                <p>Phone calls may be recorded for quality and training purposes. </p>
                <br />
                <p><b>WHAT DO WE USE YOUR INFORMATION FOR?</b></p>
                <br />
                <p>We hold the details you supply so that we can contact you to check on the information we’ve sent you or the progress of an enquiry or demo evaluation of our services. If you do not want us to hold your information or contact you, please let us know by completing the ‘Contact Us‘ form and also mentioning this in the ‘Comments Box’. Also, if you don’t want us to speak to anyone else in your organisation about your enquiry (for reasons of confidentiality), let us know in the same way. Please note that in order to avoid contacting you again, we do hold your email address as a ‘Do not email’ record on a suppression list, which is the most effective and safest way to prevent you from receiving further emails. </p>
                <br />
                <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</p>
                <br />
                <p>We (or our representatives) may use the personal information provided to us to:</p>
                <br />

                <ol style={{ marginLeft: 50 }}>
                    <li><p>process enquiries submitted by you;</p></li>
                    <br />
                    <li><p>customise the way we market the services we provide to you;</p></li>
                    <br />
                    <li><p>verify your identity; (e.g. if you participate in any promotions administered by us)</p></li>
                    <br />
                    <li><p>carry out market research and surveys and track sales data;</p></li>
                    <br />
                    <li><p>send you our newsletter, where this is requested by you;</p></li>
                    <br />
                    <li><p>track your engagement with us, including through our website, articles and newsletters;</p></li>
                    <br />
                    <li><p>understand how you use our website;</p></li>
                    <br />
                    <li><p>contact you by telephone and send you follow-up communications relating to your use of Evata’s software promoted on our website; and</p></li>
                    <br />
                    <li><p>send you information about our other products and services.</p></li>
                </ol>
                <br />
                <p><b>DO WE LOG IP ADDRESSES?</b></p>
                <br />
                <p>Yes, when you visit our website, we automatically log your IP address which is recognised by our web server. We use IP addresses to help us administer the website, to collect broad demographic information for aggregate use and to track your engagement with us.</p>
                <br />
                <p><b>DO WE USE COOKIES?</b></p>
                <br />
                <p>Cookies are very small text files which are used to store data as you move around a website: your login details, what language preferences, etc. They are created by the website and sit in your browser until they expire.</p>
                <br />
                <p>There are three types of cookies: Session cookies, First-party persistent cookies and Third-party persistent cookies.</p>
                <br />
                <p><b>Session cookies </b>only exist in temporary memory and are deleted when you close the browser. Any cookie which does not have an explicit expiry date is automatically set as a session cookie. They are only used by the website that created them during your current visit.</p>
                <br />
                <p><b>First-party persistent cookies </b>are written onto your device’s memory and come with an expiration date. They are only used by the website that created them – during both your current visit and also future visits – and are generally used for functionality enhancements.</p>
                <br />
                <p><b>Third-party persistent cookies, </b>also known as Tracking cookies, are also stored in your device’s memory and have a set expiration date. However, they can be retrieved by third-party services and used to build a profile of viewing habits and to show targeted advertisements. </p>
                <br />
                <p>We have classified the cookies that we use on the site into three categories,Essential, Legitimate Interest and Analytics, based on how they are used.</p>
                <br />
                <p><b>Essential </b>cookies are required to use the site without disruption. These cookies are either <b>Session cookies </b> and <b>First-party persistent cookies, </b> as defined above.</p>
                <br />
                <p>Evata uses this type of cookie to remember your country, so that we don’t have to check this every time you reload a page. We also use them to remember that you’ve been shown the site selector, so that you’re not shown this every time you visit the site.</p>
                <br />
                <p><b>Legitimate Interest </b>cookies store anonymous data for us to determine how you found our website. They do not contain any personal data and can not be used to identify anybody.</p>
                <br />
                <p><b>Analytics</b> cookies are not essential to use the site but will provide us with additional data to enhance your experience of our website and our offering as a whole. These cookies are <b>Third-party persistent cookies</b> , as defined above.</p>
                <br />
                <p>Evata use this type of cookie to assess the effectiveness of our website content, our marketing campaigns and to build a profile of our customers as they use our services. We also sometimes use these cookies to display advertisements related to sites you have visited and limit the number of times a particular advert is shown.</p>
                <br />
                <p>These cookies do not allow us to personally identify you and will remain on your device until you delete them manually or automatically.</p>
                <br />

                <p><b>OPT-OUT</b></p>
                <br />
                <p>We allow users to opt out from all Analytics cookies, should they wish to do so. To change your cookie preferences, access your settings using the icon in the bottom left corner of every screen.</p>
                <br />
                <p>We do not allow users to opt-out of Essential cookies as this would make our site unusable.</p>
                <br />

                <p>For further information and directions on how to disable cookies at the browser level, please visit <a href="http://www.allaboutcookies.org/manage-cookies/stop-cookies-installed.html"> here </a></p>
                <br />

                <p>You can opt out of our Google Marketing cookies at <a href="https://tools.google.com/dlpage/gaoptout">here</a>.</p>
                <br />
                <p><b>DO WE DISCLOSE ANY INFORMATION TO OUTSIDE THIRD PARTIES?</b></p>
                <br />
                <p>Certain Evata services, such as assisting with user evaluation of our products, are done in conjunction with our (third-party) business partners. In such cases, we may need to share your personal information with them in order to provide these services, however, we will ask for your permission first. We may also share information with our representatives to send you information or contact you by telephone about our services and offers that we deem would be of interest to you.</p>
                <br />
                <p>We choose our business partners carefully and ask them to comply with all data privacy policies, however, we are not responsible for their privacy policies and practices. Therefore, we recommend that you check the policy of each site you visit and ask anyone who contacts you if you have any concerns.</p>
                <br />

                <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
                <br />
                <p>In some cases, we may ask for anonymous feedback from you via a third party, in order to help us improve our service, we will fully respect your privacy and only if you waive your anonymity; we will be able to contact you about the feedback. </p>
                <br />
                <p>We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or other’s rights, property, or safety.</p>
                <br />
                <p><b>INFORMATION ABOUT OUR PRODUCTS AND SERVICES</b></p>
                <br />
                <p>If you use vehicle as part of your business, then we deem that you might be interested in vehicle tracking services as a way to improve your business operations and save costs. </p>
                <br />
                <p>We will send you information or may contact you by telephone to talk with you about our services and offers that we deem would be of interest to you. </p>
                <br />
                <p>If at any time you do not wish to continue to receive these details, then send an email message to Legal@evata.co.uk. You can also unsubscribe from our emails by using the ‘unsubscribe’ option located at the bottom of each of our newsletter communications. All you need to do is to click on the ‘Unsubscribe from our emails’ link.</p>
                <br />
                <p><b>DATA SUBJECT RIGHTS</b></p>
                <br />
                <p>Individuals have a number of rights with regard to the data held about them, if you would like to exercise any of these rights please contact Evata support as detailed at <a href="/contact">here</a>.</p>
                <br />
                <p>a. Right to be informed</p>
                <br />
                <p>Data subjects have the right to be informed of the personal data held about them, the processing of that personal data and of the lawful the basis of processing and purpose(s) of processing. This privacy notice provides details of the personal data held and processing of that personal data Evata carries out.</p>
                <br />
                <p>b. Right of Access</p>
                <br />
                <p>More commonly known as Subject Access Requests or “SARs”, Data Subjects have the right to request and obtain from information relating to, and to receive a copy of, their Personal Data.</p>
                <br />
                <p>c. Right to Rectification</p>
                <br />
                <p>Data Subjects have the right to obtain the rectification or completion of inaccurate or incomplete Personal Data concerning him or her. Please contact us to correct or complete any data we have about you.</p>
                <br />
                <p>d. Rights to Erasure, Restriction, Data Portability and to Object</p>
                <br />
                <p>In certain circumstances and, in some cases, subject to specific exceptions, Data Subjects have the right to:</p>
                <br />

                <ol style={{ marginLeft: 50 }}>
                    <li><p>Obtain the erasure of Personal Data concerning him or her;</p></li>
                    <br />
                    <li><p>Obtain the restriction of Processing of Personal Data concerning him or her;</p></li>
                    <br />
                    <li><p>Obtain the Personal Data concerning him or her, which he or she has provided to a data controller, to transmit to another data controller without hindrance to have us transfer the personal data directly to another data controller where technically feasible;</p></li>
                    <br />
                    <li><p>Object at any time to Processing carried out in our legitimate interests, or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller or carried out for direct marketing purposes.</p></li>
                </ol>
                <br />
                <p>e. Automated Decision Making</p>
                <br />
                <p>Data Subjects have the right not to be subject to a decision based solely on automated Processing, including profiling, which produces legal or similarly significantly affects concerning him or her.</p>
                <br />
                <p><b>KEEPING OUR RECORDS ACCURATE</b></p>
                <br />
                <p>We aim to keep our information about you as accurate as possible. If you would like to review or change the details you have supplied us with, please contact Legal@evata.co.uk.</p>
                <br />
                <p><b>HOW DO WE PROTECT YOUR INFORMATION?</b></p>
                <br />
                <p>We have implemented technology and policies to safeguard your privacy from unauthorized access and improper use. For example, our website is encrypted. We will continue to monitor and update security measures as new technology and privacy regulations becomes available as appropriate to the website and any customer databases or CRM systems we use. </p>
                <br />
                <p>All supplied sensitive/credit card information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.</p>
                <br />
                <p><b>YOUR CONSENT</b></p>
                <br />
                <p>By using our website, or giving us your details at a trade events or exhibitions, you consent to the collection and use of this information by Evata so that we can process your enquiry. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we would disclose it.</p>
                <br />
                <p>DATA TRANSFER OUTSIDE OF THE UK</p>
                <br />
                <p>Evata will only transfer (‘transfer’ means share data or make the data available to a remote location) Personal Data to countries outside of the EEA where:</p>
                <br />
                <ol style={{ marginLeft: 50 }}>
                    <li><p>The transfer is to a country (or an international organisation), that the European Commission has determined ensures an adequate level of protection;</p></li>
                    <br />
                    <li><p>Standard contractual clauses adopted by the European Commission have been put in place between Evata and the entity located outside the EEA;</p></li>
                    <br />
                    <li><p>•	Legally binding corporate rules have been implemented, where applicable; or the transfer is otherwise permitted by the GDPR.</p></li>
                </ol>
                <br />
                <p><b>CONTACTING US</b></p>
                <br />
                <p>If at any time you would like to contact us with a query relating to your personal information, you can do so by sending an email to Legal@evata.co.uk or by contacting us at:</p>
                <br />
                <p>Evata UK Limited</p>
                <p>10 Allison Street, </p>
                <p>Glasgow,</p>
                <p>G42 8NN</p>
                <br />
                <p>You are entitled to ask for a copy of the information we store about you (for which we may charge a small fee).</p>
                <br />
                <p>Any complaints should be raised with Evata at the email address above in the first instance. Data subjects can raise complaints to the supervisory authority in the UK.  This is the Information Commissioners office – www.ico.org.</p>
                <br />
                <p>Evata has appointed a Data Protection Officer in line with the requirements of the General Data Processing Regulation (GDPR) who can be contacted by email Legal@evata.co.uk.</p>
                <br />
                <p>This policy was last modified on September 4th 2022.</p>
                <br />
                <div className="privacy-policy-second-title" >
                    <div>
                        <span style={{ fontSize: '2rem' }}>Customer Privacy Notice</span>
                        <div className='horizontal_line'></div>
                    </div>
                </div>
                <br />
                <p><b>Customer Notice on GPS Tracking and Privacy</b></p>
                <br />
                <p>This policy notice concerns customers who are using Evata’s data analytics. If you have any questions about this information, please contact Legal@evata.co.uk.</p>
                <br />
                <p><b>What information do we collect?</b></p>
                <br />
                <p>We collect data for the provision of data analytics to assist commercial fleets with transitioning to electric vehicles. This data includes personal data such as the below:</p>
                <br />
                <ol style={{ marginLeft: 50 }}>
                    <li><p>Name</p></li>
                    <br />
                    <li><p>Address(es) – home/ work/ other significant addresses</p></li>
                    <br />
                    <li><p>Telephone number(s)</p></li>
                    <br />
                    <li><p>Email Address(es)</p></li>
                    <br />
                    <li><p>Vehicle Location data from GPS with date and time from the tracking unit</p></li>
                    <br />
                </ol>
                <br />
                <p>When our services are procured by a customer, we may also process or model co-related information from the GPS data.</p>
                <br />
                <p>Additional data collected through our modelling from the tracking unit is:</p>
                <ol style={{ marginLeft: 50 }}>
                    <li><p>Vehicle ignition state</p></li>
                    <br />
                    <li><p>Vehicle speed and, heading direction</p></li>
                    <br />
                    <li><p>Vehicle acceleration and braking data in 3 dimensions</p></li>
                    <br />
                    <li><p>Tracking Unit orientation, signal strength and GPS satellites in view data</p></li>
                    <br />
                    <li><p>Driver tag identification when fitted</p></li>
                    <br />
                    <li><p>Additional wired input when fitted, such as temperature monitoring, attached device state (tail-lift, pump, etc…), vehicle door open/closed or vehicle load state.</p></li>
                    <br />
                </ol>
                <br />
                <p>Data that is not from the tracking device is provided and maintained by the customer company that contracts our services. Vehicle location data is processed to determine an address for the location and may also provide driving style and driving time information for the customer company.</p>
                <br />
                <p><b>What do we use the information for?</b></p>
                <br />
                <p>We use your information to provide the services for which we have been contracted by the customer company that could be your employer, a company to which you are contracted. The customer company is the data controller for whom we are acting as a data processor, the data controller should inform data subjects of their lawful basis for processing and the purpose(s) of processing in the use of our services.</p>
                <br />
                <p>Services provided by Evata are as detailed on our website www.evata.co.uk</p>
                <br />
                <p><b>How do we protect your information?</b></p>
                <br />
                <p>We implement a variety of security measures to maintain the safety of your personal information. Security credentials are required to access the tracking application with feature accessibility by user account determined by the customer company system administrator.</p>
                <br />
                <p>All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.</p>
                <br />
                <p>After a transaction, your private information (credit cards, financials, etc.) will not be kept on file for more than 60 days, unless the services procured are recurring, in which case such information shall solely be used for Evata requesting payment or as required by law.</p>
                <br />
                <p><b>Will we contact you?</b></p>
                <br />
                <p>We may contact you as part of the services we provide to the customer company, this includes sending reports, arranging appointments for installation of the vehicle tracking unit, to discuss issues with the data received from the vehicle tracking unit or to arrange service appointments to investigate issues with the vehicle tracking unit.</p>
                <br />
                <p><b>Do we record phone calls?</b></p>
                <br />
                <p>Phone calls may be recorded for quality and training purposes.</p>
                <br />
                <p><b>Do we use Cookies?</b></p>
                <br />
                <p>Yes, Evata does use cookies. </p>
                <br />
                <p>Cookies are very small text files saved to your computer used to track user navigation around a website. Evata uses cookies to identify and track your movement within our websites, and your engagement with our other services such as blogs and newsletters. You will receive this cookie when you visit one of our websites but the information it collects will be stored and will remain anonymous for 38 months or until you share your email address with us through one of our websites.</p>
                <br />
                <p>Evata may use a third party to serve advertisements on our websites. The advertisers may use cookies to track the number of users responding to the campaign. Evata does not have access to or control of cookies placed by third parties.</p>
                <br />
                <p>Disabling/Enabling Cookies – users have the ability to accept or disable cookies by modifying the settings in their browser. Disabling cookies however, may mean that some functionality may be affected. For further directions on how to disable cookies please visit <a href="http://www.allaboutcookies.org/manage-cookies/stop-cookies-installed.html">here</a> </p>
                <br />
                <p>Evata also use a number of marketing analytics and email automation cookies. For example, we use Google Analytics to gather information about customer navigation and actions on our websites and using our mobile applications. Google Analytics uses cookies on your browser to store data about your activity on our sites which is then collected by google and may be analysed to understand for example the usage of our sites features and the navigation through it, we do not attempt to personally identify users in any way although the data may be used through google to target advertisements. You can opt out of allowing analytics to be used from your browser by installing a plug-in from google available at <a href="https://tools.google.com/dlpage/gaoptout/">here</a></p>
                <br />
                <p><b>Do we disclose any information to outside third parties?</b></p>
                <br />
                <p>No, we will not pass your contact details to any outside third party. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or other’s rights, property, or safety. However, non- personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                <br />
                <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
                <br />
                <p><b>Data subject rights</b></p>
                <br />
                <p>Individuals have a number of rights with regard to the data held about them, in the first instance requests should be made to the Customer company and any requests made directly to Evata will be referred to the customer company. Requests to Evata should be made to our support team who can be contacted as detailed at Legal@Evata.co.uk.</p>
                <br />
                <p>a. Right to be informed</p>
                <br />
                <p>Data subjects have the right to be informed of the personal data held about them, the processing of that personal data and of the lawful the basis of processing and purpose(s) of processing. This privacy notice provides details of the personal data held and processing of that personal data Evata carries out as a data processor on behalf of the customer company as a data controller that should provide the lawful the basis of processing and purpose(s) of processing.</p>
                <br />
                <p>b. Right of Access</p>
                <br />
                <p>More commonly known as Subject Access Requests or “SARs”, Data Subjects have the right to request and obtain from information relating to, and to receive a copy of, their Personal Data.</p>
                <br />
                <p>c. Right to Rectification</p>
                <br />
                <p>Data Subjects have the right to obtain the rectification or completion of inaccurate or incomplete Personal Data concerning him or her. Most of the personal data in the Evata’s system can be amended through the Evata web application by an administrative user of the customer company.</p>
                <br />
                <p>d. Rights to Erasure, Restriction, Data Portability and to Object</p>
                <br />
                <p>In certain circumstances and, in some cases, subject to specific exceptions, Data Subjects have the right to:</p>
                <br />
                <ol style={{ marginLeft: 50 }}>
                    <li><p>Obtain the erasure of Personal Data concerning him or her;</p></li>
                    <br />
                    <li><p>Obtain the restriction of Processing of Personal Data concerning him or her;</p></li>
                    <br />
                    <li><p>Obtain the Personal Data concerning him or her, which he or she has provided to a data controller, to transmit to another data controller without hindrance to have us transfer the personal data directly to another data controller where technically feasible;</p></li>
                    <br />
                    <li><p>Object at any time to Processing carried out in our legitimate interests, or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller or carried out for direct marketing purposes.</p></li>
                    <br />
                </ol>
                <br />
                <p>e. Automated Decision Making</p>
                <br />
                <p>Evata does not make decisions regarding Data Subjects based solely on automated Processing, including profiling, which produces legal or similarly significantly affects concerning him or her.</p>
                <br />
                <p><b>Transfer outside of the EU</b></p>
                <br />
                <p>Evata will only transfer (‘transfer’ includes making available remotely) Personal Data to countries outside of the EEA where:</p>
                <br />
                <ol style={{ marginLeft: 50 }}>
                    <li><p>The transfer is to a country (or an international organisation), that the European Commission has determined ensures an adequate level of protection;</p></li>
                    <br />
                    <li><p>Standard contractual clauses adopted by the European Commission have been put in place between Evata and the entity located outside the EEA;</p></li>
                    <br />
                    <li><p>binding corporate rules have been implemented, where applicable; or the transfer is otherwise permitted by the GDPR.</p></li>
                    <br />

                </ol>
                <br />
                <p><b>Data retention</b></p>
                <br />
                <p>The length of time Evata will retain tracking data can be set through the Evata web application by an administrative user from the customer company, the maximum retention period is 7 years and the minimum is 1 month. The data is available through the web application for up to 1 year with archive data available by request to the support team at www.evata.co.uk/contactus.</p>
                <br />
                <p>Customers should consider the length of time it is necessary for the tracking data to be retained for their purpose(s) of processing and an administrative user should configure the retention appropriately.</p>
                <br />
                <p><b>California online privacy protection act compliance</b></p>
                <br />
                <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent. As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information at any time by logging into user account and going to the ‘System Configuration’ page.</p>
                <br />
                <p><b>Your consent</b></p>
                <br />
                <p>By using our website, or giving us your details at a trade events or exhibitions, you consent to the collection and use of this information by Evata. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we would disclose it.</p>
                <br />
                <p><b>Contacting us</b></p>
                <br />
                <p>If there are any questions regarding this privacy policy you may contact us using the e-mail address below.</p>
                <br />
                <p>Legal@evata.co.uk</p>
                <br />
                <p>Any complaints should be raised with Evata at the email address above in the first instance. Data subjects can raise complaints to the supervisory authority in the UK this is the Information Commissioners office – www.ico.org.</p>
                <br />
                <p>Evata has appointed a Data Protection Officer in line with the requirements of the General Data Processing Regulation (GDPR) who can be contacted by email to Legal@evata.co.uk. </p>
                <br />
                <p>This policy was last modified on 4th September 2022.</p>


            </div>
            <Footer />

        </div>
    );
}

export default PrivacyPolicy;