import { rgbToHex } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HeatMap from "react-heatmap-grid";
import useWindowSize from "../../../custom_hook/useWindowSize";

const colorScale = ["#E6E6E6", "#CFCCCC", "#CCCBAD", "#808080", "#1A1A1A"];
// Function to convert a hex color to RGB
function hexToRgb(hex: any) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 230,
        g: 230,
        b: 230,
      };
}
function calculateRGB(value: any, min: any, max: any) {
  // Calculate the color index
  var colorIndex = Math.round(((value - min) / (max - min)) * (colorScale.length - 1));

  // Get the hex color
  var hexColor = colorScale[colorIndex];
  // Convert the hex color to RGB
  var rgbColor = hexToRgb(hexColor) as any;
  // Return the RGB color
  return `rgb(${rgbColor["r"]}, ${rgbColor["g"]},${rgbColor["b"]})`;
}

function HourHeatMap(props: any) {
  // function useClientRect() {
  //     const [rect, setRect] = useState(null);

  //     return [rect, ref];
  // }
  const windowSize = useWindowSize();

  const [rect, setRect] = useState({ width: 0 }) as any;
  const [height, setHeight] = useState(0) as any;
  function dynamicHeatBox(width: any) {
    setHeight((width - 140) / 24);
  }
  const ref = useCallback(
    (node: any) => {
      if (node !== null) {
        setRect(node.getBoundingClientRect());
      }
    },
    [windowSize.width]
  );
  useEffect(() => {
    dynamicHeatBox(rect.width);
  }, [rect.width]);

  const xLabels = ["00:00", "", "", "", "", "", "06:00", "", "", "", "", "", "12:00", "", "", "", "", "", "18:00", "", "", "", "", "23:00"];
  const yLabels = props.labels ? props.labels : ["Sun", "", "", "Wed", "", "", "Sat"];
  const emptyData = new Array(yLabels.length).fill(0).map(() => new Array(xLabels.length).fill(0).map(() => 0));
  const data = props.data ? props.data : emptyData;
  const kwData = [] as any;
  data.map((e: any) => {
    e.map((i: any) => kwData.push(i.kw));
  });
  const minValue = Math.min(...kwData);
  const maxValue = Math.max(...kwData);

  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)",
        padding: "30px 0px 30px 30px",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 30,
      }}
      ref={ref}>
      <HeatMap
        // background={"#44a340"}
        height={height}
        squares={true}
        xLabels={xLabels}
        yLabels={yLabels}
        data={data}
        title={(value: any, unit: any) => {
          if (value.kw === 0 && value.vehicleCount > 0) {
            return `There ${value.vehicleCount > 1 ? "are" : "is"} ${value.vehicleCount} vehicle parking but not charging`;
          } else if (value.kw === 0) {
            return `There is no charging and parking vehicle `;
          } else {
            return `There ${value.vehicleCount > 1 ? "are" : "is"} ${value.vehicleCount} vehicles charging with ${value.kw.toFixed(1)} kilowatt per hour`;
          }
        }}
        cellRender={(value: any) => value.vehicleCount > 0 && <p style={{ padding: 0 }}>{value.vehicleCount}</p>}
        cellStyle={(background: any, value: any, min: any, max: any, data: any, x: any, y: any) => {
          if (value.kw === 0 && value.vehicleCount > 0) {
            return {
              background: "rgba(124,252,0,0.4)",
              // margin: '1px',
              // padding: '2px',
              color: "black",
            };
          } else if (value.kw === 0) {
            return {
              background: "rgba(124,252,0,0.7)",
              // margin: '1px',
              // padding: '2px',
              color: "black",
            };
          } else {
            return {
              background: `rgba(255,160,122, ${1 - (maxValue - value.kw) / (maxValue - minValue)})`,
              // padding: '2px',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // margin: '1px',
              //   fontSize: "5px",

              color: "black",
            };
          }
        }}
        xLabelWidth={0}
        xLabelTextAlign={"bottom"}
        // yLabelWidth ={50}
        yLabelTextAlign={"left"}
      />
      <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: 20 }}>
        <div style={{ display: "flex", gap: 10 }}>
          <div style={{ width: 50, height: 20, backgroundColor: "rgba(124,252,0,0.7)" }}></div>
          <span> No Parking & Charging</span>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <div style={{ width: 50, height: 20, backgroundColor: "rgba(124, 252, 0, 0.4)" }}></div>
          <span> Parking & No Charging</span>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <div style={{ width: 80, height: 20, backgroundImage: "linear-gradient(to right, rgba(255,160,122,0) , rgba(255,160,122,1))" }}></div>
          <span> {`${Math.floor(minValue)} kw -> ${Math.round(maxValue)} kw`}</span>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Note : </span>
          The number on each cell represent current parking vehicle.
        </div>
      </div>
      {/* <p>Note : the number on each cell is vehicle count</p> */}
    </div>
  );
}

export default HourHeatMap;
