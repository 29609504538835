import { Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, selectedGridRowsCountSelector } from "@mui/x-data-grid";
import ScenarioConfigContext from "Context/ScenarioConfigContext";
import { AnyNsRecord } from "dns";
import React, { useContext, useEffect, useState } from "react";
import { GiConsoleController } from "react-icons/gi";
import UserContext from "../../../../Context/UserProvider";
import ErrorPage from "../../../../pages/ErrorPage/ErrorPage";
import TableSkelton from "../../../common/SkeltonComponent/TableSkelton";
function checking_null_undefined(array: any) {
  if (array === null || array === undefined) {
    return [];
  }
  return array;
}

function difference(a1: any, a2: any) {
  var result = [];
  for (var i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

const columns: GridColDef[] = [
  {
    field: "combinedRange",
    headerName: "Combined Range",
    sortable: false,
    align: "center",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    align: "left",
    headerAlign: "center",
    width: 520,
  },
  {
    field: "maxDCInputKW",
    headerName: "Max Rapid Charge Input",
    sortable: false,
    headerAlign: "center",
    align: "center",
    width: 200,
  },
  {
    field: "maxHomePowerInput",
    headerName: "Max Home power Input ",
    sortable: false,
    align: "center",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "maxThreePhaseInput",
    headerName: "Max Three Phase Input ",
    sortable: false,
    align: "center",
    headerAlign: "center",
    width: 200,
  }
];

function ComparisonElectricVehicleTabScreen(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [scenarioConfigInfo, setScenarioConfigInfo] = useContext(ScenarioConfigContext) as any;
  const fleetScenarioUuid = scenarioConfigInfo.draftScenario.uuid;
  const [loading, setLoading] = useState(true);
  const [electricVehicles, setElectricVehicles] = useState([]) as any;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]) as any;
  const [selectedRow, setSelectedRow] = useState([]) as any;
  const [isError, setIsError] = useState(false);
  async function fetchEvList() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/electric-vehicles/list`;
    let response = await fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    const electricVehicles = checking_null_undefined(
      res_json.electricVehicleList.map((e: any) => ({
        ...e.electricVehicle,
        maxThreePhaseInput: e.electricVehicle.maxThreePhaseInput.toLocaleString() + " kW",
        maxHomePowerInput: e.electricVehicle.maxHomePowerInput.toLocaleString() + " kW",
        maxDCInputKW: e.electricVehicle.maxDCInputKW.toLocaleString() + " kW",
        combinedRange: Math.round(e.electricVehicle.combinedRange).toLocaleString() + " " + userInfo.userProfile.distanceUnitShortDisplayName,
        id: e.fleetSnapshotElectricVehicleUuid,
        selected: e.selected,
      }))
    );
    const selectedRow = electricVehicles.filter((e: any) => e.selected);
    await setSelectionModel(selectedRow.map((r: any) => r.id));
    setSelectedRow(selectedRow);
    await setElectricVehicles(electricVehicles);
    setLoading(false);
  }

  function toggleEv(EvId: any) {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["fleetScenarioElectricVehicleUuid"] = EvId;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/electric-vehicles/toggle`;
    fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status !== 200) {
        setIsError(true);
        return;
      }
      res.json().then((res_obj) => {});
    });
  }

  function toggleAll(selected : boolean) {
    let bodyData: { [name: string]: any } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["allSelected"] = selected;
    bodyData["fleetScenarioUuid"] = fleetScenarioUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/electric-vehicles/toggle/all`;
    fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status !== 200) {
        setIsError(true);
        return;
      }
      res.json().then((res_obj) => {});
    });  
  }

  useEffect(() => {
    fetchEvList();
  }, []);
  return (
    <>
      {isError ? (
        <ErrorPage statusCode="500" />
      ) : (
        <>
          {loading ? (
            <TableSkelton />
          ) : (
            <div className="table-box">
              <DataGrid
                width={"100%"}
                rows={electricVehicles}
                autoHeight
                {...electricVehicles}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[10]}
                disableColumnMenu
                // disableSelectionOnClick
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center">
                      <span>No Electric Vehicles exist.</span>
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center">
                      Selected filter returns no result
                    </Stack>
                  ),
                }}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(e: any) => {

                  setSelectionModel(e);
                  const selectedIDs = new Set(e);
                  const selectedRowData = electricVehicles.filter((row: any) => selectedIDs.has(row.id.toString()));

                  const rowChanges = [...difference(selectedRow, selectedRowData), ...difference(selectedRowData, selectedRow)];
                 if(e.length == electricVehicles.length) {
                    toggleAll(true);
                 } else if(e.length == 0) {
                    toggleAll(false);
                 } else {
                    for (let i = 0; i < rowChanges.length; i++) {
                      toggleEv(rowChanges[i].id);
                    }
                 }

                  setSelectedRow(selectedRowData);
                }}
                checkboxSelection={true}
                fontFamily={"Arvo"}></DataGrid>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ComparisonElectricVehicleTabScreen;
