import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, CircularProgress } from '@mui/material';
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router-dom";
import PowerPurchaseAgreementQuotesTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementQuotesTable";
import { Link } from 'react-router-dom';


function BuildQuotePage(props: any) {

    const [agreement, setAgreement] = useState([]) as any
    const [isError, setIsError] = useState(false);



    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useContext(UserContext);
 
    useEffect(() => {
       setAgreement(null);
       setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                agreement == null ? (
                    <div><span>No Power Purchase Agreement has been set up - please click <Link to="/agree/ppa/quote/build/vehicles">Build PPA Quote</Link> to start</span></div>
                ) : (
                <div>
asdfasdf
                </div >
                )
            )
            }
        </>
    );
}

export default BuildQuotePage;
