import UserContext from "Context/UserProvider";
import BusinessLocationsWithChargingTable from "components/business/InfrastructureComponent/BusinessLocationsWithChargingTable";
import { useContext, useEffect, useState } from "react";

function isNull(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function BusinessDwellingListPage(props: any) {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [businessLocationsData, setBusinessLocationsData] = useState([]) as any;
    const [showCategory, setShowCategory] = useState([true]);
    const [activeMenu, setActiveMenu] = useState("Business Locations");

    const [enableButton, setEnableButton] = useState(false);


    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }


    async function fetchLocationData() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/infrastructure/dwelling/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();


        res_json.businessLocations = checking_null_undefined(res_json.businessLocations);

        setBusinessLocationsData(res_json.businessLocations.map((e: any) => ({ ...e, id: e.uuid, businessUuid: e.businessUuid })));

        setLoading(false);
    }

    useEffect(() => {
        fetchLocationData();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                    onClick={() => (setShowCategory([true]), setActiveMenu("All"))}>
                    All
                </button>
                <div className="vl"></div>
            </div>

            {showCategory[0] && (
                <>
                    {/* {fleetSummaryData.map((e: any, index: any) => {
            return (
              <FleetSummaryContainer
                key={index}
                data={e}
              />
            );
          })} */}

                    <BusinessLocationsWithChargingTable data={businessLocationsData} />
                </>
            )}
            {showCategory[1] && (
                <>
                    <BusinessLocationsWithChargingTable data={businessLocationsData} />
                </>
            )}

        </div>
    );
}

export default BusinessDwellingListPage;
