const CogIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
		  	<path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
		  	<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
		</svg>
	)
}

const BellIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
		</svg>	
	)
}

const HamburgerIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
		 	<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
		</svg>
	)
}

const AccountImage = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
		  	<path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
		</svg>
	)
}

const ErrorIcon = () => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
		</svg>
	)
}

const LoadingIcon = () => {
	return (
		<svg className="loading-circle" version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		  viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
		  <circle fill="#676767" stroke="none" cx="6" cy="50" r="6">
		    <animate
		      attributeName="opacity"
		      dur="1s"
		      values="0;1;0"
		      repeatCount="indefinite"
		      begin="0.1"/>    
		  </circle>
		  <circle fill="#676767" stroke="none" cx="26" cy="50" r="6">
		    <animate
		      attributeName="opacity"
		      dur="1s"
		      values="0;1;0"
		      repeatCount="indefinite" 
		      begin="0.2"/>       
		  </circle>
		  <circle fill="#676767" stroke="none" cx="46" cy="50" r="6">
		    <animate
		      attributeName="opacity"
		      dur="1s"
		      values="0;1;0"
		      repeatCount="indefinite" 
		      begin="0.3"/>     
		  </circle>
		</svg>
	)
}

export { CogIcon, BellIcon, HamburgerIcon, ErrorIcon, AccountImage, LoadingIcon }