import ModalComponent from 'components/common/Modal/Modal';
import React, { useContext, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import UserContext from '../../../Context/UserProvider';
import './AddEmailSurvey.css';
function AddEmailSurvey(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext)
    const [modalIsOpen, setIsOpen] = useState(false)
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    function submitForm() {
        let emails = document.getElementById("emails") as HTMLInputElement
        let bodyData: { [name: string]: string } = {}
        bodyData['uuid'] = userInfo.uuid
        bodyData['fleetUuid'] = userInfo.fleetUuid
        bodyData['emails'] = emails!.value
        emails!.value = ''
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
        let fetch_link = `${rootUrl}/survey/create`
        fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                'cache': "no-cache",
                'pragma': "no-cache",
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res.status === 200) {
                openModal()
            }
        })

    }
    return (
        <div className="email-survey-container" >
            <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                <>
                    <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IoMdClose size={30} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>
                        <h1>The User Survey emails have been sent successfully.</h1>
                    </div>
                </>

            </ModalComponent>
            <textarea placeholder='jake@evata.co.uk; john@evata.co.uk' id='emails' />
            <button className='primary-btn' onClick={() => submitForm()}>Submit</button>
        </div>
    );
}

export default AddEmailSurvey;