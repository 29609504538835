import ChargingInfrastructureBarChart from "components/charging/ChargingInfrastructureComponent/ChargingInfrastructureBarChart";
import BusinessContext from "Context/BusinessContext";
import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";

function ChargingInfrastructurePage(props: any) {
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [selectWeekData, setSelectWeekData] = useContext(BusinessContext) as any;
  const [infrastructureInsight, setInfrastructureInsight] = useState([]) as any;
  const [maxKwTick, setMaxKwTick] = useState(99) as any;
  const [maxKwTicks, setMaxKwTicks] = useState([]) as any;

  function fetchChargingInfrastructureInsight() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    bodyData["dayOfWeek"] = selectWeekData.dateOfWeek;
    bodyData["dwellingUuid"] = selectWeekData.dwellingUuid;
    bodyData["operationalCostModelUuid"] = selectWeekData.operationalCostModelUuid;
    bodyData["startDayKey"] = selectWeekData.startDayKey;
    bodyData["fleetSnapshotDwellingUuid"] = selectWeekData.fleetSnapshotDwellingUuid;
    
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/infrastructure/dwelling/chargepoints/list`;
    fetch(fetch_link, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: {
        cache: "no-cache",
        pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      res.json().then((res_obj) => {
        const infrastructureInsightData = res_obj.dwellingVirtualChargePoints.map((e: any) => {
          const list = [] as any;
          setMaxKwTick(e.maxKW);
          e.portA.slots.map((slotA: any, index: any) => list.push({ aChargingRate: slotA ? slotA.chargeRateKW : 0, bChargingRate: e.portB.slots[index] ? e.portB.slots[index].chargeRateKW : 0 }));
          return list;
        });

        const maxKwTickData = res_obj.dwellingVirtualChargePoints.map((e: any) => {
          const list = [] as any;
          list.push([0,e.maxKw/2,e.maxKW]);
          return list;
        });

        setInfrastructureInsight(infrastructureInsightData);
        setMaxKwTicks(maxKwTickData);
        setLoading(false);
      })
    );
  }
  useEffect(() => {
    fetchChargingInfrastructureInsight();
  }, []);

  return (
    <>
      {loading ? (
        <>loading...</>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
          {infrastructureInsight.map((e: any, index: any) => (
            <ChargingInfrastructureBarChart
              key={index}
              data={e}
              nums={maxKwTicks[index]}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default ChargingInfrastructurePage;