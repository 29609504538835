import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";
import { isAccountRole } from "utils/roleUtils";
import { isMultiProduct } from "utils/productUtils";
import useWindowSize from "../../../../custom_hook/useWindowSize";
import InfiniteNoificationScroll from "../../Notifications/InfiniteNoificationScroll";
import { NotificationContext } from "../../Notifications/NotificationProvider";

import ProductSelector from "./Components/ProductSelector";

import UserContext from "Context/UserProvider";
import AuthService from "service/AuthService/AuthService";
import SelectedFleetModal from "./Components/SelectedFleetModal";
import "./Nav.scss";
// import { PromptProps } from "react-router-dom";

function Nav(props: any) {
    const navigate = useNavigate();

    const notificationContext = useContext(NotificationContext);
    const sidebar = props.sidebar;
    const { userInfo, setUserInfo, selectedProduct } = useContext(UserContext);
    const notificationCount = notificationContext.state[2];
    const notifications = notificationContext.state[1];
    const [notificationMessages, setNotificationMessages] = useState([]) as any;
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    const [currentNotifications, setCurrentNotifications] = useState([]) as any;

    const [currentOpen, setCurrentOpen] = useState("");
    const { width } = useWindowSize();
    useEffect(() => {
        const messages = notifications.map((e: any) => e.message);
        setCurrentNotifications(messages);
    }, notificationCount);

    function dropMenu(element: any) {
        switch (element) {
            case "profile":
                if (currentOpen === "profile") {
                    setCurrentOpen("");
                } else {
                    setCurrentOpen("profile");
                }
                break;
            case "notification":
                if (currentOpen === "notification") {
                    setCurrentOpen("");
                } else {
                    setCurrentOpen("notification");
                }
                break;
            default:
                setCurrentOpen("");
        }
    }
    const closeDropDown = useCallback((e: any) => {
        if (e.target.id !== "nav-drop-item") {
            if (e.target.id === "manage-account-btn") {
                navigate("/common/profile");
            } else if (e.target.id === "logout-btn") {
                AuthService.logout();
            }
            setCurrentOpen("");
        }
    }, []);
    useEffect(() => {
        if (currentOpen !== "") {
            document.addEventListener("click", closeDropDown, true);
        } else {
            document.removeEventListener("click", closeDropDown, true);
        }

        return () => {
            document.removeEventListener("click", closeDropDown, true);
        };
    }, [currentOpen]);

    return (
        <Navbar userInfo={props.userInfo}>
            {/* modal for switching the fleet */}
            {modalIsOpen && (
                <SelectedFleetModal
                    modalIsOpen={[modalIsOpen, setIsOpen]}
                    userInfo={props.userInfo}
                />
            )}
            {/* left side navigation component */}
            {!props.landing ? (
                <div className="toggle-sidebar">
                    {selectedProduct === "PLAN" && (
                        <span>
                            <span
                                onClick={props.controlSideBar}
                                className="material-symbols-outlined icon"
                                style={{ display: sidebar ? "block" : "none" }}>
                                menu
                            </span>
                            <span
                                onClick={props.controlSideBar}
                                className="material-symbols-outlined icon active"
                                style={{ display: sidebar ? "none" : "block" }}>
                                close
                            </span>
                        </span>
                    )}
                    <h1
                        className="logo"
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => {
                            props.setSidebar(true);
                            navigate(`/${selectedProduct?.toLowerCase()}`);
                        }}>
                        Evata
                    </h1>
                </div>
            ) : (
                <div className="logo">
                    <img
                        src={`https://evata-dev-4-cdn.evata.co.uk/web/static/evata-logo.webp`}
                        alt="evata logo"
                        style={{ width: "auto", height: 60 }}
                    />
                </div>
            )}

            {isAccountRole(userInfo.userType) ? (
                <div className="current-fleet">
                    <span className="title">Current Fleet</span>
                    <span className="fleet-name">
                        <span>{userInfo.businessName} -</span>
                        <span> {userInfo.currentFleetName}</span>
                        <span className="change-fleet">
                            <a
                                className={"hyper-link"}
                                onClick={() => {
                                    props.setSidebar(true);
                                    openModal();
                                }}>
                                Change
                            </a>
                        </span>
                    </span>
                </div>
            ) : (
                <div className="current-fleet">
                    <span className="fleet-name">
                        <span>{userInfo.businessName}</span>
                    </span>
                </div>
            )}

            <ul className="nav">

                {isMultiProduct(userInfo.businessProducts) && (
                    <>
                        <ProductSelector
                            businessProducts={userInfo.businessProducts}
                            product="PLAN"
                            title="PLAN"></ProductSelector>

                        <ProductSelector
                             businessProducts={userInfo.businessProducts}
                             product="SHARE"
                            title="SHARE"></ProductSelector>

                        <ProductSelector
                            businessProducts={userInfo.businessProducts}
                            product="AGREE"
                            title="AGREE"></ProductSelector>
                    </>
                )}

                <ProfileLink />
                <NavItem
                    icon={"logout"}
                    setSidebar={props.setSidebar}
                    logout={true}
                    title="Sign out"></NavItem>
            </ul>
        </Navbar>
    );
}

export function ProfileLink(props: any) {

    const navigate = useNavigate();

    return (
        <li className="item">
            <a
                onClick={() => {
                    navigate("/common/profile");
                }}>
                <span className="material-symbols-outlined icon">account_circle</span>
                <span
                    className="title"
                    id="nav-drop-item">
                    My Profile
                </span>
            </a>
        </li>
    );
}


export function Navbar(props: any) {
    return (
        <header
            className="header"
            id="header">
            {props.children}
        </header>
    );
}

export function NavItem(props: any) {
    function logout() {
        return AuthService.logout();
    }
    return (
        <li className="item">
            <a
                onClick={() => {
                    if (props.logout) {
                        props.setSidebar(true);
                        logout();
                    } else {
                        props.setSidebar(true);
                        props.dropMenu(props.elem);
                    }
                }}>
                <span className="material-symbols-outlined icon">{props.icon}</span>
                <span
                    className="title"
                    id="nav-drop-item">
                    {props.title}
                </span>
            </a>
            {props.notification && props.notificationCount > 0 && <span className="notification-count">{props.notificationCount}</span>}
            {props.open && props.children}
        </li>
    );
}

export function DropdownMenu(props: any) {
    const navigate = useNavigate();
    function logout() {
        AuthService.logout();
    }
    const [activeMenu, setActiveMenu] = useState(props.type);
    const [menuHeight, setMenuHeight] = useState(0) as any;
    const dropdownRef = useRef() as any;

    useEffect(() => {
        setMenuHeight(dropdownRef.current.firstChild.offsetHeight);
    }, []);

    function calcHeight(el: any) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function DropdownItem(props: any) {
        return (
            <a
                className="menu-item"
            >
                <span className={props.profile ? "icon-profile" : "icon-button"}>{props.leftIcon}</span>
                {props.children}
                <span className="icon-right">{props.rightIcon}</span>
            </a>
        );
    }

    return (
        <div
            className="dropdown"
            ref={dropdownRef}>
            {/* start main menu */}


            <div className="profile">
                <div className="info">
                    <div className="name-and-email">
                        <span className="font-weight-bold">{props.user}</span>
                        <span>{props.user}</span>
                    </div>
                </div>
                <div className="actions">
                    <button
                        onClick={() => ""}
                        type="button"
                        className="button"
                        id="manage-account-btn">
                        Manage Account
                    </button>
                    <button
                        type="button"
                        id="logout-btn"
                        className="button sign-out">
                        Sign out
                    </button>
                </div>
            </div>
        </div>
    );
}
export function NotiContent(props: any) {
    return (
        <div className="noti-content">
            <a className="menu-item">
                <span className={props.profile ? "icon-profile" : "icon-button"}>{props.leftIcon}</span>
                {props.children}
                <span className="icon-right">{props.rightIcon}</span>
            </a>
            <div className="d-flex flex-column">
                <p>You have been upload the file, here is the file number that you uplod the first thing is the</p>
                <p style={{ color: "grey" }}>6 days ago</p>
            </div>
        </div>
    );
}

export default Nav;
