import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router";
import PercentageBar from "components/ppa-quote/PercentageBar";
import PercentagePie from "./PercentagePie";
import PercentagePies from "./PercentagePies";

interface DataRow {
    id: number;
    uuid: string;
    siteAccessPolicyUuid: string;
    workflowState: string;
    chargePointOperator: {
        uuid: string;
        name: string;
        logoUrl: string;
    };
    rateCardName: string;
    ppaPricePerKWh: number;
    monthlyCommitmentInGbp: number;
    annualCommitmentInGbp: number;
    publicSavingInGbp: number;
    rowId: any;
}

interface DataGridChildProps {
    businessChargingCategory: string;
    quotes: DataRow[];
    acceptQuote: (chargePointOperatorUuid: string, rateCardUuid: string, rowId: any) => void;
}

const formatToGBP = (value: number) => {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
};

const CustomHeader = () => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            {/* Main Title */}
            <Typography variant="subtitle1" fontWeight="500" fontSize="10pt">
                Within Range (km)
            </Typography>
            {/* Subtitles for each distance range */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                {['[0-5]', '[5-10]', '[10-20]', '[20-40]', '[40+]'].map((label, index) => (
                    <Typography
                        key={index}
                        variant="caption"
                        sx={{ width: '49px', textAlign: 'center' }}
                    >
                        {label}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
}

const QuotesTable: React.FC<DataGridChildProps> = ({ businessChargingCategory, quotes, acceptQuote }) => {
    const [includeVAT, setIncludeVAT] = useState(true);
    const navigate = useNavigate();
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const toggleVAT = () => {
        setIncludeVAT(!includeVAT);
    };

    const formatPrice = (cost: number) => {
        if (cost === null || cost === 0) {
            return "";
        }
        const vatRate = 0.20; // Assuming 20% VAT
        const price = includeVAT ? cost : cost / (1 + vatRate);
        return formatToGBP(price);
    };

    const renderHeader = (title: string) => (
        <div>
            <b>{title}</b>
            <br />
            <span style={{ fontSize: 'smaller', fontWeight: 'normal' }}>
                {includeVAT ? "(incl VAT)" : "(excl VAT)"}
            </span>
        </div>
    );

    useEffect(() => {
        const updatedColumns: GridColDef[] = [
            {
                field: 'logo',
                headerName: 'Charge Point Operator',
                flex: 1, // Dynamically allocate width
                minWidth: 150, // Ensure a minimum width
                renderCell: (params) => (
                    <img
                        src={params.row.chargePointOperator.logoUrl}
                        alt="Operator Logo"
                        style={{ width: 100, height: 40, objectFit: 'contain' }}
                    />
                ),
            },
            {
                field: 'percentages',
                headerName: 'Within Range',
                flex: 2,
                minWidth: 264,
                // Use the custom header component
                renderHeader: () => <CustomHeader />,
                // Render pie charts in one row
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '-0px' }}>
                        {params.value.map((percentage: number, index: number) => (
                            <Box key={index} sx={{ width: '48px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PercentagePie
                                    percentage={percentage}
                                    color={['#006400', '#228B22', '#32CD32', '#66CDAA', '#7FFF00'][index]}
                                />
                            </Box>
                        ))}
                    </Box>
                ),
            },

            {
                field: "rateCardName",
                headerName: "Agreement Name",
                flex: 1,
                minWidth: 130,
                align: "left",
                headerAlign: "left",
            },
            {
                field: "ppaPricePerKWh",
                renderHeader: () => renderHeader("Price per kW/h"),
                flex: 1,
                minWidth: 150,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "monthlyCommitmentInGbp",
                renderHeader: () => renderHeader("Monthly Commitment"),
                flex: 1.5,
                minWidth: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "annualCommitmentInGbp",
                renderHeader: () => renderHeader("Annual Commitment"),
                flex: 1.5,
                minWidth: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: "publicSavingInGbp",
                renderHeader: () => renderHeader("Est. Annual Cost Saving"),
                flex: 1.5,
                minWidth: 180,
                align: "right",
                headerAlign: "right",
                valueFormatter: (params) => formatPrice(params as number),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                minWidth: 150,
                renderCell: (params) => (
                    params.row.rateCardName !== null ? (
                        <Button variant="contained" color="primary">
                            Accept
                        </Button>
                    ) :
                        <Button variant="contained" color="secondary">Request</Button>
                ),
            },
        ];
        setColumns(updatedColumns);
    }, [includeVAT, navigate, acceptQuote]);

    return (
        <>
            <div className="table-box">
                <Button onClick={toggleVAT}>
                    {includeVAT ? "Show Prices Excluding VAT" : "Show Prices Including VAT"}
                </Button>
                <DataGrid
                    rows={quotes}
                    autoHeight
                    columns={columns}
                    disableColumnMenu
                    initialState={{ pagination: { paginationModel: { page: 1, pageSize: 10 } } }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    checkboxSelection
                    getRowId={(row) => row.chargePointOperator.uuid}
                />
            </div>
        </>
    );
};

export default QuotesTable;
