import OcmSummaryTable from "components/ocm/OperationalCostModelSummaryComponent/OcmSummaryTable";
import UserContext from "Context/UserProvider";
import React, { useContext, useEffect, useState } from "react";
import { currencyFormat, checking_null_undefined } from "utils";

function OperationalCostModelSummaryPage(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [OcmSummaryData, setOcmSummaryData] = useState([]) as any;
    const [activeMenu, setActiveMenu] = useState("Operational Cost Models");

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }
    async function fetchOperationalCostData() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/operational-cost-model/list`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        // res_json.dwellingChargePointSummaries = checking_null_undefined(res_json.dwellingChargePointSummaries);
        let summaries = checking_null_undefined(res_json.summaries);

        setOcmSummaryData(summaries.map((e: any) => ({ ...e, id: e.uuid, itemCount: e.total.itemCount, totalOperationalCostOfEnRouteCharging: currencyFormat(e.total.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(e.total.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(e.total.totalTermEnergyCostSaving) + " (" + Math.round(e.total.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: e.total.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: e.total.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, totalBusinessChargingEnergyCost: currencyFormat(e.total.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(e.total.totalPublicChargingEnergyCost),totalVirtualChargingEnergyCost: currencyFormat(e.total.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(e.total.totalHomeChargingEnergyCost) })));

        setLoading(false);
    }

    useEffect(() => {
        fetchOperationalCostData();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <OcmSummaryTable data={OcmSummaryData} />
        </div>
    );
}

export default OperationalCostModelSummaryPage;
