import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./TableHelpIcon.css";

function HelpIcon(props: any) {
  const popover = ({ content, header }: any) => (
    <Popover
      id="popover-trigger-focus"
      style={{ padding: 20 }}
      title="Popover left">
      <strong>{header}</strong> {content}.
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="focus"
      placement="bottom"
      overlay={popover({ content: props.content, header: props.header })}>
      <button className={"help-btn"}>
        <span
          className="material-symbols-outlined"
          style={{ cursor: "pointer" }}>
          help
        </span>
      </button>
    </OverlayTrigger>
  );
}

export default HelpIcon;
