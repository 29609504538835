import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useContext } from "react";
import UserContext from "Context/UserProvider";
import { displayProductButton } from "utils/productUtils";

import AuthService from "service/AuthService/AuthService";
import "../Nav.scss";

interface ProductSelectorProps {

    businessProducts: string[];
    product: string;
    title: string;

}

const ProductSelector: React.FC<ProductSelectorProps> = ({ businessProducts, product, title }) => {
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);

    const { userInfo, setUserInfo, selectedProduct, setSelectedProduct, theme, setTheme } = useContext(UserContext);

    function logout() {
        return AuthService.logout();
    }

    return (

        <li className="item">
            {displayProductButton(product, businessProducts) && (
                <a
                    onClick={() => {

                        setSelectedProduct(product);

                    }}>
                    <span
                        className="title"
                        id="nav-drop-item">
                        {title}
                    </span>
                </a>
            )}
        </li>
    );
}
export default ProductSelector;