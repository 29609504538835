
import { Outlet } from "react-router";

export default function IceVehicleRouter(props: any) {
  // const yourFleetHeader = <h1><b>Telematics</b></h1>
  return (
    <div className="drag-drop-container">
      <Outlet />
    </div>
  );
}
