import { useAuth0 } from "@auth0/auth0-react";
import { gridColumnLookupSelector } from "@mui/x-data-grid";
import LoaderModal from "components/common/Modal/LoaderModal";
import UserContext from "Context/UserProvider";
import React, { useReducer, useEffect, useContext, useState } from "react";
import BackButton from "../../components/common/SiteTemplate/BackButton/BackButton";
import "./ProfilePage.scss";

function ProfilePage(props: any) {
  useEffect(() => {
    document.title = `Profile`;
  });
  const {userInfo, setUserInfo} = useContext(UserContext);
  const user = userInfo.authUser;
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const type = userInfo.accountType === "EARLY_ADOPTER" ? "Early Adopter" : "Free";
  const initialState = {
    option1: userInfo.userProfile.distanceUnit === "KM",
    option2: userInfo.userProfile.distanceUnit === "MILES",
    chosenUnit: userInfo.userProfile.distanceUnit,
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "MILES":
        return { ...state, option1: false, option2: true, chosenUnit: "MILES" };
      case "KM":
        return { ...state, option1: true, option2: false, chosenUnit: "KM" };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const switchUnitDistance = async () => {
    setLoading(true);
    let bodyData: { [name: string]: any } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["userProfile"] = { distanceUnit: state.chosenUnit };
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/user_auth/profile/save`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    setUserInfo((prev: any) => ({ ...prev, userProfile: res_json.userProfile }));
    setLoading(false);
  };
  return (
    <div className="profile-page-container">
      {loading && <LoaderModal />}
      {/* <BackButton /> */}
      <div className="profile-container">
        <span className="icon-profile-page">
        </span>
        <span style={{ fontSize: 28, fontWeight: "bold" }}></span>
        <div className="about-container">
          <span style={{ fontWeight: "bold" }}>ABOUT</span>
          {/* <span className="about-line">Role : Software Engineer</span> */}
          <span className="about-line">Account : {type}</span>
          {/* <span className="about-line">Company : Evata</span> */}
          <span className="about-line">Preferred Unit : {userInfo.userProfile.distanceUnitDisplayName}</span>
          <span style={{ fontWeight: "bold" }}>CONTACT</span>
          <span className="about-line">Email : {user.email}</span>
        </div>
      </div>
      {/* <div className="profile-edit-container">
                <span>Edit Profile</span>
            </div> */}
      <div className="form-container">
        <span>Select Unit Distance</span>
        <div className="radio-btn-container">
          <div
            className={`radio-btn ${state.option1 ? "active" : ""}`}
            onClick={() => dispatch({ type: "KM" })}>
            <span>Kilometers</span>
          </div>
          <div
            className={`radio-btn ${state.option2 ? "active" : ""}`}
            onClick={() => dispatch({ type: "MILES" })}>
            <span>Miles</span>
          </div>
        </div>
        <button
          className="primary-btn"
          onClick={() => switchUnitDistance()}
          disabled={!(state.option1 === true || state.option2 === true)}>
          Confirm
        </button>
      </div>
    </div>
  );
}

export default ProfilePage;
