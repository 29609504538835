import React from 'react';
import UploadForm from './Components/UploadForm';

function ImportLocationPage(props: any) {
    return (
        <div>
            <UploadForm
            />

        </div>
    );
}

export default ImportLocationPage;