import CheckIcon from '@mui/icons-material/Check';
import './MembershipCard.scss'

export default function MembershipCard(props: any) {
  const action_url = `http://localhost:3001/sub/create-checkout-session?productID=${props.productID}`
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40, padding: 50, borderRadius: 30, boxShadow: '0 0 2px 0 #808080' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>
        <h3 style={{ color: '#757575' }}><b>{props.type}</b></h3>
        <h1 ><b>{props.price}</b></h1>
        <h4 style={{ color: '#00e676' }}><b>per month </b></h4>
        <p >A paragraph is a self-contained unit of discourse in writing dealing with a particular point or idea. A paragraph consists of three or more sentences. Though not required by the syntax of any language, paragraphs are usually an expected part of formal writing, used to organize longer prose.</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div className='list_items' >
          <CheckIcon />
          <span>3 compare</span>
        </div>
        <div className='list_items'>
          <CheckIcon />
          <span>3 compare</span>
        </div>
        <div className='list_items'>
          <CheckIcon />
          <span>3 compare</span>
        </div>
        <div className='list_items'>
          <CheckIcon />
          <span>3 compare</span>
        </div>
        <div className='list_items'>
          <CheckIcon />
          <span>3 compare</span>
        </div>
      </div>
      <form style={{ display: 'flex', justifyContent: 'center' }} action={action_url} method="POST">
        {/* Add a hidden field with the lookup_key of your Price */}
        <button className='membership_submit_button' id="checkout-and-portal-button" type="submit">
          <h3 style={{ color: 'white' }}><b>Choose {props.type}</b></h3>
        </button>
      </form>

    </div>
  )
}
