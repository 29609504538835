import React, { PureComponent } from "react";
import { useNavigate } from "react-router";
import {
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Legend,
} from "recharts";
import "./HorizontalStackedChart.scss";

export default function HorizontalStackedChartPower(props: any) {
  const navigate = useNavigate();

  const data = props.data.map(
    ({ description: description, data: data, id: id }: any, index: any) => {
      return {
        id: id,
        name: description,
        electricity: Math.round(data[0].price),
        fuel: Math.round(data[1].price),
      };
    }
  );
  return (
    <div className="horizontal-chart-container">
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            right: 30,
            left: 20,
            bottom: 5,
            top: 20,
          }}
          onClick={(e: any) =>
            navigate(
              "/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight",
              {
                state: {
                  id: e.activePayload[0].payload.id,
                  categoryType: props.categoryType,
                  fleetScenarioNumber: props.fleetScenarioNumber,
                  fleetScenarioUuid: props.fleetScenarioUuid,
                  description: e.activeLabel,
                  registrationNumber: props.registrationNumber,
                },
              }
            )
          }
        >
          <XAxis type="number" axisLine={false} unit={"£"}/>
          <YAxis
            interval={0}
            dataKey="name"
            type="category"
            orientation={"right"}
            width={600}
            axisLine={false}
            tickLine={false}
            fontSize={10}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="electricity" stackId="a" fill="#BEF213" />
          <Bar dataKey="fuel" stackId="b" fill="#C1D1D6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
