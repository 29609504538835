import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "./DashboardCardItem";
import DashboardCardTitle from "./DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

function UserManagement(props: any) {

    const dashboardInfo = props.dashboardInfo;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <div className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">group</span>
                    User Management
                </div>
                <div className="dashboard-card-content">
                    <ul className="ul">
                        <li>
                            <a href="#" onClick={() => {
                                navigate("/common/user/list", {
                                    state: {
                                        fleetScenarioUuid: dashboardInfo.lastDraftScenario.uuid,
                                    },
                                });
                            }}>List Users</a>
                        </li>
                        <li>
                            <a href="#" onClick={() => {
                                navigate("/common/user/add", {
                                    state: {
                                        optionCategory: [true, false],
                                        business: {
                                            name: userInfo.businessName,
                                            uuid: userInfo.businessUuid,
                                        },
                                    },
                                });
                            }}>Add User</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default UserManagement;
