import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../Context/UserProvider";
import TableSkelton from "../../common/SkeltonComponent/TableSkelton";
import { RxUpdate } from "react-icons/rx";
import ModalComponent from "components/common/Modal/Modal";
import { IoMdClose } from "react-icons/io";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UpdateSurveyPage from "pages/SurveyPage/UpdateSurveyPage";
import { useNavigate } from "react-router";

function SurveyEmailTable(props: any) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const emails = props.emails;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const columns: GridColDef[] = [
    {
      field: "emailAddress",
      headerName: "Email",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sentOnDate",
      headerName: "Sent On",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "vehicleRegistration",
      headerName: "Vehicle Registration",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "addressStatusDisplayName",
      headerName: "Address Status",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "existingChargingFacilities",
      headerName: "Existing Charging Facilities",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (cellValue) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            {/* <div className={`action-button ${cellValue.row.type === "INFRASTRUCTURE_ESTIMATED" ? 'inactive' : ''} `} onClick={() => !(cellValue.row.type === "INFRASTRUCTURE_ESTIMATED") && navigateScreen(cellValue, '/plan/infrastructure/dwelling-detail')} > */}
            <div
              className={`action-button`}
              onClick={() => navigate("/plan/business/surveys/edit", { state: { currentSelected: cellValue.row } })}>
              <RxUpdate />
              <span>Update</span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-box">
        {/* <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                    <>
                        <div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IoMdClose className="close-btn" fontSize={30} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>

                            <UpdateSurveyPage currentSelected={currentSelected} fetchEmails={fetchEmails} />

                        </div>
                    </>

                </ModalComponent> */}

        <DataGrid
          width={"100%"}
          rows={emails}
          autoHeight
          {...emails}
          columns={columns}
          disableColumnMenu
          pageSize={15}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                <span>Once you have surveyed the drivers of your fleet, results will appear here</span>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center">
                Local filter returns no result
              </Stack>
            ),
          }}
          checkboxSelection={true}
          fontFamily={"Arvo"}></DataGrid>
      </div>
    </>
  );
}

export default SurveyEmailTable;
