import React, { useContext, useEffect, useState } from "react";
import DashboardCardItem from "../common/DashboardCardItem";
import DashboardCardTitle from "../common/DashboardCardTitle";
import UserContext from "Context/UserProvider";
import { useNavigate } from "react-router";

interface ChargingSessionsProps {
    title: string;
    chargingSessions: any; // Replace `any` with a more specific type if possible
    incomeCostTitle: string;
}

const ChargingSessions: React.FC<ChargingSessionsProps> = ({ title, chargingSessions, incomeCostTitle }) => {

    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const formatTimestamp = (timestamp: string) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-GB', {
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).replace(',', ''); // Remove the comma from the date string
    };


    return (
        <div className="col-xs-12 col-md-6 col-xl-4">
            <div className="dashboard-card">
                <span className="dashboard-card-title">
                    <span className="icon material-symbols-outlined">electric_car</span>
                    {title}{" "}
                </span>
                <div className="dashboard-card-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Timestamp</th>
                                <th style={{ textAlign: 'right' }}>{incomeCostTitle}</th>
                                <th style={{ textAlign: 'right' }}>Charge (kW/h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th>VRN</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chargingSessions?.map((cs: any, index: number) => (
                                <tr className="data" key={index}>
                                    {/* Uncomment and modify the onClick handler as needed */}
                                    {/* onClick={() => selectFleet(cs.uuid, cs.name, cs.businessName, cs.businessUuid)} */}
                                    <td>{formatTimestamp(cs.chargeTimestamp)}</td>
                                    <td style={{ textAlign: 'right' }}>£{cs.cost.toFixed(2)}</td>
                                    <td style={{ textAlign: 'right' }}>{cs.chargeAmountKWh.toFixed(1)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td style={{ textAlign: 'left' }}>  {cs.registrationNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="dashboard-card-actions">
                    <div
                        className="icon-and-text"
                        onClick={() => navigate("/share/business/charging-transactions")}>
                        <span className="icon material-symbols-outlined">settings_applications</span>
                        View Transactions
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ChargingSessions;
