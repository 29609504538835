import ChargingSummaryTable from "components/ocm/OperationalCostModelComponent/ChargingSummaryTable";
import FleetSummaryContainer from "components/ocm/OperationalCostModelComponent/FleetSummaryContainer";
import FleetSummaryTable from "components/ocm/OperationalCostModelComponent/FleetSummaryTable";
import IceVehicleCategorySummaryTable from "components/ocm/OperationalCostModelComponent/IceVehicleCategorySummaryTable";
import UserContext from "Context/UserProvider";
import { useLocation, useNavigate} from "react-router";
import { currencyFormat, checking_null_undefined } from "utils";
import React, { useContext, useEffect, useState } from "react";

function OperationalCostModelPage(props: any) {
    const {userInfo, setUserInfo} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [categorySummaryData, setCategorySummaryData] = useState([]) as any;
    const [chargingSummaryData, setChargingSummaryData] = useState([]) as any;
    const [fleetSummaryData, setFleetSummaryData] = useState([]) as any;
    const [showCategory, setShowCategory] = useState([true, false, false]);
    const [activeMenu, setActiveMenu] = useState("ICE Vehicle Category Summary");
    const location = useLocation() as any;
    const operationalCostModelUuid = location.state.operationalCostModelUuid;
    const navigate = useNavigate();

  
    const returnMenu = [`Operational Cost Models`, `Operational Cost Model Version ${location.state.index} `];



    async function fetchOperationalCostData() {
        let bodyData: { [name: string]: string } = {};
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["operationalCostModelUuid"] = operationalCostModelUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/operational-cost-model/get`;
        let response = await fetch(fetch_link, {
            method: "POST",
            body: JSON.stringify(bodyData),
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        res_json.dwellingVirtualChargePointSummaries = checking_null_undefined(res_json.dwellingVirtualChargePointSummaries);

        setCategorySummaryData([
            { ...res_json.cat1,  totalOperationalCostOfEnRouteCharging: currencyFormat(res_json.cat1.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(res_json.cat1.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(res_json.cat1.totalTermEnergyCostSaving) + " (" + Math.round(res_json.cat1.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: res_json.cat1.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: res_json.cat1.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, name: "1", totalBusinessChargingEnergyCost: currencyFormat(res_json.cat1.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(res_json.cat1.totalPublicChargingEnergyCost), totalVirtualChargingEnergyCost: currencyFormat(res_json.cat1.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(res_json.cat1.totalHomeChargingEnergyCost), id: "1" },
            { ...res_json.cat2,  totalOperationalCostOfEnRouteCharging: currencyFormat(res_json.cat2.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(res_json.cat2.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(res_json.cat2.totalTermEnergyCostSaving) + " (" + Math.round(res_json.cat2.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: res_json.cat2.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: res_json.cat2.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, name: "2", totalBusinessChargingEnergyCost: currencyFormat(res_json.cat2.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(res_json.cat2.totalPublicChargingEnergyCost),totalVirtualChargingEnergyCost: currencyFormat(res_json.cat2.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(res_json.cat2.totalHomeChargingEnergyCost), id: "2" },
            { ...res_json.cat3,  totalOperationalCostOfEnRouteCharging: currencyFormat(res_json.cat3.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(res_json.cat3.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(res_json.cat3.totalTermEnergyCostSaving) + " (" + Math.round(res_json.cat3.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: res_json.cat3.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: res_json.cat3.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, name: "3", totalBusinessChargingEnergyCost: currencyFormat(res_json.cat3.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(res_json.cat3.totalPublicChargingEnergyCost),totalVirtualChargingEnergyCost: currencyFormat(res_json.cat3.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(res_json.cat3.totalHomeChargingEnergyCost), id: "3" },
            { ...res_json.cat4,  totalOperationalCostOfEnRouteCharging: currencyFormat(res_json.cat4.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(res_json.cat4.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(res_json.cat4.totalTermEnergyCostSaving) + " (" + Math.round(res_json.cat4.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: res_json.cat4.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: res_json.cat4.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, name: "4", totalBusinessChargingEnergyCost: currencyFormat(res_json.cat4.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(res_json.cat4.totalPublicChargingEnergyCost),totalVirtualChargingEnergyCost: currencyFormat(res_json.cat4.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(res_json.cat4.totalHomeChargingEnergyCost), id: "4" },
            { ...res_json.total, totalOperationalCostOfEnRouteCharging: currencyFormat(res_json.total.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(res_json.total.totalTermEnergyCost), totalTermFuelCostSavingPercentage: currencyFormat(res_json.total.totalTermEnergyCostSaving) + " (" + Math.round(res_json.total.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: res_json.total.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: res_json.total.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, name: "Total", totalBusinessChargingEnergyCost: currencyFormat(res_json.total.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(res_json.total.totalPublicChargingEnergyCost),totalVirtualChargingEnergyCost: currencyFormat(res_json.total.totalVirtualChargingEnergyCost),totalHomeChargingEnergyCost: currencyFormat(res_json.total.totalHomeChargingEnergyCost), id: "5" }
        ]);
        setChargingSummaryData(res_json.dwellingVirtualChargePointSummaries.map((e: any) => ({ ...e, id: e.dwellingUuid, operationalCostModelUuid: e.operationalCostModelUuid })));
        setFleetSummaryData(res_json.fleetSummaries.map((e: any) => ({ ...e, id: e.uuid, totalOperationalCostOfEnRouteCharging: currencyFormat(e.totalOperationalCostOfEnRouteCharging), totalTermEnergyCost: currencyFormat(e.totalTermEnergyCost), totalTermEnergyCostSavingPercentage: currencyFormat(e.totalTermEnergyCostSaving) + " (" + Math.round(e.totalTermEnergyCostSavingPercentage * 100) + "%)", averageDailyDistance: e.averageDailyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, averageJourneyDistance: e.averageJourneyDistance.toFixed(0) + " " + userInfo.userProfile.distanceUnitShortDisplayName, totalBusinessChargingEnergyCost: currencyFormat(e.totalBusinessChargingEnergyCost), totalPublicChargingEnergyCost: currencyFormat(e.totalPublicChargingEnergyCost), totalVirtualChargingEnergyCost: currencyFormat(e.totalVirtualChargingEnergyCost), totalHomeChargingEnergyCost: currencyFormat(e.totalHomeChargingEnergyCost) })));
        
        setLoading(false);
    }

    useEffect(() => {
        fetchOperationalCostData();
    }, []);

    if (loading) return <>loading...</>;
    return (
        <div className="drag-drop-container">
            <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "ICE Vehicle Category Summary" ? "active" : ""}`}
                    onClick={() => (setShowCategory([true, false, false]), setActiveMenu("ICE Vehicle Category Summary"))}>
                    ICE Vehicle Category Summary
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Charging Summary" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, true, false]), setActiveMenu("Charging Summary"))}>
                    Charging Summary
                </button>
                <div className="vl"></div>
                <button
                    className={`tab-btn ${activeMenu === "Fleet Summary" ? "active" : ""}`}
                    onClick={() => (setShowCategory([false, false, true]), setActiveMenu("Fleet Summary"))}>
                    Fleet Summary
                </button>
                <div className="vl"></div>
            </div>

            {showCategory[0] && (
                <>
                    {/* {fleetSummaryData.map((e: any, index: any) => {
            return (
              <FleetSummaryContainer
                key={index}
                data={e}
              />
            );
          })} */}

                    <IceVehicleCategorySummaryTable data={categorySummaryData} />
                </>
            )}
            {showCategory[1] && (
                <>
                    <ChargingSummaryTable data={chargingSummaryData} />
                </>
            )}
            {showCategory[2] && (
                <>
                    <FleetSummaryTable data={fleetSummaryData} />
                </>
            )}
        </div>
    );
}



export default OperationalCostModelPage;
