import React, { useContext, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AreaChart from "../../components/fleet/JourneyInsightComponent/AreaChart";
import HorizontalStackedChart from "../../components/fleet/JourneyInsightComponent/HorizontalStackedChartCharge";
import MapWithRoute from "../../components/fleet/JourneyInsightComponent/MapWithRoute";
import VerticalStackedChart from "../../components/fleet/JourneyInsightComponent/VerticalStackedChart";
import BackButton from "../../components/common/SiteTemplate/BackButton/BackButton";
import AreaChartSkelton from "../../components/common/SkeltonComponent/AreaChartSkelton";
import VerticalStackedBarChartSkelton from "../../components/common/SkeltonComponent/VerticalStackedBarChartSkelton";
import VerticalStackedBarChart from "../../components/common/SkeltonComponent/VerticalStackedBarChartSkelton";
import UserContext from "../../Context/UserProvider";

function JourneyInsightPage(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const {userInfo, setUserInfo} = useContext(UserContext);
  const params = location.state as any;
  const [selectedJourney, setSelectedJourney] = useState() as any;
  const [selectedDistance, setSelectedDistance] = useState() as any;
  const [loading, setLoading] = useState(true);
  const [dailyBatteryUsage, setDailyBatteryUsage] = useState() as any;
  const [batteryUsageMap, setBatteryUsageMap] = useState() as any;
  const [mapPoints, setMapPoints] = useState() as any;
  const returnMenu = ["Scenarios ", `Scenario ${params.fleetScenarioNumber} `, `Category ${params.categoryType + 1} `, `${params.registrationNumber} `, params.description];
  async function fetchInsight() {
    setLoading(true);
    let bodyData: { [name: string]: string } = {};
    //Variable for fetch request
    let userID = userInfo.uuid;
    let access_token = userInfo.access_token;
    let fleetUuid = userInfo.fleetUuid;
    bodyData["uuid"] = userID;
    bodyData["access_token"] = access_token;
    bodyData["fleetUuid"] = fleetUuid;
    bodyData["comparisonUuid"] = params.id;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/journey/insight/get`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };

    let response = await fetch(fetch_link, fetch_option);
    let res_json = await response.json();
    setDailyBatteryUsage(
      res_json.dailyBatteryUsageList.map((prev: any) => ({
        ...prev,
        startingBatteryLevel: +"1",
      }))
    );
    setBatteryUsageMap(res_json.batteryUsageMap);
    setMapPoints(res_json.mapPointsMap);
    setSelectedJourney(res_json.dailyBatteryUsageList[0].date);
    setSelectedDistance(res_json.dailyBatteryUsageList[0].distance);
    setLoading(false);
  }
  useLayoutEffect(() => {
    fetchInsight();
  }, []);
  return (
    <>
      {/* code for break menu */}
      <div>
        {returnMenu.map((e: any, index: any) => {
          //Check for last bread menu item
          if (index === returnMenu.length - 1) {
            return <span key={index}>{e}</span>;
          }
          //Check for scenario menu item to return because we need to return to exact category
          else if (index === 2) {
            return (

              <span
                key={index}
                onClick={() => {
                  navigate(-(returnMenu.length - 1 - index));
                  navigate("/plan/insight/fleet/scenario/categories", {
                    state: {
                      fleetScenarioUuid: params.fleetScenarioUuid,
                      fleetScenarioNumber: params.fleetScenarioNumber,
                      activeCategory: `Category ${params.categoryType + 1}`,
                      showCategory: [false, false, false, false].map((e: any, index: any) => {
                        if (index === params.categoryType) {
                          return true;
                        }
                      }),
                    },
                  });
                }}>
                <span
                  style={{
                    color: "#00c1d5",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}>
                  {e}
                </span>
                {"  >  "}
              </span>
            );
          }
          return (
            <span onClick={() => navigate(-(returnMenu.length - 1 - index))}>
              <span className="hyper-link">{e}</span>
              {"  >  "}
            </span>
          );
        })}
      </div>
      <br />
      <h1>Journey Insight</h1>
      <br />
      {loading ? (
        <>Loading...</>
      ) : (
        <div className="d-flex flex-column gap-3">
           <VerticalStackedChart
            data={dailyBatteryUsage}
            selectedJourney={[selectedJourney, setSelectedJourney]}
            selectedDistance={[selectedDistance, setSelectedDistance]}

          />
          {/* <h1>Daily Battery Usage for Journey</h1> */}
          <div className="d-flex flex-column flex-lg-row gap-3">
            <AreaChart
              data={batteryUsageMap}
              selectedJourney={selectedJourney}
            />
            {/* <h1>Journey Details</h1> */}
            <MapWithRoute
              data={mapPoints}
              selectedJourney={selectedJourney}
              selectedDistance={selectedDistance}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default JourneyInsightPage;
