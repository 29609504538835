import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import UserContext from "../../Context/UserProvider";
import FleetScenarioTable from "../../components/fleet/FleetScenarioConfigPageComponent/FleetScenarioTable/FleetScenarioTable";
import ErrorPage from "../ErrorPage/ErrorPage";
import "./FleetScenarioPage.scss";

function FleetScenarioPage(props: any) {
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const {userInfo, setUserInfo} = useContext(UserContext);
  const [fleetScenario, setFleetScenario] = useState([]) as any;
  const [loading, setLoading] = useState(true);

  // function updateRunningScenario(scenario: any) {
  //     setFleetScenario((prev: any) => prev.map((e: any) => {
  //         if (e.uuid === scenario.uuid) {
  //             return { ...scenario, createdDate: new Date((Date.parse(scenario.createdDate))).toLocaleDateString(), id: scenario.uuid, percentageComplete: (scenario.percentageComplete.toFixed(2) * 100) }
  //         }
  //         return e
  //     }))
  // }

  async function fetchScenario() {
    let bodyData: { [name: string]: string } = {};
    bodyData["uuid"] = userInfo.uuid;
    bodyData["access_token"] = userInfo.access_token;
    bodyData["fleetUuid"] = userInfo.fleetUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/DragDrop/fleet/scenario/list`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();

    setFleetScenario(res_json.scenarios.map((e: any) => ({ ...e, id: e.uuid })));
    setLoading(false);
  }

  useEffect(() => {
    fetchScenario();
  }, []);
  return (
    <>
      {isError ? (
        <ErrorPage statusCode="500" />
      ) : (
        <>
          {/* <BackButton /> */}
          {loading ? (
            <div>loading... </div>
          ) : (
            <div
              style={{
                overflowX: "hidden",
                padding: 20,
                width: "100%",
                height: "100%",
                gap: 30,
                justifyContent: "space-between",
              }}
              className="row">
              {fleetScenario.length > 0 ? (
                <FleetScenarioTable scenarioList={fleetScenario} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: 15,
                  }}>
                  <p style={{ fontSize: 25 }}>There is no any scenario in this fleet yet</p>
                  <p>
                    Please click{" "}
                    <span
                      onClick={() => navigate("/plan/scenario/configuration")}
                      style={{ textDecoration: "underline", cursor: "pointer", color: "#00c1d5" }}>
                      {" "}
                      here{" "}
                    </span>
                    to configure and run new scenario.
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FleetScenarioPage;
