import React from 'react';
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import Footer from '../../components/homepage/HomePageComponent/Footer';
import JourneyNav from '../../components/homepage/HomePageComponent/Nav';
import './AboutPage.scss'

function AboutPage(props: any) {
    return (
        <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
            <JourneyNav fixed="fixed-top" homePage={true} />
            <div className="about_page_title" >
                <div>
                    <span style={{ fontSize: '3rem' }}>About Us</span>
                </div>
            </div>
            <div className="about_page_body">
                <div className="about_page_body_container">
                    <div className="about_page_body_content">
                        <span style={{ fontWeight: 700, letterSpacing: 5 }}>What inspired<span style={{ backgroundColor: '#bef214', borderRadius: 10, padding: '0px 20px 0px 20px' }}>Evata</span>? </span>
                        <br />
                        <br />
                        <p>
                            Born out of frustration at the complexity of transitioning a commercial fleet, Evata is the result of painstaking work to simplify the process of fleet electrification. At the centre of this process is an interconnected problem - data. Businesses have digital tools to deal with a variety of problems, but digital tools which simplify the process of transitioning to an electric fleet have largely been absent
                        </p>
                        <br />
                        <p>That was until now. Today, any business can use Evata's Electrification Management Platform to gain rapid, detailed analysis of what fleet electrification could mean for them and, most importantly, how to make the transition. This marks a move away from months of data analysis with reliance on disparate sources of sparse, costly and complex information, and a move towards an integrated platform that promotes data-led electrification decisions.</p>
                    </div>
                    <div className="about_page_body_content right" >
                        <span style={{ fontWeight: 600 }}> Data-led Electrification Identifying </span>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                            <span style={{ fontWeight: '500' }}> <span className="span-highlight" >Which</span> <span className="span-big">vehicles</span> to transaction and when? </span>
                            <span style={{ fontWeight: '500' }}><span className="span-highlight" >Which</span> <span className="span-big" >infrastructure</span> is required? </span>
                            <span style={{ fontWeight: '500' }}><span className="span-highlight" >Which</span> <span className="span-big" >EV</span> provided the optimal TCO? </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />


        </div>
    );
}

export default AboutPage;