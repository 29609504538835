import { Backdrop, Button, CircularProgress } from '@mui/material';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Tooltip,
} from 'chart.js';
import ElectricVehicleTable from "components/ppa-quote/ElectricVehicleTable";
import Breadcrumbs from "components/ppa-quote/Breadcrumbs";
import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checking_null_undefined } from "utils";


// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
interface Vehicle {
    vrn: string;
    make: string;
    model: string;
    journeys: number;
    totalDistance: string;
    avgCharge: string;
    avgDwellTime: string;
}

function BuildQuotePageVehicles(props: any) {
    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useContext(UserContext);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [quotes, setQuotes] = useState<any[]>([]);
    const navigate = useNavigate();

    const steps = ["Authenticating...", "Downloading...", "Analysing..."];
    const stepDurations = [1500, 500, 2000]; // Array to set the duration (in ms) for each step

    const [showCategory, setShowCategory] = useState([true, false, false]) as any;
    const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false]);
    const [activeMenu, setActiveMenu] = useState("Plan");
    const [isError, setIsError] = useState(false);

    const [rented, setRented] = useState() as any;
    const [plan, setPlan] = useState() as any;
    const [connectedFleet, setConnectedFleet] = useState() as any;

    const data1 = {
        datasets: [{
            data: [48, 52],
            backgroundColor: ['#FF6384', '#CCCCCC'],
        }],
    };

    const data2 = {
        datasets: [{
            data: [12, 88],
            backgroundColor: ['#36A2EB', '#CCCCCC'],
        }],
    };

    const handleNext = () => {

        navigate("/agree/ppa/quote/build/locations")

    }

    const fetchVehicleData = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["fleetUuid"] = userInfo.fleetUuid;

        console.log("FLEETEET " + JSON.stringify(bodyData));

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/fleet/electric-vehicle/list`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        console.log(JSON.stringify(res_json));

        setConnectedFleet(checking_null_undefined(res_json.connectedFleet).map((e: any) => ({ ...e, id: e.uuid, registrationNumber: e.registrationNumber, vin: e.vin, make: e.make, model: e.model, chargeCount: e.chargeCount, countDaysOfData: e.countDaysOfData })));
        setPlan(checking_null_undefined(res_json.plan).map((e: any) => ({ ...e, id: e.uuid, registrationNumber: e.registrationNumber, vin: e.vin, make: e.make, model: e.model, countDaysOfData: e.countDaysOfData })));
        setLoading(false);
    };


    const providers = [
        { name: 'Verizon Connect', logo: 'https://www.verizonconnect.com/images/logo-vzc-5c1dedfa.svg' },
        { name: 'Quartix', logo: 'https://www.quartix.com/content/uploads/2021/01/quartix-logo-eur.svg' },
        { name: 'Teletrac Navman', logo: 'https://www.teletracnavman.co.uk/media/16878/tn-logo-lockup-rgb.svg' },
        { name: 'Movolytics', logo: 'https://movolytics.com/wp-content/uploads/2022/09/movolytics_logo.svg' },
        { name: 'Masternaut', logo: 'https://connectedfleet.michelin.com/hs-fs/hubfs/MCF_2024/MICHELIN%20Connected%20Fleet_logo.png?width=578&height=122&name=MICHELIN%20Connected%20Fleet_logo.png' },
    ];

    useEffect(() => {
        fetchVehicleData();
    }, []);
    const breadcrumbs = ['Vehicles', 'Home Locations', 'Summary', 'Quotes'];
    const activeIndex = 0; // Change this index to set the active step

    return (
        <div>
            {/* Breadcrumbs always displayed */}
            <Breadcrumbs currentStep={"Vehicles"} />

            {/* Loading popup */}
            <Backdrop open={loading} style={{ zIndex: 1300 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loading && (
                <div className="container-fluid g-0" style={{ marginTop: "24px" }}>
                    <div className="row gy-2">

                        {showCategory[0] && (
                            <>
                                <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                                    <div className="vl"></div>
                                    <button
                                        className={`tab-btn ${activeMenu === "Plan" ? "active" : ""}`}
                                        onClick={() => {
                                            setShowSecondaryCategory([true, false]);
                                            setActiveMenu("Plan");
                                        }}>
                                        PLAN - Simulated EV
                                    </button>
                                    <div className="vl" />
                                    <button
                                        className={`tab-btn ${activeMenu === "EV Connected Car Data" ? "active" : ""}`}
                                        onClick={() => {
                                            setShowSecondaryCategory([false, true]);
                                            setActiveMenu("EV Connected Car Data");
                                        }}>
                                        EV Connected Car Data
                                    </button>
                                    <div className="vl" />
                                </div>
                                <br />
                                {showSecondaryCategory[0] && (
                                    <ElectricVehicleTable electricVehicles={plan} displayVin={false} displayRegLink={true} makeModel={false} />
                                )}
                                {showSecondaryCategory[1] && (
                                    <ElectricVehicleTable electricVehicles={connectedFleet} displayVin={true} displayRegLink={false} makeModel={true} />
                                )}
                            </>
                        )}
                        <br />
                        <br />
                        <br />
                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
                                <button className="primary-btn" disabled={isDisabled} onClick={handleNext}>
                                    Next
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            )}
        </div>
    );
}

export default BuildQuotePageVehicles;
